import React from 'react'
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { sizeTheme } from '../../../style/sizeTheme';

interface musicListItemType {
    title: string;
    img?: string;
    clickEvent : any;
    singer?: string;
}

export default function MusicYoutubeListItem({title,singer,img,clickEvent}:musicListItemType) {
    return (
        <Wrap>
            {!(img === undefined || img === '') &&
                <img src={`https://img.youtube.com/vi/${img}/0.jpg`} />
            }
            <Title>{title}</Title>
            {!(singer === undefined || singer === '') &&
                <Singer>{singer}</Singer>
            }
            <BtnWrap>
                <HighlightOffIcon color='error' onClick={clickEvent}></HighlightOffIcon>
            </BtnWrap>
        </Wrap>
    )
}


const Wrap = styled(Row)`
    width: 100%;
    height: 65px;
    padding: 0 10px;
    &>img{
        height: 65px;
    }
    margin: 4px 0;
`;
const Title = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 60%;
`;
const Singer = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 40%;
`;
const BtnWrap = styled(Center)`
    margin-left: 10px;
    width: 10%;
    & svg{cursor: pointer;}
`;
import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box,Grid, } from '@mui/material'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import { MUSIC_CATEGORY_ITEM, apiSetMusicCateAdd, apiSetMusicCateDel, apiSetMusicCateUpdate, getMusicCateList } from '../../../api/apiMusic'
import { useNavigate, useParams } from 'react-router'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import MusicCategoryListPart from '../part/MusicCategoryListPart'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanelPart, a11yProps } from '../../common/part/TabPanelPart'
import MusicCategoryInputPart from '../part/MusicCategoryInputPart'
import { AdminWrap } from '../../../style/basic/commonStyle'

export const USE_TYPE = ['전체','음악','유튜브'];

export default function MusicCategoryListPage() {
    const pageTitle = '음악 카테고리';
    const user = useUserInfo();
    const navigate = useNavigate();
    const {type,page} = useParams();
    const value = Number(type) ?? 0;
    const nowPage = Number(page) ?? 1;

    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [lastCode,setLastCode] = useState(0);

    const [selectItem,setSelectItem] = useState(MUSIC_CATEGORY_ITEM);

    useEffect(()=>{
        if(user.jwt_token === '') return;
        getList();
    },[user.jwt_token,value,nowPage]);

    useEffect(()=>{
        if(user.jwt_token === '') return;
        if(total === 0) return;
        getLastCode();
    },[user.jwt_token,total])

    // 리스트
    const getList = async() =>{
        const res = await getMusicCateList(user.jwt_token,user.admin_id,(value+1),nowPage,10);
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    const getLastCode = async() =>{
        const res = await getMusicCateList(user.jwt_token,user.admin_id,(value+1),total,10);
        if(res.result){
            const last = res.data[res.data.length-1];
            if(last?.category_id === undefined){
                setLastCode(0);
                return
            };
            setLastCode(last.category_id + 1);
        }else{
            setLastCode(0);
        }
        
    }

    // 탭이동
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(`/musicCategoryList/${newValue}/1`);
    };

    // 리스트 삭제
    const delAction = async(id:any) =>{
        if(window.confirm('삭제 하시겠습니까?')){
            const res = await apiSetMusicCateDel(user.jwt_token,user.admin_id,id);
            if(res.result){
                alert('삭제되었습니다');
                getList();
            }else{
                alert('잠시후 다시 시도해주세요.');
            }
        }
    }

    // 리스트 수정
    const updateAction = async(item:typeof MUSIC_CATEGORY_ITEM) =>{
        setSelectItem(item);
    }

    // 생성
    const create = async(title:string,code:number,use:number) =>{
        if(window.confirm('생성 하시겠습니까?')){
            if(title === ''){
                alert('타이틀을 입력해주세요.');
                return;
            }
            if(code === 0){
                alert('코드를 입력해주세요.');
                return;
            }
            const res = await apiSetMusicCateAdd(user.jwt_token,user.admin_id,title,code,use);

            if(res.result){
                alert('카테고리를 추가했습니다.');
                getList();
                getLastCode();
            }else{
                if(res.code === 4001){
                    alert('이미 등록된 코드입니다.');
                }else{
                    alert('잠시후 다시 시도해주세요.');
                }
            }
            return res.result;
        }
    }
    // 입력 수정
    const update = async(title:string,id:number,code:any,use:any) =>{
        if(window.confirm('수정 하시겠습니까?')){
            if(title === ''){
                alert('타이틀을 입력해주세요.');
                return;
            }
            const res = await apiSetMusicCateUpdate(user.jwt_token,user.admin_id,title,id,code,use);
            if(res.result){
                alert('수정되었습니다');
                setSelectItem(MUSIC_CATEGORY_ITEM);
                getList();
            }else{
                alert('잠시후 다시 시도해주세요.');
            }
            return res.result;
        }
    }

    // 입력 취소
    const cancel = () =>{
        if(window.confirm('취소 하시겠습니까?')){
            setSelectItem(MUSIC_CATEGORY_ITEM);
        }
    }

    return (
        <>
            <Grid container={true} spacing={2}>
                <Grid item md={5} sm={12} sx={{width:'100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title='입력'></TableWrapTitleItem>
                        <MusicCategoryInputPart item={selectItem} create={create} update={update} cancel={cancel} lastCode={lastCode} />
                    </AdminWrap>
                </Grid>
                <Grid item md={7} sm={12} sx={{width:'100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title='리스트'></TableWrapTitleItem>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="music" {...a11yProps(0)} />
                            <Tab label="effect" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanelPart value={value} index={0}>
                            <MusicCategoryListPart list={list} nowPage={nowPage} total={total} index={0} updateAction={updateAction} delAction={delAction}></MusicCategoryListPart>
                        </TabPanelPart>
                        <TabPanelPart value={value} index={1}>
                            <MusicCategoryListPart list={list} nowPage={nowPage} total={total} index={1} updateAction={updateAction} delAction={delAction}></MusicCategoryListPart>
                        </TabPanelPart>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

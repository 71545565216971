import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Grid, TableRow } from '@mui/material'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import ListPart from '../../common/part/ListPart'
import { ADM_ACCESS_TITLE } from '../../../data/tableTitle'
import { useParams } from 'react-router'
import TableNoItem from '../../common/item/TableNoItem'
import TableContentItem from '../../common/item/TableContentItem'
import { apiGetAdminLog } from '../../../api/apiAdmin'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import { AdminWrap } from '../../../style/basic/commonStyle'

export default function AdminAccessListPage() {
    const TITLE = '관리자 접속 내역'
    const {page} = useParams();
    const nowPage = Number(page) ?? 1;
    const userInfo = useUserInfo();
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,nowPage]);

    const getList = async() =>{
        const res = await apiGetAdminLog(userInfo.jwt_token,userInfo.admin_id,nowPage,10);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    return (
        <>
            <AdminWrap>
                <TableWrapTitleItem title={TITLE} />
                <ListPart titleArr={ADM_ACCESS_TITLE} minWidth={850} nowPage={nowPage} total={total} link={'adminAccess'}>
                    {list === undefined || list.length === 0 ?
                        <TableNoItem colSpan={ADM_ACCESS_TITLE.length} /> :
                        list.map((data:any,index:any)=>{
                            return(
                                <TableRow key={`admAccess-table-${index}`}>
                                    <TableContentItem text={data.admin_email} />
                                    <TableContentItem text={data.admin_name} />
                                    <TableContentItem text={data.admin_level} />
                                    <TableContentItem text={data.adm_login_log_country} />
                                    <TableContentItem text={data.adm_login_log_ip} />
                                    <TableContentItem text={`${data.adm_login_log_os} / ${data.adm_login_log_brower}`} />
                                    <TableContentItem text={data.admin_timestamp} type='time' />
                                    <TableContentItem text={data.adm_login_log_timestamp} type='time' />
                                </TableRow>
                            )
                        })
                    }
                </ListPart>
            </AdminWrap>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import InputItem from '../../common/item/InputItem';
import BasicText from '../../basic/BasicText';
import SelectItem from '../../common/item/SelectItem';
import { Button, MenuItem } from '@mui/material';

// 타입 1 - 엔터  2 - 제작사
const TYPE = ['엔터','제작사'];
export default function AgencyInputPart({selectItem,setSelectItem}:any) {
    const [code,setCode] = useState('');
    const [chName,setChName] = useState('');
    const [name,setName] = useState('');
    const [type,setType] = useState(0);

    useEffect(()=>{
        if(selectItem === undefined){
            reset();
        }else{
            setCode(selectItem.code);
            setChName(selectItem.ch_name);
            setName(selectItem.name);
            setType(selectItem.type-1);
        }
    },[selectItem])

    const reset = () =>{
        setCode('');
        setChName('');
        setName('');
        setType(0);
    }

    // 취소
    const cancel = () =>{
        setSelectItem(undefined);
    }

    // 수정
    const update = async() =>{

    }

    return (
        <Wrap>
            <InputWrap>
                <InputItem label='채널 코드' inputLabel='none' value={code} valueChange={setCode}/>
                <InputItem label='채널명' inputLabel='none' value={chName} valueChange={setChName}/>
                <InputItem label='소속사명' inputLabel='none' value={name} valueChange={setName}/>
                <InputItemWrap>
                    <BasicText marginBottom={1}>타입</BasicText>
                    <SelectItem value={type} setValue={setType}>
                        {TYPE.map((data,index)=>{
                            return(
                                <MenuItem key={`type-${index}`} value={index}>{data}</MenuItem>
                            )
                        })}
                    </SelectItem>
                </InputItemWrap>
            </InputWrap>
            <BtnWrap>
                {selectItem === undefined ?
                    <Button variant='contained'>생성</Button> :
                    <>
                        <Button variant='contained' >수정</Button>
                        <Button variant='contained' color='error' onClick={cancel}>취소</Button>
                    </>
                }
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InputWrap = styled(Row)`
    width: auto;
    align-items: stretch;
    gap: 10px;
    flex-direction: column;
`;

const InputItemWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
`;

const BtnWrap = styled(Center)`
    margin-top: 20px;
    gap: 10px;
`;

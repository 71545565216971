import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { useNavigate, useParams } from 'react-router';
import { Box, Button, Grid, Tab, TableRow, Tabs, } from '@mui/material';
import { TabPanelPart, a11yProps } from '../../common/part/TabPanelPart';
import styled from 'styled-components';
import { AdminWrap, Center } from '../../../style/basic/commonStyle';
import { apiGetDetailTerms, apiGetTerms, apiSetAddTerms } from '../../../api/apiSetting';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import TermsContentPart from '../part/TermsContentPart';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { TERMS_TITLE } from '../../../data/tableTitle';
import ListPart from '../../common/part/ListPart';
import TableNoItem from '../../common/item/TableNoItem';
import TableContentItem from '../../common/item/TableContentItem';

export default function TermsPage() {
    const pageTitle = '약관';
    const userInfo = useUserInfo();
    const navigate = useNavigate();
    const {tab,page} = useParams();
    const nowPage = page === undefined ? 1 : Number(page);
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const [title,setTitle] = useState('');
    const [content,setContent] = useState('');
    const value = Number(tab) ?? 0;

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,value,nowPage]);

    // 리셋
    const reset = () =>{
        setTitle('');
        setContent('');
    }

    // 탭이동 이동
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(`/terms/${newValue}/1`);
        reset();
    };

    // 디테일
    const getDetail = async(id:any) =>{
        const res = await apiGetDetailTerms(userInfo.jwt_token,userInfo.admin_id,id);
        if(res.result){
            setTitle(res.data.terms_title);
            setContent(res.data.terms_content);
        }else{
            setTitle('');
            setContent('');
        }
    }

    // 리스트
    const getList = async() =>{
        const res = await apiGetTerms(userInfo.jwt_token,userInfo.admin_id,nowPage,10,(value + 1));
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }   
    }

    // 등록
    const updateAction = async() =>{
        if(content === ''){
            alert('내용을 입력해주세요.');
            return;
        }
        if(window.confirm('약관을 등록하시겠습니까?')){
            const res = await apiSetAddTerms(userInfo.jwt_token,userInfo.admin_id,(value + 1),content);
            if(res.result){
                alert('약관이 등록되었습니다.');
                getList();
                reset();
            }else{
                alert(res.error);
            }
            
        }
    }
    
    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label={'개인정보처리방침'} {...a11yProps(0)}></Tab>
                            <Tab label={'서비스 이용약관'} {...a11yProps(1)}></Tab>
                        </Tabs>
                        <TabContentWrap>
                            <TabPanelPart value={value} index={0}>
                                <TermsContentPart title={title} setTitle={setTitle} content={content} setContent={setContent} />
                            </TabPanelPart>
                            <TabPanelPart value={value} index={1}>
                                <TermsContentPart title={title} setTitle={setTitle} content={content} setContent={setContent} />
                            </TabPanelPart>
                            <Center>
                                <Button variant='contained' sx={{margin:'15px 0'}} onClick={updateAction}>추가</Button>
                            </Center>
                        </TabContentWrap>
                    </AdminWrap>                    
                </Grid>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'리스트'}></TableWrapTitleItem>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label={'개인정보처리방침'} {...a11yProps(0)}></Tab>
                            <Tab label={'서비스 이용약관'} {...a11yProps(1)}></Tab>
                        </Tabs>
                        <ListPart titleArr={TERMS_TITLE} minWidth={850} nowPage={nowPage} total={total} link={`terms/${value}`}>
                            {list === undefined || list.length === 0 ?
                                <TableNoItem colSpan={TERMS_TITLE.length}></TableNoItem> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <TableRow key={`tableContent-${index}`}>
                                            <TableContentItem text={data.terms_id} />
                                            <TableContentItem text={data.terms_title} />
                                            <TableContentItem text={data.terms_content} />
                                            <TableContentItem text={data.terms_timestmap} type='time'/>
                                            <TableContentItem>
                                                <Button variant='contained' onClick={()=>getDetail(data.terms_id)}>보기</Button>
                                            </TableContentItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

const TabContentWrap = styled.div`
    margin-top: 10px;
`;
import React from 'react'
import styled from 'styled-components'
import HorizontalScroll_001 from '../layout/HorizontalScroll_001';
import VerticalScroll_001 from '../layout/VerticalScroll_001';
import GoogleAdBanner_001 from '../layout/GoogleAdBanner_001';
import HorizontalScroll_100 from '../layout/HorizontalScroll_100';

export default function LayoutPart({contentList}:any) {
    return (
        <Wrap>
            <HeaderWrap></HeaderWrap>
            <ContentWrap>
                {!(contentList === undefined || contentList.length === 0) &&
                    contentList.map((data:any,index:any)=>{
                        return(
                            <ContentListWrap key={`app-${index}`}>
                                {data.type === 'google_ad_banner_001' && <GoogleAdBanner_001 data={data} /> }
                                {data.type === 'horizon_scroll_100' && <HorizontalScroll_100 data={data} /> }
                                {data.type === 'horizon_scroll_001' && <HorizontalScroll_001 data={data} /> }
                                {data.type === 'vertical_scroll_001' && <VerticalScroll_001 data={data} /> }
                                {/* {
                                    data.type === "horizon_scroll_100" ? <HorizontalScroll_100 data={data} /> :
                                    data.type === "horizon_scroll_001" ? <HorizontalScroll_001 data={data} /> :
                                    data.type === "vertical_scroll_001" ? <VerticalScroll_001 data={data} /> :
                                    data.type === "google_ad_banner_001" ? <GoogleAdBanner_001 data={data} /> :
                                    <></>
                                } */}
                            </ContentListWrap>
                        )
                    })
                }
            </ContentWrap>
            <BottomWrap>
                
            </BottomWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 360px;
    height: 740px;
    outline: 4px solid #646464;
    border-radius: 4px;
    overflow: hidden;
    background-color: #000;
    & *::-webkit-scrollbar{
        width: 4px;
        height: 4px;
        background-color: #5c5c5c;
    }
    & *::-webkit-scrollbar-thumb {
        background-color: #fff;
    }
`;

const HeaderWrap = styled.div`
    height: 60px;
`;
const ContentWrap = styled.div`
    height: calc(100% - 120px);
    overflow-y: auto;
`;
const ContentListWrap = styled.div``;
const BottomWrap = styled.div`
    height: 60px;
`;
import React,{useEffect,useState} from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import {Grid, Box, TableRow, Button} from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { useParams } from 'react-router';
import ListPart from '../../common/part/ListPart';
import { STORY_REPORT_LIST_TITLE } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import TableContentItem from '../../common/item/TableContentItem';
import { AdminWrap } from '../../../style/basic/commonStyle';

const DUMMY = [
    {
        id:1,
        reporter_nick:'신고한 사람1',
        reporter_code:'re2r4edd',
        target_nick:'신고 당한 사람1',
        target_code:'2redm4mfd',
        sns_type:'1',
        report_type:"혐오 발언 또는 상징",
        report_time:1691720585003,
    },
    {
        id:2,
        reporter_nick:'신고한 사람2',
        reporter_code:'derdd3eed',
        target_nick:'신고 당한 사람2',
        target_code:'gddr3cddf',
        sns_type:'2',
        report_type:"스팸",
        report_time:1691720585003,
    },
]

export default function StoryReportListPage() {
    const pageTitle = 'Story 신고 리스트';
    const {page} = useParams();
    let nowPage = Number(page) ?? 1;
    const [total,setTotal] = useState(1);
    const [list,setList] = useState(DUMMY);

    useEffect(()=>{
        getList();
    },[nowPage]);

    // 리스트
    const getList = async() =>{
        
    }

    // 삭제
    const delAction = async(id:number) =>{
        if(window.confirm('삭제 하시겠습니까?')){
            
        }
    }

    // 보류
    const cancelACtion = async(id:number) =>{
        if(window.confirm('보류 하시겠습니까?')){

        }
    }

    return (
        <>
            <AdminWrap>
                <TableWrapTitleItem title={pageTitle}></TableWrapTitleItem>
                <ListPart titleArr={STORY_REPORT_LIST_TITLE} minWidth={950} nowPage={nowPage} total={total} link={'storyReport'}>
                    {list.length === 0 || list[0].id === 0 ?
                        <TableNoItem colSpan={STORY_REPORT_LIST_TITLE.length}></TableNoItem> :
                        list?.map((data,index:number)=>{
                            return(
                                <TableRow
                                    key={`tableContent-${index}`}
                                >
                                    <TableContentItem text={data.id}></TableContentItem>
                                    <TableContentItem text={`${data.reporter_nick}(${data.reporter_code})`}></TableContentItem>
                                    <TableContentItem text={`${data.target_nick}(${data.target_code})`}></TableContentItem>
                                    <TableContentItem 
                                        text={
                                            data.sns_type === '1' ? '텍스트' 
                                            : data.sns_type === '2' ? '이미지' 
                                            : data.sns_type === '3' ? '비디오'
                                            : data.sns_type === '4' ? '오디오'
                                            : '유튜브'
                                        }
                                        ></TableContentItem>
                                    <TableContentItem text={data.report_type}></TableContentItem>
                                    <TableContentItem text={data.report_time} type='time'></TableContentItem>
                                    <TableContentItem>
                                        <Button onClick={()=>delAction(data.id)} variant='contained' color='error'>삭제</Button>
                                    </TableContentItem>
                                    <TableContentItem>
                                        <Button onClick={()=>cancelACtion(data.id)} variant='contained' color='info'>보류</Button>
                                    </TableContentItem>
                                </TableRow>
                            )
                        })
                    }
                </ListPart>
            </AdminWrap>
        </>
    )
}

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';

export default function GoogleAdBanner_001({data}:any) {
    const {styles} = data;
    return (
        <Wrap styles={styles && styles}>
            <AdItem>AD</AdItem>
        </Wrap>
    )
}

const Wrap = styled.div<{styles?: any}>`
    padding-top: ${props => props?.styles?.paddingTop ?? 10}px;
    padding-bottom: ${props => props?.styles?.paddingBottom ?? 0}px;
    width: 90%;
    margin: 0 auto;
`;

const AdItem = styled(Center)`
    background-color: #eee;
    width: 100%;
    height: 50px;
`;
import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { apiLogin } from '../../../api/login';
import { setUserInfo } from '../../../store/common/user';
import { Center, Row } from '../../../style/basic/commonStyle';
import { IMG_URI } from '../../../api/config';

export default function LoginPage(){
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [createOpen,setCreateOpen] = useState(false);
    
    const onChangeEmail = useCallback((val:any) => {
        setEmail(val);
    },[]);

    const onChangePassword = useCallback((val: any) => {
        setPassword(val);
    },[]);

    const loginAction = async(e:any) => {
        e.preventDefault();
        const res = await apiLogin(email,password);
        
        if(!res.result){
            alert('이메일과 비밀번호를 확인해주세요.');
            return;
        }

        // // 로그
        // const logRes = await apiLoginLog(res.data.admin_id);
        
        // if(!logRes.result){
        //     alert(logRes.error);
        //     return;
        // }
        
        dispatch(setUserInfo({
            admin_id: res.data.admin_id,
            admin_email: res.data.admin_email,
            admin_level: res.data.admin_level,
            jwt_token: res.data.jwt_token,
            admin_code: res.data.admin_code,
            admin_name: res.data.admin_name,
        }))
        navigate('/');
    }
    
    return (
        <Wrap>
            <LoginWrap onSubmit={loginAction}>
                <AdminIconItem>ADMIN</AdminIconItem>
                <Center style={{marginBottom:'60px'}}>
                    <img style={{height:'36px'}} alt='logo' src={IMG_URI+'/img/admin-logo.png'}></img>
                </Center>
                <InputItem>
                    <input placeholder='관리자 아이디' value={email} onChange={(e:any) => onChangeEmail(e.target.value)} required/>
                </InputItem>
                <InputItem>
                    <input type='password' placeholder='관리자 비밀번호' value={password} onChange={(e:any) => onChangePassword(e.target.value)} required/>
                </InputItem>
                <BtnWrap type='submit'>로그인</BtnWrap>
            </LoginWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    width: 100%;
    height: 100vh;
`;

const AdminIconItem = styled(Center)`
    background-color: #0063ff;
    color: #fff;
    font-size: 15px;
    padding: 6px 20px;
    border-radius: 30px;
`;

const LoginWrap = styled.form`
    border-radius: 10px;
    width: 100%;
    max-width: 410px;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
`;

const InputItem = styled(Row)`
    &>input{
        border: 1px solid #ddd;
        padding: 20px;
        border-radius: 4px;
        width: 100%;
        font-size: 18px;
    }
`;

const BtnWrap = styled.button`
    color: #fff;
    background-color: #0063ff;
    width: 100%;
    height: 62px;
    margin-top: 70px;
    border-radius: 4px;
    font-size: 20px;
`;
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { TextItem } from '../../../style/basic/commonStyle';
import InputItem from '../../common/item/InputItem';
import SelectItem from '../../common/item/SelectItem';
import { Button, MenuItem } from '@mui/material';
import { getMusicCateList } from '../../../api/apiMusic';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { apiSetYoutubeAdd } from '../../../api/apiStory';

export default function YoutubeRegPart({getList,genreList}:any) {
    const userInfo = useUserInfo();
    const [link,setLink] = useState('');
    const [type,setType] = useState('2');
    const [genre,setGenre] = useState('');

    const reset = () =>{
        setLink('');
        setType('2');
        setGenre('');
    }
    
    const youtubeRegClick = async() =>{
        if(genre === ''){
            alert('장르를 선택해주세요.')
            return;
        }
        if(link === ''){
            alert('링크를 입력해주세요.');
        }
        const res = await apiSetYoutubeAdd(userInfo.jwt_token,userInfo.admin_id,link,genre,type);
        
        if(res.result){
            alert('등록되었습니다.');
            reset();
            getList();
        }else{
            alert(res.error_kr);
        }
    }

    return (
        <Wrap>
            <TableWrapTitleItem title='등록' />
            <TextItem>타입</TextItem>
            <SelectItem value={type} setValue={setType} sx={{width:'100%',maxWidth: '320px'}} margin='10px 0 0 0'>
                <MenuItem value='2'>KPOP</MenuItem>
                <MenuItem value='3'>POP</MenuItem>
            </SelectItem>
            <TextItem marginTop={10}>장르</TextItem>
            <SelectItem value={genre} setValue={setGenre} sx={{width:'100%',maxWidth: '320px'}} margin='10px 0 0 0' noneState>
                {!(genreList.length === 0 || genreList === undefined) &&
                    genreList.map((data:any,index:any)=>{
                        return(
                            <MenuItem key={`youtube-genre-${index}`} value={data.sound_small_category}>{data.category_title}</MenuItem>
                        )
                    })
                }
            </SelectItem>
            <InputItem label='링크' inputLabel='none' value={link} valueChange={setLink} inputSx={{width:'100%',maxWidth: '320px'}} margin='10px 0 0 0' />
            <Button variant='contained' sx={{marginTop:'15px'}} onClick={youtubeRegClick}>등록</Button>
        </Wrap>
    )
}

const Wrap = styled.div``;

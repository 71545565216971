import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { Center } from '../../../style/basic/commonStyle'
import styled, { keyframes } from 'styled-components';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface tableNoItemType{
    colSpan:number;
    txt?:string;
    loading?: boolean
}

export default function TableNoItem({colSpan,txt,loading}:tableNoItemType) {
    
    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <Center>
                    {loading === true ? 
                        <LoadingWrap>
                            <AiOutlineLoading3Quarters size={20} />
                        </LoadingWrap> :
                        txt === undefined || txt === '' ?
                            'No items to display' :    
                        txt
                    
                    }
                </Center>
            </TableCell>
        </TableRow>
    )
}

const loadingAni = keyframes`
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
`;
const LoadingWrap = styled.div`
    &>svg{
        animation: ${loadingAni} 0.8s infinite;
    }
`
import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Grid } from '@mui/material'
import LineChartPart from '../part/LineChartPart';
import { LIST1, LIST2, LIST3 } from './data';


export default function MainPage() {
  const [list1,setList1] = useState<any>(LIST1);
  const [list2,setList2] = useState<any>(LIST2);
  const [list3,setList3] = useState<any>(LIST3);

  // useEffect(()=>{
  //   getNewUserList();
  //   getUserList();
  //   getMusicList();
  // },[])

  // const getNewUserList = async() =>{

  // }

  // const getUserList = async() =>{

  // }

  // const getMusicList = async() =>{

  // }

  return (
    <></>
    // <LeftNavBar idKey={'0'} titleText='' routerHistory={'/'}>
    //   <Grid container={true} spacing={1}>
    //     <Grid item xs={12}>
    //       <LineChartPart title='월간 가입자 수' list={list1} />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <LineChartPart title='일간 활성 사용자 수' list={list2} />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <LineChartPart title='월간 활성 사용자 수' list={list3} />
    //     </Grid>
    //   </Grid>
    // </LeftNavBar>
  )
}

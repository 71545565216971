import { MenuItem, Select } from '@mui/material';
import React from 'react'
import styled from 'styled-components'

export default function AppBooleanItem({value,setValue}:any) {
    return (
        <Wrap>
            <Select 
                size='small' 
                value={value} 
                onChange={(e:any)=>setValue(e.target.value)} 
                sx={{
                    '.MuiSelect-select':{padding:'4px',fontSize:'14px'},
                }}
            >
                <MenuItem value={'true'}>true</MenuItem>
                <MenuItem value={'false'}>false</MenuItem>
            </Select>
        </Wrap>
    )
}

const Wrap = styled.div``;

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { apiGetSearchUserList, apiGetUserList } from '../../../api/apiUser'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { Row } from '../../../style/basic/commonStyle';
import SearchPart from '../../common/part/SearchPart';
import ListPart from '../../common/part/ListPart';
import { REWARD_USER } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import { Button, TableRow } from '@mui/material';
import TableContentItem from '../../common/item/TableContentItem';

export default function RewardSendUserListPart({userList,setUserList}:any) {
    const userInfo = useUserInfo();
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(0);
    const [search,setSearch] = useState('');
    const [list,setList] = useState<any>([]);

    useEffect(()=>{
        setPage(1);
    },[search])

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        if(search === ''){
            getList();
        }else{
            getSearchList();
        }
    },[userInfo.jwt_token,search,page]);

    const getSearchList = async() => {
        const res = await apiGetSearchUserList(userInfo.jwt_token,userInfo.admin_id,page,search);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList(res.data);
            setTotal(0);
        }
    }

    const getList = async() => {
        const res = await apiGetUserList(userInfo.jwt_token,userInfo.admin_id,page,'1','');
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList(res.data);
            setTotal(0);
        }
    }

    // 검색
    const searchRes = (sc:string) =>{
        setSearch(sc);
    }

    // 추가
    const userListAdd = (data:any) =>{
        setUserList((arr:any) => [...arr,data]);
    }

    // 해제
    const userListDel = (data:any) =>{
        setUserList((arr:any) => arr.filter((item:any) => item.user_id != data.user_id));
    }

    return (
        <Wrap>
            <SearchPart setRes={searchRes} />
            <ListWrap>
                <ListPart titleArr={REWARD_USER} minWidth={500} nowPage={page} setPage={setPage} total={total}>
                    {list === undefined || list.length === 0 ?
                        <TableNoItem colSpan={REWARD_USER.length} /> :
                        list.map((data:any,index:any) =>{
                            const chk = userList.filter((item:any) => item.user_id === data.user_id);
                            return(
                                <TableRow key={`tableContent-${index}`}>
                                    <TableContentItem text={data.user_id}></TableContentItem>
                                    <TableContentItem>
                                        <img alt='profile' src={data.user_profile}></img>
                                    </TableContentItem>
                                    <TableContentItem text={data.user_nickname}></TableContentItem>
                                    <TableContentItem text={data.user_code}></TableContentItem>
                                    <TableContentItem>
                                        {chk.length === 0 ?
                                            <Button variant='contained' onClick={()=>userListAdd(data)}>선택</Button> :
                                            <Button variant='contained' onClick={()=>userListDel(data)} color='error'>해제</Button> 
                                        }
                                    </TableContentItem>
                                </TableRow>
                            )
                        })
                    }
                </ListPart>
            </ListWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ListWrap = styled.div`
    margin-top: 15px;
`;
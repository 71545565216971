import { axiosConfigInstance } from "./config";

// 음악 리스트 아이템
export const MUSIC_LIST_ITEM = {
    "sound_id": 0,
    "sound_title": "",
    "url": "",
    "genre": "RnB & Soul",
    "sound_category": "1",
    "sound_small_category": "3",
    "sound_bpm": "117",
    "sound_duration": "3:02",
    "sound_timestamp": "1685608810154",
    "sound_visible": "",
    "artist": "ATO creator 05",
    "user_id": 0,
    "play_count": 0
}

// 음악 카테고리 아이템
export const MUSIC_CATEGORY_ITEM =  {
    "category_id": 0,
    "sound_category": "0",
    "sound_small_category": "0",
    "category_title": "HipPop",
    "category_use": 0
}

// 음악 리스트
export const getMusicList = async(token:string,admin_id:any,status:any,page:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/sound',token).post('',{
            method:'adminGetSoundList',
            params:{
                admin_id,
                status,
                page,
                count:10,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 음악 검색 리스트
export const getSearchMusicList = async(token:string,admin_id:any,search_query:any,page:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/sound',token).post('',{
            method:'adminSearchSoundList',
            params:{
                admin_id,
                page,
                count:10,
                search_query,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 카테고리 리스트
export const getMusicCateList = async(token:string,admin_id:any,sound_category:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/sound',token).post('',{
            method:'adminGetSoundCategory',
            params:{
                admin_id,
                sound_category,
                page,
                count,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 카테고리 추가
export const apiSetMusicCateAdd = async(token:string,admin_id:any,title:string,code:any,use:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/sound',token).post('',{
            method:'adminRegisterCategory',
            params:{
                "admin_id": admin_id,
                "category_title": title,
                "sound_category": "1",
                "category_small": code,
                "category_use": use,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error
        }
    }
}

// 카테고리 수정
export const apiSetMusicCateUpdate = async(token:string,admin_id:any,title:string,id:any,code:any,use:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/sound',token).post('',{
            method:'adminModifyCategory',
            params:{
                "admin_id": admin_id,
                "category_title": title,
                "category_id": id,
                "sound_category": "1",
                "category_small": code,
                "category_use": use,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error
        }
    }
}

// 카테고리 삭제
export const apiSetMusicCateDel = async(token:string,admin_id:any,id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/sound',token).post('',{
            method:'adminDeleteCategory',
            params:{
                "admin_id": admin_id,
                "category_id": id,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 음악 심사
export const apiSetMusicState = async(token:string,admin_id:any,sound_id:any,sound_state:any,sound_reject_reason?:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/sound',token).post('',{
            method:'adminSoundChangeStatus',
            params:{
                admin_id,
                sound_id,
                sound_status:sound_state,
                sound_reject_reason
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 유튜브 플레이 리스트 목록
export const apiGetYouPlayList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/playlist',token).post('',{
            method:'adminGetYoutubePlaylist',
            params:{
                admin_id,
                page,
                count
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 유튜브 플레이 리스트 등록
export const apiSetAddYouList = async(token:string,admin_id:any,y_playlist_name:string,y_playlist_description:string,y_playlist_item:any,y_playlist_thumbnail:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','adminUploadYoutubePlaylist');
        formData.append('params', JSON.stringify({
            admin_id,
            y_playlist_name,
            y_playlist_description,
            y_playlist_item,
            // y_playlist_thumbnail
        }));
        formData.append('y_playlist_thumbnail',y_playlist_thumbnail);
        const res = await axiosConfigInstance(`/admin/playlist`,token,type).post('',formData);
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 유튜브 플레이 리스트 활성/비활성
export const apiSetYouListOnOff = async(token:string,admin_id:any,y_playlist_id:any,y_playlist_visible:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/playlist',token).post('',{
            method: 'adminDeleteYoutubePlaylist',
            params:{
                admin_id,
                y_playlist_id,
                y_playlist_visible,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 유튜브 플레이 리스트 디테일
export const apiSGetYouListDetail = async(token:string,admin_id:any,y_playlist_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/playlist',token).post('',{
            method: 'adminGetDetailYoutubePlaylist',
            params:{
                admin_id,
                y_playlist_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 유튜브 플레이 리스트 수정
export const apiSetUpdateYouList = async(token:string,admin_id:any,y_playlist_id:any,y_playlist_name:string,y_playlist_description:string,y_playlist_item:any,y_playlist_thumbnail:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','adminModifyYoutubePlaylist');
        formData.append('params', JSON.stringify({
            admin_id,
            y_playlist_id,
            y_playlist_name,
            y_playlist_description,
            y_playlist_item,
            // y_playlist_thumbnail
        }));
        formData.append('y_playlist_thumbnail',y_playlist_thumbnail);
        const res = await axiosConfigInstance(`/admin/playlist`,token,type).post('',formData);
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 유튜브 리스트
// sns_category  (0: all, 1: basic_feed / 2: outer_feed (ex. youtube))
// sns_small_category  (0: all, 1: basic feed / 2: kpop / 3: pop)
// sns_status (all: 전체 / 0: non_visible / 1: all_visible / 2: follow_visible(친구만 공개) / 3: 심사중)
export const apiGetYouList = async(token:string,admin_id:any,page:any,sns_type:string,sns_cate:string,status:any,search_query:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/sns',token).post('',{
            method:'adminSearchSnsFeed',
            params:{
                "admin_id": admin_id,
                "page": page,
                "count": "10",
                "sns_category": sns_type,
                "sns_small_category": sns_cate,
                "sns_status": status,
                "search_query":search_query,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}


// 음악 플레이리스트
export const apiGetMusicPlayList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/playlist',token).post('',{
            method: 'adminGetSoundPlaylist',
            params: {
                admin_id,
                page,
                count,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 음악 검색하기
export const apiGetSearchMusicList = async(token:string,admin_id:any,page:any,count:any,search_query:string,sound_category:string,sound_small_category:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/sound',token).post('',{
            method: 'adminSearchSound',
            params: {
                admin_id,
                page,
                count,
                search_query,
                sound_category,
                sound_small_category,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 음악 플레이 리스트 등록
export const apiSetAddMusicPlayList = async(token:string,admin_id:any,playlist_name:string,playlist_description:string,playlist_item:any,playlist_thumbnail:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','adminUploadSoundPlaylist');
        formData.append('params', JSON.stringify({
            admin_id,
            playlist_name,
            playlist_description,
            playlist_item,
        }));
        formData.append('playlist_thumbnail',playlist_thumbnail);
        const res = await axiosConfigInstance(`/admin/playlist`,token,type).post('',formData);
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 음악 플레이리스트 활성/비활성
export const apiSetMusicListOnOff = async(token:string,admin_id:any,playlist_id:any,playlist_visible:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/playlist',token).post('',{
            method: 'adminDeleteSoundPlaylist',
            params: {
                admin_id,
                playlist_id,
                playlist_visible,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 음악 플레이리스트 디테일
export const apiGetMusicListDetail = async(token:string,admin_id:any,playlist_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/playlist',token).post('',{
            method: 'adminGetDetailSoundPlaylist',
            params: {
                admin_id,
                playlist_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 음악 플레이리스트 수정
export const apiSetUpdateMusicPlayList = async(token:string,admin_id:any,playlist_id:any,playlist_name:string,playlist_description:string,playlist_item:any,playlist_thumbnail:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','adminModifySoundPlaylist');
        formData.append('params', JSON.stringify({
            admin_id,
            playlist_id,
            playlist_name,
            playlist_description,
            playlist_item,
        }));
        formData.append('playlist_thumbnail',playlist_thumbnail);
        const res = await axiosConfigInstance(`/admin/playlist`,token,type).post('',formData);
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import React, { useRef } from 'react'
import BasicText from '../../basic/BasicText';

interface inputItemType{
    value?:string | number;
    valueChange?:any;
    type?:'text'|'number'|'password'|any;
    label?:string;
    margin?:string;
    inputSx?:any;
    inputLabel?:any;
    inputProps?:any;
    fileRef?:any;
    multiline?:boolean;
    rows?:number;
    size?:"small" | "medium";
    placeHolder?:string;
}

const pwProps = {
    autoComplete:"off"
}

export default function InputItem({value,valueChange,type,label,margin,inputSx,inputLabel,inputProps,fileRef}:inputItemType) {
    let intLabel = inputLabel === 'none' ? '' : label;
    // const fileRef = useRef<any>(null);
    const changeAction = (e:any) =>{
        if(type === 'file'){
            valueChange(e);
        }else{
            valueChange(e.target.value);
        }
    }
    return (
        <ItemWrap margin={margin}>
            {label &&
                <BasicText marginBottom={1}>{label}</BasicText>
            }
            <TextField size='small' inputRef={fileRef} sx={inputSx} id={`input-${label}`} label={intLabel} variant="outlined" value={value} onChange={(e)=>changeAction(e)} type={type} inputProps={type==='password' ? pwProps : inputProps}/>
        </ItemWrap>
    )
}

const ItemWrap = styled.div<{margin?:string}>`
  margin: ${props=>props.margin ?? 0};
  max-width: 100%;
  &>div{
    max-width: 100%;
  }
`;
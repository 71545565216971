import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Grid } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import SingerInputPart from '../part/SingerInputPart';
import SingerListPart from '../part/SingerListPart';
import { AdminWrap } from '../../../style/basic/commonStyle';

const TYPE = ['엔터','제작사'];
const LIST = [
    {id: 1, code: 'suboxmusic', ch_name: '슈박스', name: '슈박스', type: 2},
    {id: 2, code: 'HYBELABELS', ch_name: 'HYBE LABELS', name: 'BTS', type: 1},
    {id: 3, code: 'SMTOWN', ch_name: 'SMTOWN', name: 'sm', type: 1},
    {id: 4, code: 'JYPEntertainment', ch_name: 'JYP Entertainment', name: 'jyp', type: 1},
]

export default function SingerListPage() {
    const pageTitle = '가수 리스트';
    const [selectItem,setSelectItem] = useState<any>();
    const [agencyList,setAgencyList] = useState<any>([]);

    useEffect(()=>{
        getList();
    },[])

    const getList = async() =>{
        setAgencyList(LIST);
    }

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'가수 설정'} />
                        <SingerInputPart selectItem={selectItem} setSelectItem={setSelectItem} agencyList={agencyList} />
                    </AdminWrap>
                </Grid>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'가수 리스트'} />
                        <SingerListPart setSelectItem={setSelectItem} />
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}


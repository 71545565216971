import { Button, Grid, TableCell, TableRow, Tooltip, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import TableContentItem from '../../common/item/TableContentItem'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import LeftNavBar from '../../common/part/LeftNavBar'
import { USER_TITLE } from '../../../data/tableTitle'
import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router'
import { walletMiddleSubstring } from '../../../utils/format/string'
import { DEVICE, USER_ITEM, apiGetSearchUserList, apiGetUserList, apiGetUserUrl, apiSetMainUser } from '../../../api/apiUser'
import { CopyAction } from '../../basic/extends/CopyExtends'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import ListPart from '../../common/part/ListPart'
import TableNoItem from '../../common/item/TableNoItem'
import { AdminWrap, Row, SearchWrap } from '../../../style/basic/commonStyle'
import UserListFilterPart from '../part/UserListFilterPart'
import SearchPart from '../../common/part/SearchPart'
import UserUrlOpenItem from '../item/UserUrlOpenItem'
import UserBtnPart from '../part/UserBtnPart'
import { FaAngleDown,FaAngleUp } from "react-icons/fa";
import { addTheme } from '../../../style/addTheme'

export default function UserListPage() {
    const pageTitle = '회원 정보 리스트';
    const navigate = useNavigate();
    const {page,sc} = useParams();
    let nowPage = Number(page) ?? 1;
    let searchRes = sc === undefined ? '' : String(sc);
    const user = useUserInfo();
    const [list,setList] = useState([USER_ITEM]);
    const [total,setTotal] = useState(1);
    const [target_membership,setTarget_membership] = useState('');
    const [target_device,setTarget_device] = useState('');

    const [open,setOpen] = useState(false);
    const [urlData,setUrlData] = useState<any>({});
    const [selectItem,setSelectItem] = useState<any>();
    const [btnOpen,setBtnOpen] = useState(false);

    const [sortName,setSortName] = useState('');
    const [sort,setSort] = useState(true);
    const mediaQuery = useMediaQuery(addTheme.media.m);

    useEffect(()=>{
        if(selectItem){
            setBtnOpen(true);
        }else{
            setBtnOpen(false);
        }
    },[selectItem])
    
    useEffect(()=>{
        if(user.jwt_token === '') return;
        if(searchRes !== '') return;
        getList();
    },[user.jwt_token,nowPage,target_membership,target_device,searchRes]);

    useEffect(()=>{
        if(user.jwt_token === '') return;
        if(searchRes === '') return;
        getSearchList();
    },[user.jwt_token,searchRes,nowPage]);

    // 유저 검색
    const getSearchList = async() =>{
        const res = await apiGetSearchUserList(user.jwt_token,user.admin_id,nowPage,searchRes);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
            return;
        }
        setTotal(0);
        setList([]);
    }

    // 유저 리스트 api
    const getList = async() =>{
        const res = await apiGetUserList(user.jwt_token,user.admin_id,nowPage,target_membership,target_device);
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
            return;
        }
        setTotal(0);
        setList([]);
    }

    // 차단
    const blockEvent = async() =>{
        let result = selectItem.user_block === 0 ? 1 : 0;
        let text = selectItem.user_block === 0 ? '해제' : '차단';
        if(window.confirm(`${text} 하시겠습니까?`)){
            // const res = await apiSetUserBlock(id,result,user.admin_token);
            // if(res.result){
            //     alert(`${text} 되었습니다.`);
            //     getList();
            // }else{
            //     alert(res.error);
            // }
        }
    }

    const copyClick = (text:any) =>{
        CopyAction(text);
    }
    // 검색
    const searchAction = (val:string) =>{
        navigate(`/userList/1/${val}`);
    }

    // 연동 url, 코드
    const getUserUrl = async() =>{
        const res = await apiGetUserUrl(user.jwt_token,user.admin_id,selectItem.user_id);
        selectReset();
        if(res.result){
            setOpen(true);
            setUrlData(res.data);
        }else{
            setOpen(false);
        }
    }

    // btn select reset
    const selectReset = () =>{
        setSelectItem(undefined);
    }

    // 메인 등록 / 해제
    const userMainUpdate = async() =>{
        let main = selectItem.is_main_artist === '0' ? '1' : '0';
        let resStr = selectItem.is_main_artist === '0' ? '등록' : '해제';
        const res = await apiSetMainUser(user.jwt_token,user.admin_id,selectItem.user_id,main);
        if(res.result){
            alert(`메인으로 ${resStr}되었습니다.`);
            selectReset();
            getList();
        }
    }

    const titleClick = (title:string) =>{
        if(title === '최근접속일' || title === '업로드한 음원수'){
            if(sortName === title){
                setSort(!sort);
                if(title === '업로드한 음원수'){
                    setList((arr:any) => {
                        return arr.sort((a:any,b:any)=>{ return sort ? (a.sound_count - b.sound_count) : (b.sound_count - a.sound_count) });
                        
                    });
                }else if(title === '최근접속일'){
                    setList((arr:any) => {
                        return arr.sort((a:any,b:any)=>{ return sort ? Number(a.user_recent_timestamp) - Number(b.user_recent_timestamp) : Number(b.user_recent_timestamp) - Number(a.user_recent_timestamp) });
                        
                    });
                }
            }else{
                setSort(true);
                setSortName(title);
                if(title === '업로드한 음원수'){
                    setList((arr:any) => {
                        return arr.sort((a:any,b:any)=>{ return (b.sound_count - a.sound_count)})
                    });
                }else if(title === '최근접속일'){
                    setList((arr:any) => {
                        return arr.sort((a:any,b:any)=>{ return Number(b.user_recent_timestamp) - Number(a.user_recent_timestamp)})
                    });
                }

            }
        }
    }
    return (
        <>
            {/* <Grid container={true}>
                <Grid item md={4} sm={12}>
                    <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2}}>
                        <TableWrapTitleItem title={'가장 많은 포인트'}></TableWrapTitleItem>
                        <TextItem textAlign='right' fontSize={29} fw={700}>{numberWithComma(4000)}</TextItem>
                    </Box>
                </Grid>
            </Grid> */}
            <AdminWrap>
                <TableWrapTitleItem title={pageTitle}>
                    <SearchWrap>
                        <UserListFilterPart target_membership={target_membership} setTarget_membership={setTarget_membership} target_device={target_device} setTarget_device={setTarget_device} />
                        <SearchPart setRes={searchAction} />
                    </SearchWrap>
                </TableWrapTitleItem>
                <ListPart titleArr={USER_TITLE} minWidth={850} nowPage={nowPage} total={total} link={'userList'} searchRes={searchRes}
                    titleChildren={<TableRow sx={{bgcolor:'#5157ff'}}>
                        {USER_TITLE.map((data:any,index:any)=>{
                            return(
                                <TableCell onClick={()=>titleClick(data.name)} key={`title-${index}`} sx={{width:`${data.size}%`,color:'#fff'}} align={'center'}>
                                    {data.name}{(sortName === data.name) ? sort ? <FaAngleUp /> : <FaAngleDown /> : <></>}
                                </TableCell>
                            )
                        })}
                    </TableRow>}
                >
                    {list === undefined || list.length === 0 ?
                        <TableNoItem colSpan={USER_TITLE.length}></TableNoItem> :
                        list.map((data,index:number)=>{
                            return(
                                <TableRow
                                    key={`tableContent-${index}`}
                                >
                                    <TableContentItem text={data.user_id}></TableContentItem>
                                    <TableContentItem>
                                        <img style={{width:'40px',height:'40px'}} alt='profile' src={data.user_profile}></img>
                                    </TableContentItem>
                                    <TableContentItem>
                                        <Text>{data.user_nickname}</Text>
                                        <Tooltip title={data.user_code} arrow={true}>
                                            <TextSm onClick={()=>copyClick(data.user_code)}>({walletMiddleSubstring(data.user_code)})</TextSm>
                                        </Tooltip>
                                    </TableContentItem>
                                    <TableContentItem text={0}></TableContentItem>
                                    <TableContentItem text={data.user_recent_timestamp} type='time' />
                                    <TableContentItem text={data.user_timestamp} type='time' />
                                    <TableContentItem text={data.sound_count}></TableContentItem>
                                    <TableContentItem>
                                        <Tooltip title={data.user_address} arrow={true}>
                                            <Text onClick={()=>copyClick(data.user_address)}>{walletMiddleSubstring(data.user_address)}</Text>
                                        </Tooltip>
                                    </TableContentItem>
                                    <TableContentItem text={data.user_country}></TableContentItem>
                                    <TableContentItem text={DEVICE[Number(data.user_device)-1]}></TableContentItem>
                                    <TableContentItem text={data.user_parent_code}></TableContentItem>
                                    <TableContentItem>
                                        <Button variant='contained' onClick={()=>setSelectItem(data)}>관리</Button>
                                    </TableContentItem>
                                    {/* <TableContentItem>
                                        <Button 
                                            sx={{margin:'0 2px'}} 
                                            onClick={()=>blockEvent(data.user_id, data.user_block)} 
                                            color={data.user_block === '0' ? 'error' : 'info'}
                                            variant='contained' 
                                        >
                                            {data.user_block === '0' ? '차단' : '해제'}
                                        </Button>
                                    </TableContentItem>
                                    <TableContentItem>
                                        <Button variant='contained' onClick={()=>getUserUrl(data.user_id)}>연동</Button>
                                    </TableContentItem> */}
                                </TableRow>
                            )
                        })
                    }
                </ListPart>
            </AdminWrap>
            <UserUrlOpenItem open={open} setOpen={setOpen} data={urlData} />
            <UserBtnPart open={btnOpen} setOpen={selectReset} selectItem={selectItem} blockEvent={blockEvent} getUserUrl={getUserUrl} userMainUpdate={userMainUpdate} />
        </>
    )
}

const Text = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-size: 14px;
`;

const TextSm = styled.div`
    overflow-wrap: anywhere;
    font-size: 12px;
`;

import React from 'react'
import styled, { keyframes } from 'styled-components';
import { Center } from '../../../style/basic/commonStyle';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export default function LoadingItem() {
    return (
        <LoadingWrap>
            <LoadingIcon>
                <AiOutlineLoading3Quarters size={20} />
            </LoadingIcon>
        </LoadingWrap>
    )
}

const loadingAni = keyframes`
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
`;
const LoadingWrap = styled(Center)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;
const LoadingIcon = styled.div`
    & svg{
        animation: ${loadingAni} 0.8s infinite;
    }
`
import { Button, Grid, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import TableContentItem from '../../common/item/TableContentItem'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import LeftNavBar from '../../common/part/LeftNavBar'
import { GAME_LOG, GAME_LOG_TITLE, GAME_TITLE } from '../../../data/tableTitle'
import styled from '@emotion/styled'
import { useNavigate, useParams } from 'react-router'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import ListPart from '../../common/part/ListPart'
import TableNoItem from '../../common/item/TableNoItem'
import { Center } from '../../../style/basic/commonStyle'
import { apiGetGameList, apiGetGameLogList } from '../../../api/apiGame'

const REWARD = ['미지급','지급'];

export default function GameLogListPage() {
    const pageTitle = '게임 로그 리스트';
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const {gamePage,id,logPage} = useParams();
    let nowGamePage = Number(gamePage) ?? 1;
    let nowLogPage = Number(logPage) ?? 1;
    let gameId = Number(id) ?? 1;
    const [gameList,setGameList] = useState<any>([]);
    const [gameTotal,setGameTotal] = useState(1);
    const [logList,setLogList] = useState<any>([]);
    const [logTotal,setLogTotal] = useState(1);

    useEffect(()=>{
        getGameList();
    },[nowGamePage]);

    useEffect(()=>{
        if(gameId == 0){
            setLogList([]);
            return;
        }
        getGameLogList();
    },[nowLogPage,gameId])

    // 게임 리스트 api
    const getGameList = async() =>{
        const res = await apiGetGameList(userInfo.jwt_token,userInfo.admin_id,nowGamePage,10);
        if(res.result){
            setGameList(res.data);
            setGameTotal(res.total);
        }else{
            setGameList([]);
            setGameTotal(0);
        }
    }

    // 로그 리스트 api
    const getGameLogList = async() =>{
        const res = await apiGetGameLogList(userInfo.jwt_token,userInfo.admin_id,gameId,nowLogPage,10);
        if(res.result){
            setLogList(res.data);
            setLogTotal(res.total)
        }else{
            setLogList([]);
            setLogTotal(0);
        }
        
    }

    // 게임페이지 페이지 변경
    const gamePageChange = (page:any) =>{
        navigate(`/gameLogList/${page}/${gameId}/${nowLogPage}`);
    }
    // 게임로그페이지 페이지 변경
    const gameLogPageChange = (page:any) =>{
        navigate(`/gameLogList/${nowGamePage}/${gameId}/${page}`);
    }

    // 로그선택
    const logSelect = (id:any) =>{
        navigate(`/gameLogList/${nowGamePage}/${id}/1`);
    }
    
    return (
        <LeftNavBar idKey={'8-2'} titleText={pageTitle}>
            <Grid container={true} spacing={3}>
                <Grid item md={5}>
                    <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2, marginTop:'15px'}}>
                        <TableWrapTitleItem title={'게임 리스트'} />
                        <ListPart titleArr={GAME_LOG_TITLE} nowPage={nowGamePage} setPage={gamePageChange} total={gameTotal} >
                            {gameList === undefined || gameList.length === 0 ?
                                <TableNoItem colSpan={GAME_TITLE.length} /> :
                                gameList.map((data:any,index:any)=>{
                                   
                                    return(
                                        <TableRow key={`table-content-${index}`}>
                                            <TableContentItem text={data.game_id} />
                                            <TableContentItem text={data.game_icon_url} >
                                                <img alt='game-img' src={data.game_icon_url} />
                                            </TableContentItem>
                                            <TableContentItem text={data.game_name} />
                                            <TableContentItem text={data.game_description} />
                                            <TableContentItem>
                                                <Button variant='contained' onClick={()=>logSelect(data.game_id)}>로그</Button>
                                            </TableContentItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </Box>
                </Grid>
                <Grid item md={7}>
                    <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2, marginTop:'15px'}}>
                        <TableWrapTitleItem title={pageTitle} />
                        <ListPart titleArr={GAME_LOG} minWidth={700} nowPage={nowLogPage} total={logTotal} setPage={gameLogPageChange} >
                            {logList === undefined || logList.length === 0 ?
                                <TableNoItem colSpan={GAME_LOG.length} txt='게임을 선택해주세요.' /> :
                                logList.map((data:any,index:any)=>{
                                    const rewardStr = REWARD[Number(data.game_log_is_reward)]
                                    return(
                                        <TableRow key={`log-table-content-${index}`}>
                                            <TableContentItem>
                                                <img alt='game-img' src={data.user_profile}></img>
                                            </TableContentItem>
                                            <TableContentItem text={data.user_nickname} />
                                            <TableContentItem text={data.game_log_score} />
                                            <TableContentItem text={rewardStr} />
                                            <TableContentItem text={data.game_log_timestamp} type='time' />
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </Box>
                </Grid>
            </Grid>
        </LeftNavBar>
    )
}

const GameItemWrap = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &>img{
        width: 80%;
    }
    border: 1px solid #eee;
    padding: 8px;
`;

const PaginationWrap = styled(Center)`
    margin-top: 15px;
`;
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Center, Row } from '../../../style/basic/commonStyle';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useLocation } from 'react-router';

export default function MenuPart({data,idKey,GoHistory}:any) {
    const location = useLocation();
    const [openDrop,setOpenDrop] = useState(false);
    const [activeState,setActiveState] = useState(false);

    useEffect(()=>{
        if(data?.sub === undefined) return;
        let chk = data.sub.find((item:any)=>item.router === location.pathname);
        if(chk === undefined || chk.length === 0){
            setActiveState(false);
        }else{
            setActiveState(true);
        }
    },[location.pathname,data.routerName.sub])

    const handleClick = (data:any) => {
        if(GoHistory){
            if(data.router !== ''){
                GoHistory(data.router);
            }
        }
        setOpenDrop(!openDrop);
    };
    
    return (
        <div>
            <ListItemButton onClick={()=>handleClick(data)}>
                <MenuWrap fontSize={18}>
                    <ListItemText primary={data.name} sx={{color:activeState ? '#fff':'#ffff'}} />
                    {data?.sub.length !== 0 &&
                        <MenuIcon>
                            {openDrop ?
                                <FaAngleUp color='#fff' /> :
                                <FaAngleDown color='#fff'/>
                            }
                        </MenuIcon>
                    }
                </MenuWrap>
                <BgWrap bgShow={activeState}></BgWrap>
            </ListItemButton>
            <Collapse in={openDrop} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {data?.sub?.map((subData:any,index:any)=>{
                        const chk = location.pathname.includes(subData.routerName)
                        const showChk = subData?.show === false;
                        if(showChk) return
                        return(
                            <ListItemButton key={`sub-${data.id}-${index}`} sx={{color:chk? '#0085ff' : '#111', bgcolor:'#fff','&:hover':{backgroundColor:'#f0f0f0cd'}}} onClick={()=>GoHistory(subData.router,subData.type)}>
                                <MenuWrap fontSize={14}>
                                    <ListItemText primary={subData.name} />
                                </MenuWrap>
                            </ListItemButton>
                        )
                    })}
                </List>
            </Collapse>
        </div>
    )
}

const MenuWrap = styled(Row)<{fontSize?:number}>`
    position: relative;
    z-index: 2;
    padding: 6px 0 6px 15px;
    & .MuiTypography-root{
        font-size: ${props=>props.fontSize === undefined ? 15 : props.fontSize}px;
    }
`;

const MenuIcon = styled(Center)`
    width: 40px;
`;

const BgWrap = styled.div<{bgShow:boolean}>`
    position: absolute;
    top:0;
    left:0;
    width: ${props => props.bgShow ? '5px' : '0'};
    height: 100%;
    background-color: #fff;
    transition: 0.2s ease-in-out;
    z-index: 1;
`;
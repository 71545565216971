import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ListPart from '../../common/part/ListPart';
import { STAT_USER_REGISTER } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import { apiGetStatUserSignList } from '../../../api/apiStat';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { TableRow } from '@mui/material';
import TableContentItem from '../../common/item/TableContentItem';
import { DEVICE_OBJ } from '../../../api/apiUser';
import LoadingItem from '../item/LoadingItem';

export default function StatUserSignListPart({page,setPage}:any) {
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const [loading,setLoading] = useState(false);
    const userInfo = useUserInfo();

    useEffect(()=>{
        getList();
    },[page])

    const getList = async() =>{
        setLoading(true);
        const res = await apiGetStatUserSignList(userInfo.jwt_token,userInfo.admin_id,page,15);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
        setLoading(false);
    }

    return (
        <Wrap>
            <ListPart titleArr={STAT_USER_REGISTER} minWidth={450} total={total} nowPage={page} setPage={setPage}>
                {list.length === 0 ?
                    <TableNoItem colSpan={STAT_USER_REGISTER.length} loading={loading} /> :
                    list.map((data:any,index:any)=>{
                        return(
                            <TableRow
                                key={`tableContent-${index}`}
                            >
                                <TableContentItem text={DEVICE_OBJ[data.device_code]}/>
                                <TableContentItem text={data.user_country === '' ? 'KR' : data.user_country} />
                                <TableContentItem text={data.user_timestamp} type='time' />
                            </TableRow>
                        )
                    })
                }
            </ListPart>
            {list.length !== 0 && loading && <LoadingItem />}
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;
export const LIST1 = [{"name":"2022-04","data":17},
{"name":"2022-05","data":28},
{"name":"2022-06","data":37},
{"name":"2022-07","data":38},
{"name":"2022-08","data":59},
{"name":"2022-09","data":34},
{"name":"2022-10","data":32},
{"name":"2022-11","data":41},
{"name":"2022-12","data":33},
{"name":"2023-01","data":37},
{"name":"2023-02","data":27},
{"name":"2023-03","data":97},
{"name":"2023-04","data":93},
{"name":"2023-05","data":107},
{"name":"2023-06","data":127},
{"name":"2023-07","data":96},
{"name":"2023-08","data":665},
{"name":"2023-09","data":334},
{"name":"2023-10","data":581},
{"name":"2023-11","data":892},
{"name":"2023-12","data":875},
{"name":"2024-01","data":2142},
{"name":"2024-02","data":2186},
{"name":"2024-03","data":1617},
]

export const LIST2 = [{"name":"2022-04-20","data":22},
{"name":"2022-04-21","data":56},
{"name":"2022-04-22","data":62},
{"name":"2022-04-23","data":56},
{"name":"2022-04-24","data":71},
{"name":"2022-04-25","data":61},
{"name":"2022-04-26","data":77},
{"name":"2022-04-27","data":48},
{"name":"2022-04-28","data":53},
{"name":"2022-04-29","data":71},
{"name":"2022-04-30","data":59},
{"name":"2022-05-01","data":75},
{"name":"2022-05-02","data":46},
{"name":"2022-05-03","data":28},
{"name":"2022-05-04","data":50},
{"name":"2022-05-05","data":82},
{"name":"2022-05-06","data":61},
{"name":"2022-05-07","data":57},
{"name":"2022-05-08","data":73},
{"name":"2022-05-09","data":73},
{"name":"2022-05-10","data":79},
{"name":"2022-05-11","data":50},
{"name":"2022-05-12","data":51},
{"name":"2022-05-13","data":51},
{"name":"2022-05-14","data":72},
{"name":"2022-05-15","data":52},
{"name":"2022-05-16","data":66},
{"name":"2022-05-17","data":72},
{"name":"2022-05-18","data":75},
{"name":"2022-05-19","data":58},
{"name":"2022-05-20","data":58},
{"name":"2022-05-21","data":36},
{"name":"2022-05-22","data":55},
{"name":"2022-05-23","data":84},
{"name":"2022-05-24","data":101},
{"name":"2022-05-25","data":71},
{"name":"2022-05-26","data":63},
{"name":"2022-05-27","data":62},
{"name":"2022-05-28","data":53},
{"name":"2022-05-29","data":64},
{"name":"2022-05-30","data":59},
{"name":"2022-05-31","data":70},
{"name":"2022-06-01","data":87},
{"name":"2022-06-02","data":97},
{"name":"2022-06-03","data":71},
{"name":"2022-06-04","data":85},
{"name":"2022-06-05","data":62},
{"name":"2022-06-06","data":49},
{"name":"2022-06-07","data":47},
{"name":"2022-06-08","data":51},
{"name":"2022-06-09","data":52},
{"name":"2022-06-10","data":73},
{"name":"2022-06-11","data":79},
{"name":"2022-06-12","data":65},
{"name":"2022-06-13","data":65},
{"name":"2022-06-14","data":75},
{"name":"2022-06-15","data":65},
{"name":"2022-06-16","data":61},
{"name":"2022-06-17","data":61},
{"name":"2022-06-18","data":64},
{"name":"2022-06-19","data":54},
{"name":"2022-06-20","data":51},
{"name":"2022-06-21","data":55},
{"name":"2022-06-22","data":73},
{"name":"2022-06-23","data":65},
{"name":"2022-06-24","data":85},
{"name":"2022-06-25","data":57},
{"name":"2022-06-26","data":66},
{"name":"2022-06-27","data":44},
{"name":"2022-06-28","data":57},
{"name":"2022-06-29","data":74},
{"name":"2022-06-30","data":67},
{"name":"2022-07-01","data":78},
{"name":"2022-07-02","data":102},
{"name":"2022-07-03","data":50},
{"name":"2022-07-04","data":36},
{"name":"2022-07-05","data":22},
{"name":"2022-07-06","data":63},
{"name":"2022-07-07","data":81},
{"name":"2022-07-08","data":58},
{"name":"2022-07-09","data":59},
{"name":"2022-07-10","data":91},
{"name":"2022-07-11","data":57},
{"name":"2022-07-12","data":40},
{"name":"2022-07-13","data":70},
{"name":"2022-07-14","data":30},
{"name":"2022-07-15","data":62},
{"name":"2022-07-16","data":57},
{"name":"2022-07-17","data":54},
{"name":"2022-07-18","data":58},
{"name":"2022-07-19","data":82},
{"name":"2022-07-20","data":69},
{"name":"2022-07-21","data":42},
{"name":"2022-07-22","data":46},
{"name":"2022-07-23","data":62},
{"name":"2022-07-24","data":67},
{"name":"2022-07-25","data":65},
{"name":"2022-07-26","data":68},
{"name":"2022-07-27","data":58},
{"name":"2022-07-28","data":87},
{"name":"2022-07-29","data":65},
{"name":"2022-07-30","data":92},
{"name":"2022-07-31","data":64},
{"name":"2022-08-01","data":68},
{"name":"2022-08-02","data":50},
{"name":"2022-08-03","data":61},
{"name":"2022-08-04","data":57},
{"name":"2022-08-05","data":72},
{"name":"2022-08-06","data":88},
{"name":"2022-08-07","data":49},
{"name":"2022-08-08","data":59},
{"name":"2022-08-09","data":71},
{"name":"2022-08-10","data":49},
{"name":"2022-08-11","data":56},
{"name":"2022-08-12","data":72},
{"name":"2022-08-13","data":79},
{"name":"2022-08-14","data":53},
{"name":"2022-08-15","data":35},
{"name":"2022-08-16","data":38},
{"name":"2022-08-17","data":57},
{"name":"2022-08-18","data":60},
{"name":"2022-08-19","data":38},
{"name":"2022-08-20","data":78},
{"name":"2022-08-21","data":69},
{"name":"2022-08-22","data":73},
{"name":"2022-08-23","data":78},
{"name":"2022-08-24","data":57},
{"name":"2022-08-25","data":67},
{"name":"2022-08-26","data":70},
{"name":"2022-08-27","data":60},
{"name":"2022-08-28","data":43},
{"name":"2022-08-29","data":78},
{"name":"2022-08-30","data":59},
{"name":"2022-08-31","data":58},
{"name":"2022-09-01","data":61},
{"name":"2022-09-02","data":92},
{"name":"2022-09-03","data":57},
{"name":"2022-09-04","data":51},
{"name":"2022-09-05","data":58},
{"name":"2022-09-06","data":78},
{"name":"2022-09-07","data":75},
{"name":"2022-09-08","data":54},
{"name":"2022-09-09","data":52},
{"name":"2022-09-10","data":58},
{"name":"2022-09-11","data":45},
{"name":"2022-09-12","data":49},
{"name":"2022-09-13","data":80},
{"name":"2022-09-14","data":57},
{"name":"2022-09-15","data":56},
{"name":"2022-09-16","data":77},
{"name":"2022-09-17","data":67},
{"name":"2022-09-18","data":52},
{"name":"2022-09-19","data":83},
{"name":"2022-09-20","data":91},
{"name":"2022-09-21","data":54},
{"name":"2022-09-22","data":51},
{"name":"2022-09-23","data":32},
{"name":"2022-09-24","data":79},
{"name":"2022-09-25","data":62},
{"name":"2022-09-26","data":63},
{"name":"2022-09-27","data":87},
{"name":"2022-09-28","data":60},
{"name":"2022-09-29","data":57},
{"name":"2022-09-30","data":59},
{"name":"2022-10-01","data":82},
{"name":"2022-10-02","data":58},
{"name":"2022-10-03","data":74},
{"name":"2022-10-04","data":81},
{"name":"2022-10-05","data":69},
{"name":"2022-10-06","data":68},
{"name":"2022-10-07","data":81},
{"name":"2022-10-08","data":57},
{"name":"2022-10-09","data":87},
{"name":"2022-10-10","data":88},
{"name":"2022-10-11","data":62},
{"name":"2022-10-12","data":48},
{"name":"2022-10-13","data":65},
{"name":"2022-10-14","data":62},
{"name":"2022-10-15","data":62},
{"name":"2022-10-16","data":40},
{"name":"2022-10-17","data":38},
{"name":"2022-10-18","data":38},
{"name":"2022-10-19","data":46},
{"name":"2022-10-20","data":59},
{"name":"2022-10-21","data":80},
{"name":"2022-10-22","data":71},
{"name":"2022-10-23","data":88},
{"name":"2022-10-24","data":84},
{"name":"2022-10-25","data":56},
{"name":"2022-10-26","data":56},
{"name":"2022-10-27","data":45},
{"name":"2022-10-28","data":55},
{"name":"2022-10-29","data":66},
{"name":"2022-10-30","data":51},
{"name":"2022-10-31","data":97},
{"name":"2022-11-01","data":59},
{"name":"2022-11-02","data":70},
{"name":"2022-11-03","data":61},
{"name":"2022-11-04","data":69},
{"name":"2022-11-05","data":58},
{"name":"2022-11-06","data":47},
{"name":"2022-11-07","data":36},
{"name":"2022-11-08","data":37},
{"name":"2022-11-09","data":69},
{"name":"2022-11-10","data":67},
{"name":"2022-11-11","data":41},
{"name":"2022-11-12","data":56},
{"name":"2022-11-13","data":76},
{"name":"2022-11-14","data":54},
{"name":"2022-11-15","data":34},
{"name":"2022-11-16","data":48},
{"name":"2022-11-17","data":56},
{"name":"2022-11-18","data":47},
{"name":"2022-11-19","data":59},
{"name":"2022-11-20","data":62},
{"name":"2022-11-21","data":53},
{"name":"2022-11-22","data":50},
{"name":"2022-11-23","data":37},
{"name":"2022-11-24","data":91},
{"name":"2022-11-25","data":84},
{"name":"2022-11-26","data":75},
{"name":"2022-11-27","data":74},
{"name":"2022-11-28","data":62},
{"name":"2022-11-29","data":74},
{"name":"2022-11-30","data":51},
{"name":"2022-12-01","data":56},
{"name":"2022-12-02","data":41},
{"name":"2022-12-03","data":43},
{"name":"2022-12-04","data":57},
{"name":"2022-12-05","data":68},
{"name":"2022-12-06","data":74},
{"name":"2022-12-07","data":86},
{"name":"2022-12-08","data":66},
{"name":"2022-12-09","data":58},
{"name":"2022-12-10","data":41},
{"name":"2022-12-11","data":61},
{"name":"2022-12-12","data":46},
{"name":"2022-12-13","data":55},
{"name":"2022-12-14","data":86},
{"name":"2022-12-15","data":72},
{"name":"2022-12-16","data":81},
{"name":"2022-12-17","data":65},
{"name":"2022-12-18","data":50},
{"name":"2022-12-19","data":44},
{"name":"2022-12-20","data":55},
{"name":"2022-12-21","data":82},
{"name":"2022-12-22","data":56},
{"name":"2022-12-23","data":58},
{"name":"2022-12-24","data":63},
{"name":"2022-12-25","data":45},
{"name":"2022-12-26","data":66},
{"name":"2022-12-27","data":45},
{"name":"2022-12-28","data":72},
{"name":"2022-12-29","data":49},
{"name":"2022-12-30","data":34},
{"name":"2022-12-31","data":58},
{"name":"2023-01-01","data":84},
{"name":"2023-01-02","data":70},
{"name":"2023-01-03","data":73},
{"name":"2023-01-04","data":54},
{"name":"2023-01-05","data":55},
{"name":"2023-01-06","data":72},
{"name":"2023-01-07","data":57},
{"name":"2023-01-08","data":61},
{"name":"2023-01-09","data":75},
{"name":"2023-01-10","data":52},
{"name":"2023-01-11","data":61},
{"name":"2023-01-12","data":77},
{"name":"2023-01-13","data":74},
{"name":"2023-01-14","data":55},
{"name":"2023-01-15","data":52},
{"name":"2023-01-16","data":85},
{"name":"2023-01-17","data":88},
{"name":"2023-01-18","data":50},
{"name":"2023-01-19","data":50},
{"name":"2023-01-20","data":56},
{"name":"2023-01-21","data":86},
{"name":"2023-01-22","data":67},
{"name":"2023-01-23","data":78},
{"name":"2023-01-24","data":72},
{"name":"2023-01-25","data":64},
{"name":"2023-01-26","data":87},
{"name":"2023-01-27","data":75},
{"name":"2023-01-28","data":58},
{"name":"2023-01-29","data":55},
{"name":"2023-01-30","data":57},
{"name":"2023-01-31","data":87},
{"name":"2023-02-01","data":51},
{"name":"2023-02-02","data":78},
{"name":"2023-02-03","data":78},
{"name":"2023-02-04","data":58},
{"name":"2023-02-05","data":56},
{"name":"2023-02-06","data":47},
{"name":"2023-02-07","data":65},
{"name":"2023-02-08","data":64},
{"name":"2023-02-09","data":79},
{"name":"2023-02-10","data":53},
{"name":"2023-02-11","data":56},
{"name":"2023-02-12","data":53},
{"name":"2023-02-13","data":73},
{"name":"2023-02-14","data":78},
{"name":"2023-02-15","data":56},
{"name":"2023-02-16","data":69},
{"name":"2023-02-17","data":88},
{"name":"2023-02-18","data":72},
{"name":"2023-02-19","data":63},
{"name":"2023-02-20","data":65},
{"name":"2023-02-21","data":69},
{"name":"2023-02-22","data":71},
{"name":"2023-02-23","data":47},
{"name":"2023-02-24","data":40},
{"name":"2023-02-25","data":65},
{"name":"2023-02-26","data":83},
{"name":"2023-02-27","data":73},
{"name":"2023-02-28","data":59},
{"name":"2023-03-01","data":82},
{"name":"2023-03-02","data":98},
{"name":"2023-03-03","data":63},
{"name":"2023-03-04","data":88},
{"name":"2023-03-05","data":78},
{"name":"2023-03-06","data":82},
{"name":"2023-03-07","data":72},
{"name":"2023-03-08","data":88},
{"name":"2023-03-09","data":83},
{"name":"2023-03-10","data":49},
{"name":"2023-03-11","data":78},
{"name":"2023-03-12","data":80},
{"name":"2023-03-13","data":55},
{"name":"2023-03-14","data":77},
{"name":"2023-03-15","data":73},
{"name":"2023-03-16","data":42},
{"name":"2023-03-17","data":63},
{"name":"2023-03-18","data":93},
{"name":"2023-03-19","data":65},
{"name":"2023-03-20","data":55},
{"name":"2023-03-21","data":83},
{"name":"2023-03-22","data":44},
{"name":"2023-03-23","data":54},
{"name":"2023-03-24","data":57},
{"name":"2023-03-25","data":65},
{"name":"2023-03-26","data":69},
{"name":"2023-03-27","data":86},
{"name":"2023-03-28","data":67},
{"name":"2023-03-29","data":59},
{"name":"2023-03-30","data":76},
{"name":"2023-03-31","data":51},
{"name":"2023-04-01","data":34},
{"name":"2023-04-02","data":53},
{"name":"2023-04-03","data":88},
{"name":"2023-04-04","data":48},
{"name":"2023-04-05","data":66},
{"name":"2023-04-06","data":74},
{"name":"2023-04-07","data":50},
{"name":"2023-04-08","data":60},
{"name":"2023-04-09","data":63},
{"name":"2023-04-10","data":69},
{"name":"2023-04-11","data":63},
{"name":"2023-04-12","data":68},
{"name":"2023-04-13","data":68},
{"name":"2023-04-14","data":57},
{"name":"2023-04-15","data":71},
{"name":"2023-04-16","data":51},
{"name":"2023-04-17","data":44},
{"name":"2023-04-18","data":20},
{"name":"2023-04-19","data":53},
{"name":"2023-04-20","data":69},
{"name":"2023-04-21","data":76},
{"name":"2023-04-22","data":91},
{"name":"2023-04-23","data":84},
{"name":"2023-04-24","data":89},
{"name":"2023-04-25","data":60},
{"name":"2023-04-26","data":49},
{"name":"2023-04-27","data":100},
{"name":"2023-04-28","data":79},
{"name":"2023-04-29","data":68},
{"name":"2023-04-30","data":77},
{"name":"2023-05-01","data":72},
{"name":"2023-05-02","data":84},
{"name":"2023-05-03","data":90},
{"name":"2023-05-04","data":65},
{"name":"2023-05-05","data":65},
{"name":"2023-05-06","data":85},
{"name":"2023-05-07","data":88},
{"name":"2023-05-08","data":72},
{"name":"2023-05-09","data":76},
{"name":"2023-05-10","data":79},
{"name":"2023-05-11","data":58},
{"name":"2023-05-12","data":41},
{"name":"2023-05-13","data":63},
{"name":"2023-05-14","data":52},
{"name":"2023-05-15","data":55},
{"name":"2023-05-16","data":62},
{"name":"2023-05-17","data":61},
{"name":"2023-05-18","data":63},
{"name":"2023-05-19","data":37},
{"name":"2023-05-20","data":31},
{"name":"2023-05-21","data":51},
{"name":"2023-05-22","data":62},
{"name":"2023-05-23","data":64},
{"name":"2023-05-24","data":69},
{"name":"2023-05-25","data":79},
{"name":"2023-05-26","data":59},
{"name":"2023-05-27","data":75},
{"name":"2023-05-28","data":81},
{"name":"2023-05-29","data":61},
{"name":"2023-05-30","data":72},
{"name":"2023-05-31","data":43},
{"name":"2023-06-01","data":58},
{"name":"2023-06-02","data":59},
{"name":"2023-06-03","data":46},
{"name":"2023-06-04","data":54},
{"name":"2023-06-05","data":72},
{"name":"2023-06-06","data":68},
{"name":"2023-06-07","data":27},
{"name":"2023-06-08","data":38},
{"name":"2023-06-09","data":34},
{"name":"2023-06-10","data":70},
{"name":"2023-06-11","data":60},
{"name":"2023-06-12","data":37},
{"name":"2023-06-13","data":41},
{"name":"2023-06-14","data":52},
{"name":"2023-06-15","data":46},
{"name":"2023-06-16","data":18},
{"name":"2023-06-18","data":153},
{"name":"2023-06-19","data":252},
{"name":"2023-06-20","data":154},
{"name":"2023-06-21","data":145},
{"name":"2023-06-22","data":281},
{"name":"2023-06-23","data":165},
{"name":"2023-06-24","data":210},
{"name":"2023-06-25","data":337},
{"name":"2023-06-26","data":438},
{"name":"2023-06-27","data":447},
{"name":"2023-06-28","data":505},
{"name":"2023-06-29","data":242},
{"name":"2023-06-30","data":230},
{"name":"2023-07-01","data":166},
{"name":"2023-07-02","data":274},
{"name":"2023-07-03","data":396},
{"name":"2023-07-04","data":457},
{"name":"2023-07-05","data":245},
{"name":"2023-07-06","data":380},
{"name":"2023-07-07","data":271},
{"name":"2023-07-08","data":132},
{"name":"2023-07-09","data":285},
{"name":"2023-07-10","data":221},
{"name":"2023-07-11","data":272},
{"name":"2023-07-12","data":169},
{"name":"2023-07-13","data":15},
{"name":"2023-07-15","data":1053},
{"name":"2023-07-16","data":1236},
{"name":"2023-07-17","data":1919},
{"name":"2023-07-18","data":2245},
{"name":"2023-07-19","data":1129},
{"name":"2023-07-20","data":1469},
{"name":"2023-07-21","data":2438},
{"name":"2023-07-22","data":1551},
{"name":"2023-07-23","data":1126},
{"name":"2023-07-24","data":1747},
{"name":"2023-07-25","data":2320},
{"name":"2023-07-26","data":2012},
{"name":"2023-07-27","data":1974},
{"name":"2023-07-28","data":1436},
{"name":"2023-07-29","data":1020},
{"name":"2023-07-30","data":1677},
{"name":"2023-07-31","data":1006},
{"name":"2023-08-01","data":1720},
{"name":"2023-08-02","data":2135},
{"name":"2023-08-03","data":1629},
{"name":"2023-08-04","data":782},
{"name":"2023-08-05","data":521},
{"name":"2023-08-06","data":973},
{"name":"2023-08-07","data":1548},
{"name":"2023-08-08","data":1031},
{"name":"2023-08-09","data":924},
{"name":"2023-08-10","data":2002},
{"name":"2023-08-11","data":1449},
{"name":"2023-08-12","data":1581},
{"name":"2023-08-13","data":2149},
{"name":"2023-08-14","data":2103},
{"name":"2023-08-15","data":1268},
{"name":"2023-08-16","data":274},
{"name":"2023-08-18","data":3939},
{"name":"2023-08-19","data":6169},
{"name":"2023-08-20","data":7710},
{"name":"2023-08-21","data":8107},
{"name":"2023-08-22","data":4855},
{"name":"2023-08-23","data":2856},
{"name":"2023-08-24","data":3269},
{"name":"2023-08-25","data":4914},
{"name":"2023-08-26","data":4079},
{"name":"2023-08-27","data":6129},
{"name":"2023-08-28","data":7779},
{"name":"2023-08-29","data":6327},
{"name":"2023-08-30","data":4168},
{"name":"2023-08-31","data":4148},
{"name":"2023-09-01","data":3220},
{"name":"2023-09-02","data":5960},
{"name":"2023-09-03","data":5152},
{"name":"2023-09-04","data":3045},
{"name":"2023-09-05","data":6611},
{"name":"2023-09-06","data":8073},
{"name":"2023-09-07","data":6267},
{"name":"2023-09-08","data":4448},
{"name":"2023-09-09","data":4701},
{"name":"2023-09-10","data":3871},
{"name":"2023-09-11","data":6452},
{"name":"2023-09-12","data":8744},
{"name":"2023-09-13","data":6177},
{"name":"2023-09-14","data":5848},
{"name":"2023-09-15","data":6931},
{"name":"2023-09-16","data":4765},
{"name":"2023-09-17","data":4879},
{"name":"2023-09-18","data":5926},
{"name":"2023-09-19","data":7584},
{"name":"2023-09-20","data":8034},
{"name":"2023-09-21","data":7623},
{"name":"2023-09-22","data":7200},
{"name":"2023-09-23","data":5322},
{"name":"2023-09-24","data":5478},
{"name":"2023-09-25","data":4426},
{"name":"2023-09-26","data":4875},
{"name":"2023-09-27","data":3738},
{"name":"2023-09-28","data":4649},
{"name":"2023-09-29","data":5895},
{"name":"2023-09-30","data":2266},
{"name":"2023-10-02","data":7433},
{"name":"2023-10-03","data":11230},
{"name":"2023-10-04","data":9283},
{"name":"2023-10-05","data":11456},
{"name":"2023-10-06","data":10687},
{"name":"2023-10-07","data":11489},
{"name":"2023-10-08","data":9816},
{"name":"2023-10-09","data":11049},
{"name":"2023-10-10","data":12509},
{"name":"2023-10-11","data":10363},
{"name":"2023-10-12","data":11323},
{"name":"2023-10-13","data":11587},
{"name":"2023-10-14","data":9554},
{"name":"2023-10-15","data":8710},
{"name":"2023-10-16","data":10638},
{"name":"2023-10-17","data":12027},
{"name":"2023-10-18","data":11736},
{"name":"2023-10-19","data":10834},
{"name":"2023-10-20","data":9259},
{"name":"2023-10-21","data":10112},
{"name":"2023-10-22","data":11348},
{"name":"2023-10-23","data":10268},
{"name":"2023-10-24","data":9131},
{"name":"2023-10-25","data":8486},
{"name":"2023-10-26","data":8335},
{"name":"2023-10-27","data":8811},
{"name":"2023-10-28","data":10879},
{"name":"2023-10-29","data":10516},
{"name":"2023-10-30","data":10711},
{"name":"2023-10-31","data":12447},
{"name":"2023-11-01","data":11074},
{"name":"2023-11-02","data":11082},
{"name":"2023-11-03","data":13069},
{"name":"2023-11-04","data":11892},
{"name":"2023-11-05","data":12765},
{"name":"2023-11-06","data":13445},
{"name":"2023-11-07","data":10557},
{"name":"2023-11-08","data":9561},
{"name":"2023-11-09","data":9213},
{"name":"2023-11-10","data":11697},
{"name":"2023-11-11","data":12143},
{"name":"2023-11-12","data":10565},
{"name":"2023-11-13","data":10665},
{"name":"2023-11-14","data":9403},
{"name":"2023-11-15","data":10554},
{"name":"2023-11-16","data":9807},
{"name":"2023-11-17","data":9945},
{"name":"2023-11-18","data":12325},
{"name":"2023-11-19","data":10691},
{"name":"2023-11-20","data":9461},
{"name":"2023-11-21","data":9616},
{"name":"2023-11-22","data":11229},
{"name":"2023-11-23","data":12377},
{"name":"2023-11-24","data":11372},
{"name":"2023-11-25","data":10528},
{"name":"2023-11-26","data":9818},
{"name":"2023-11-27","data":10776},
{"name":"2023-11-28","data":10839},
{"name":"2023-11-29","data":11329},
{"name":"2023-11-30","data":12347},
{"name":"2023-12-01","data":13224},
{"name":"2023-12-02","data":13823},
{"name":"2023-12-03","data":12980},
{"name":"2023-12-04","data":11954},
{"name":"2023-12-05","data":13023},
{"name":"2023-12-06","data":11186},
{"name":"2023-12-07","data":10635},
{"name":"2023-12-08","data":10805},
{"name":"2023-12-09","data":9180},
{"name":"2023-12-10","data":11735},
{"name":"2023-12-11","data":10855},
{"name":"2023-12-12","data":8739},
{"name":"2023-12-13","data":10608},
{"name":"2023-12-14","data":11181},
{"name":"2023-12-15","data":10834},
{"name":"2023-12-16","data":11927},
{"name":"2023-12-17","data":13189},
{"name":"2023-12-18","data":11704},
{"name":"2023-12-19","data":8973},
{"name":"2023-12-20","data":8536},
{"name":"2023-12-21","data":9411},
{"name":"2023-12-22","data":10747},
{"name":"2023-12-23","data":9862},
{"name":"2023-12-24","data":9638},
{"name":"2023-12-25","data":3696},
{"name":"2023-12-27","data":8276},
{"name":"2023-12-28","data":14877},
{"name":"2023-12-29","data":14609},
{"name":"2023-12-30","data":14896},
{"name":"2023-12-31","data":15485},
{"name":"2024-01-01","data":13570},
{"name":"2024-01-02","data":14391},
{"name":"2024-01-03","data":13990},
{"name":"2024-01-04","data":12488},
{"name":"2024-01-05","data":12361},
{"name":"2024-01-06","data":14722},
{"name":"2024-01-07","data":13453},
{"name":"2024-01-08","data":13939},
{"name":"2024-01-09","data":13624},
{"name":"2024-01-10","data":13215},
{"name":"2024-01-11","data":13995},
{"name":"2024-01-12","data":14722},
{"name":"2024-01-13","data":13196},
{"name":"2024-01-14","data":12648},
{"name":"2024-01-15","data":14366},
{"name":"2024-01-16","data":5619},
{"name":"2024-01-20","data":10485},
{"name":"2024-01-21","data":16629},
{"name":"2024-01-22","data":16710},
{"name":"2024-01-23","data":16496},
{"name":"2024-01-24","data":16052},
{"name":"2024-01-25","data":15169},
{"name":"2024-01-26","data":15372},
{"name":"2024-01-27","data":15247},
{"name":"2024-01-28","data":16198},
{"name":"2024-01-29","data":15807},
{"name":"2024-01-30","data":15844},
{"name":"2024-01-31","data":15836},
{"name":"2024-02-01","data":16242},
{"name":"2024-02-02","data":15420},
{"name":"2024-02-03","data":15926},
{"name":"2024-02-04","data":16324},
{"name":"2024-02-05","data":16538},
{"name":"2024-02-06","data":15749},
{"name":"2024-02-07","data":16103},
{"name":"2024-02-08","data":16350},
{"name":"2024-02-09","data":15966},
{"name":"2024-02-10","data":15536},
{"name":"2024-02-11","data":15276},
{"name":"2024-02-12","data":15363},
{"name":"2024-02-13","data":16235},
{"name":"2024-02-14","data":6440},
{"name":"2024-02-16","data":8505},
{"name":"2024-02-17","data":14796},
{"name":"2024-02-18","data":14126},
{"name":"2024-02-19","data":14294},
{"name":"2024-02-20","data":14411},
{"name":"2024-02-21","data":14614},
{"name":"2024-02-22","data":14696},
{"name":"2024-02-23","data":15365},
{"name":"2024-02-24","data":15334},
{"name":"2024-02-25","data":15511},
{"name":"2024-02-26","data":15435},
{"name":"2024-02-27","data":14367},
{"name":"2024-02-28","data":14817},
{"name":"2024-02-29","data":14136},
{"name":"2024-03-01","data":14306},
{"name":"2024-03-02","data":15062},
{"name":"2024-03-03","data":14848},
{"name":"2024-03-04","data":13789},
{"name":"2024-03-05","data":13291},
{"name":"2024-03-06","data":14357},
{"name":"2024-03-07","data":13738},
{"name":"2024-03-08","data":14275},
{"name":"2024-03-09","data":13907},
{"name":"2024-03-10","data":13405},
{"name":"2024-03-11","data":14658},
{"name":"2024-03-12","data":15312},
{"name":"2024-03-13","data":15167},
{"name":"2024-03-14","data":14458},
{"name":"2024-03-15","data":13986},
{"name":"2024-03-16","data":14123},
{"name":"2024-03-17","data":13476},
{"name":"2024-03-18","data":13655},
{"name":"2024-03-19","data":14906},
{"name":"2024-03-20","data":15188},
{"name":"2024-03-21","data":14935},
{"name":"2024-03-22","data":14599},
{"name":"2024-03-23","data":15284},
{"name":"2024-03-24","data":15431},
{"name":"2024-03-25","data":14837},
{"name":"2024-03-26","data":14738},
{"name":"2024-03-27","data":15784},
{"name":"2024-03-28","data":15486},
{"name":"2024-03-29","data":15143},
{"name":"2024-03-30","data":14178},
{"name":"2024-03-31","data":14563},]

export const LIST3 = [{"name":"2022-04","data":636},
{"name":"2022-05","data":1947},
{"name":"2022-06","data":1957},
{"name":"2022-07","data":1935},
{"name":"2022-08","data":1902},
{"name":"2022-09","data":1897},
{"name":"2022-10","data":2014},
{"name":"2022-11","data":1757},
{"name":"2022-12","data":1833},
{"name":"2023-01","data":2087},
{"name":"2023-02","data":1809},
{"name":"2023-03","data":2175},
{"name":"2023-04","data":1942},
{"name":"2023-05","data":2015},
{"name":"2023-06","data":4339},
{"name":"2023-07","data":30641},
{"name":"2023-08","data":96538},
{"name":"2023-09","data":168160},
{"name":"2023-10","data":312027},
{"name":"2023-11","data":330145},
{"name":"2023-12","data":336588},
{"name":"2024-01","data":396144},
{"name":"2024-02","data":413875},
{"name":"2024-03","data":450885},]
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import AppInputItem from '../item/AppInputItem';
import AppBooleanItem from '../item/AppBooleanItem';
import HorizontalScroll_001_list_item from './item/HorizontalScroll_001_list_item';
import UiBtnItem from './item/UiBtnItem';

export default function HorizontalScroll_100_input({data,update,index}:any) {
    const [container,setContainer] = useState<any>();
    const [h_title_text,setH_title_text] = useState('');
    const [h_styles,setH_styles] = useState<any>();
    const [h_title_customStyles,setH_title_customStyles] = useState('');
    const [i_customStyle,setI_customStyle] = useState('');
    const [i_styles,setI_styles] = useState<any>();
    const [i_list,setI_list] = useState<any>([]);

    useEffect(()=>{
        if(data === undefined) return;
        setContainer(data?.container);
        setH_title_text(data?.header?.title?.text);
        setH_styles(data?.header?.title?.styles);
        setH_title_customStyles(data?.header?.title?.custom_styles);
        setI_customStyle(data?.item?.custom_styles);
        setI_styles(data?.item?.styles);
        setI_list(data?.item?.list);
    },[data]);

    const containerUpdate = (val:string,type:string) =>{
        const newData = {...container};
        const keys = type.split('.');
        let currentObj = newData;
        for (let i = 0; i < keys.length -1; i++) {
            currentObj = currentObj[keys[i]];
        }
        const targetKey = keys[keys.length - 1];
        currentObj[targetKey] = val;
        setContainer(newData);
    }

    const headerStylesUpdate = (val:string,type:string) =>{
        const newData = {...h_styles};
        newData[type] = val;
        setH_styles(newData);
    }
    
    const itemUpdate = (val:string,type:string) =>{
        const newData = {...i_styles};
        newData[type] = val;
        setI_styles(newData);
    }
    
    const updateEvent = () =>{
        const newData = data;
        
        console.log(newData);
        
        // update(newData,index);
    }

    return (
        <Wrap>
            <ItemWrap>
                <TitleWrap>type</TitleWrap>
                <ContentWrap>
                    {data.type}
                </ContentWrap>
            </ItemWrap>
            <ItemWrap>
                <TitleWrap>container</TitleWrap>
                <ContentWrap>
                    <ContentItem>
                        <ContentItemTitle>custom_styles:</ContentItemTitle>
                        <AppInputItem value={container?.custom_styles ?? ''} valueChange={(val:any)=>containerUpdate(val,'custom_styles')} multi/>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>styles</ContentItemTitle>
                        <ContentItemWrap>
                            <ContentItem>
                                <ContentItemTitle>padding:</ContentItemTitle>
                                <AppInputItem type='number' value={container?.styles?.padding ?? ''} valueChange={(val:any)=>containerUpdate(val,'styles.padding')}/>
                            </ContentItem>
                        </ContentItemWrap>
                    </ContentItem>
                </ContentWrap>
            </ItemWrap>
            <ItemWrap>
                <TitleWrap>header</TitleWrap>
                <ContentWrap>
                    <ContentItem>
                        <ContentItemTitle>title</ContentItemTitle>
                        <ContentItemWrap>
                            <ContentItem>
                                <ContentItemTitle>text:</ContentItemTitle>
                                <AppInputItem value={h_title_text} valueChange={setH_title_text}/>
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>styles</ContentItemTitle>
                                <ContentItemWrap>
                                    <ContentItem>
                                        <ContentItemTitle>bold:</ContentItemTitle>
                                        <AppBooleanItem value={h_styles?.bold ?? 'false'} setValue={(val:any)=>headerStylesUpdate(val,'bold')} />
                                    </ContentItem>
                                    <ContentItem>
                                        <ContentItemTitle>color:</ContentItemTitle>
                                        <AppInputItem value={h_styles?.color ?? 'white'} valueChange={(val:any)=>headerStylesUpdate(val,'color')} />
                                    </ContentItem>
                                    <ContentItem>
                                        <ContentItemTitle>fontSize:</ContentItemTitle>
                                        <AppInputItem type='number' value={h_styles?.fontSize ?? '0'} valueChange={(val:any)=>headerStylesUpdate(val,'fontSize')} />
                                    </ContentItem>
                                </ContentItemWrap>
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>custom_styles:</ContentItemTitle>
                                <AppInputItem value={h_title_customStyles} valueChange={setH_title_customStyles} multi/>
                            </ContentItem>
                        </ContentItemWrap>
                    </ContentItem>
                </ContentWrap>
            </ItemWrap>
            <ItemWrap>
                <TitleWrap>item</TitleWrap>
                <ContentWrap>
                    <ContentItem>
                        <ContentItemTitle>custom_styles:</ContentItemTitle>
                        <AppInputItem value={i_customStyle} valueChange={setI_customStyle} multi/>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>styles</ContentItemTitle>
                        <ContentItemWrap>
                            <ContentItem>
                                <ContentItemTitle>activeOpacity:</ContentItemTitle>
                                <AppInputItem value={i_styles?.activeOpacity ?? '0'} valueChange={(val:any)=>itemUpdate(val,'activeOpacity')} />
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>height:</ContentItemTitle>
                                <AppInputItem value={i_styles?.height ?? '0'} valueChange={(val:any)=>itemUpdate(val,'height')} />
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>padding:</ContentItemTitle>
                                <AppInputItem value={i_styles?.padding ?? '0'} valueChange={(val:any)=>itemUpdate(val,'padding')} />
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>radius:</ContentItemTitle>
                                <AppInputItem value={i_styles?.radius ?? '0'} valueChange={(val:any)=>itemUpdate(val,'radius')} />
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>width:</ContentItemTitle>
                                <AppInputItem value={i_styles?.width ?? '0'} valueChange={(val:any)=>itemUpdate(val,'width')} />
                            </ContentItem>
                        </ContentItemWrap>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>list</ContentItemTitle>
                        <ContentItemWrap>
                            {i_list === undefined || i_list.length === 0 ?
                                <></> : 
                                i_list.map((data:any,index:any)=>{
                                    return(
                                        <HorizontalScroll_001_list_item key={`list-item-${index}`} data={data} />
                                    )
                                })
                            }
                        </ContentItemWrap>
                    </ContentItem>
                </ContentWrap>
            </ItemWrap>
            <UiBtnItem updateEvent={updateEvent} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const ItemWrap = styled(Row)`
    align-items: start;
`;
const TitleWrap = styled.div`
    width: 90px;
    font-weight: 700;
`;
const ContentWrap = styled.div`
    &>div{
        margin-bottom: 4px;
    }
`;

const ContentItem = styled(Row)`
    align-items: start;
    margin-bottom: 4px;
`;
const ContentItemTitle = styled.div`
    margin-right: 4px;
`;

const ContentItemWrap = styled(Row)`
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px;
`;

import React from 'react'
import styled from 'styled-components';
import { MUSIC_CATEGORY_ITEM } from '../../../api/apiMusic';
import TablePart from '../../common/part/TablePart';
import { MUSIC_CATEGORY_TITLE } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import TableContentItem from '../../common/item/TableContentItem';
import {TableRow,Button,Pagination} from '@mui/material';
import { Row } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';
import { USE_TYPE } from '../page/MusicCategoryListPage';

interface musicCategoryListPart{
    list: Array<typeof MUSIC_CATEGORY_ITEM>;
    nowPage: number;
    total: number;
    index: any;
    updateAction: any;
    delAction: any;
}

export default function MusicCategoryListPart({list,nowPage,total,index,updateAction,delAction}:musicCategoryListPart) {
    const navigate = useNavigate();
    // 페이지 이동
    const pageChange = (count:number) =>{
        navigate(`/musicCategoryList/${index}/${count}`);
    }
    
    return (
        <Wrap>
            <TablePart titleArr={MUSIC_CATEGORY_TITLE} minWidth={600}>
                {list.length === 0 || list[0]?.category_id === 0 ? 
                    <TableNoItem colSpan={MUSIC_CATEGORY_TITLE.length}></TableNoItem>:
                    list?.map((data,index)=>{
                        return(
                            <TableRow
                                key={`tableContent-${index}`}
                            >
                                <TableContentItem text={data.category_id}></TableContentItem>
                                <TableContentItem text={data.sound_small_category}></TableContentItem>
                                <TableContentItem text={USE_TYPE[(data.category_use-1)]}></TableContentItem>
                                <TableContentItem text={data.category_title}></TableContentItem>
                                <TableContentItem>
                                    <Button variant='contained' onClick={()=>updateAction(data)}>수정</Button>
                                </TableContentItem>
                                <TableContentItem>
                                    <Button variant='contained' color='error' onClick={()=>delAction(data.category_id)}>삭제</Button>
                                </TableContentItem>
                            </TableRow>
                        )
                    })
                }
            </TablePart>
            <PaginationWrap>
                <Pagination shape="rounded" page={nowPage} count={total} onChange={(e, page) => pageChange(page)}></Pagination>
            </PaginationWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const PaginationWrap = styled(Row)`
    padding: 20px 0;
    justify-content: end;
`;

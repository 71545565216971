import { axiosConfigInstance } from "./config";

// 유튜브 리스트 추가
export const apiSetYoutubeAdd = async(token:string,admin_id:any,link:string,genre:string,type:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/sns',token).post('',{
            method:'adminRegisterYoutube',
            params:{
                "admin_id": admin_id,
                "youtube_url": link,
                "sns_genre": genre,
                "sns_small_category": type,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// story or youtube 리스트 가져오기
export const apiGetStoryList = async(token:string,admin_id:any,page:any,sns_type:string,sns_cate:string,status:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/sns',token).post('',{
            method:'adminGetSnsList',
            params:{
                "admin_id": admin_id,
                "page": page,
                "count": "10",
                "sns_category": sns_type,
                "sns_small_category": sns_cate,
                "sns_status": status,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 유튜브 비디오 숨김 / 해제
/**
 * 
 * @param token 
 * @param admin_id 
 * @param sns_id 
 * @param sns_visible 0 숨김 1활성화
 * @returns 
 */
export const apiSetYoutubeHideShow = async(token:string,admin_id:any,sns_id:any,sns_visible:'0'|'1') =>{
    try {
        const res = await axiosConfigInstance('/admin/sns',token).post('',{
            method: 'adminChangeSnsVisible',
            params: {
                admin_id,sns_id,sns_visible
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 앱 메인 동영상 리스트
export const apiGetVideoList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/artist/support',token).post('',{
            method: 'admGetArtistRecommendVideoList',
            params: {
                admin_id,
                page,
                count
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 앱 메인 동영상 등록 해제  (0: 해제 / 1: 등록)
export const apiSetVideoUpdate = async(token:string,admin_id:any,sns_id:any,is_recommend:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/artist/support',token).post('',{
            method: 'admSetRecommendVideo',
            params: {
                admin_id,
                sns_id,
                is_recommend,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}
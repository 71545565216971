import React, { useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Grid } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import AgencyInputPart from '../part/AgencyInputPart';
import AgencyListPart from '../part/AgencyListPart';
import { AdminWrap } from '../../../style/basic/commonStyle';

export default function AgencyListPage() {
    const pageTitle = '소속사 리스트';
    const [selectItem,setSelectItem] = useState<any>();
    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'소속사 설정'} />
                        <AgencyInputPart selectItem={selectItem} setSelectItem={setSelectItem} />
                    </AdminWrap>
                </Grid>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'소속사 리스트'} />
                        <AgencyListPart setSelectItem={setSelectItem} />
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}


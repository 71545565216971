export const addTheme = {
    media:{
        l:'(max-width: 1270px)',
        m:'(max-width: 992px)',
        s:'(max-width:768px)',
        sx:'(max-width:500px)',
    },
    maxWidth:'1120px',
    padding:'0 15px',
    headerHeight:{
        pc:'72px',
        m:'100px'
    },
    titleFontSize:40,
    mTitleFontSize:28,
    subText:{
        color:'#ababab',
        fontSize:19,
        mFontSize:15,
    },
    color:'#00ff00',
    color_off:'#005d46',
    main: '#0063ff',
}
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import AppInputItem from '../../item/AppInputItem';
import { Row } from '../../../../style/basic/commonStyle';
import {debounce} from 'lodash';

export default function VerticalScroll_001_list_item({data,index,itemUpdate}:any) {
    const [main_text,setMain_text] = useState('');
    const [sub_text,setSub_text] = useState('');
    const [url,setUrl] = useState('');

    useEffect(()=>{
        if(data === undefined) return;
        setMain_text(data.item_main_text);
        setSub_text(data.item_sub_text);
        setUrl(data.item_url);
    },[]);

    // useEffect(()=>{
    //     update()
    // },[main_text,sub_text,url])

    const valueChange = (val:any,setItem:any) =>{
        setItem(val);
        debounceUpdate();
    }

    const debounceUpdate = debounce(()=>{
        const data = {
            item_main_text: main_text,
            item_url: sub_text,
            item_sub_text: url,
        }
        itemUpdate(index,data);
    },500)

    // const update = () =>{
    //     const data = {
    //         item_main_text: main_text,
    //         item_sub_text: sub_text,
    //         item_url: url,
    //     }
    //     itemUpdate(index,data);
    // }
    
    return(
        <ListItemWrap>
            <ContentItem>
                <ContentItemTitle>item_id:</ContentItemTitle>
                <ContentItemTitle>{data?.item_id ?? '0'}</ContentItemTitle>
            </ContentItem>
            <ContentItem>
                <ContentItemTitle>item_main_text:</ContentItemTitle>
                <AppInputItem value={main_text ?? ''} valueChange={(val:any)=>{valueChange(val,setMain_text)}} />
            </ContentItem>
            <ContentItem>
                <ContentItemTitle>item_sub_text:</ContentItemTitle>
                <AppInputItem value={sub_text ?? ''} valueChange={(val:any)=>{valueChange(val,setSub_text)}} />
            </ContentItem>
            <ContentItem>
                <ContentItemTitle>item_url:</ContentItemTitle>
                <AppInputItem value={url ?? ''} valueChange={(val:any)=>{valueChange(val,setUrl)}} />
            </ContentItem>
        </ListItemWrap>
    )
}
const ContentItemWrap = styled(Row)`
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px;
`;

const ListItemWrap = styled(ContentItemWrap)`
    /* border: 1px solid #eee; */
    margin-bottom: 4px;
`;

const ContentItem = styled(Row)`
    align-items: start;
    margin-bottom: 4px;
`;
const ContentItemTitle = styled.div`
    margin-right: 4px;
`;
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { Center } from '../../../style/basic/commonStyle';
import { Button, MenuItem, TextField } from '@mui/material';
import InputItem from '../../common/item/InputItem';
import { fileTypeRes } from '../../../utils/format/string';
import BasicText from '../../basic/BasicText';
import SelectItem from '../../common/item/SelectItem';
import { GAME_TYPE, apiSetGameCreate, apiSetGameUpdate } from '../../../api/apiGame';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

export default function GameUploadPart({selectItem,selectItemReset,getList}:any) {
    const userInfo = useUserInfo();
    const [pageType,setPageType] = useState('생성');
    const [itemId,setItemId] = useState('')
    const [name,setName] = useState('');
    const [desc,setDesc] = useState('');
    const [comment,setComment] = useState('');
    const [link,setLink] = useState('');
    const [score,setScore] = useState('0');
    const [count,setCount] = useState('0');
    const [type,setType] = useState('0');
    const iconRef = useRef<any>(null);
    const thumRef = useRef<any>(null);
    const [icon,setIcon] = useState<any>(null);
    const [thum,setThum] = useState<any>(null);
    const [previewIcon,setPreviewIcon] = useState('');
    const [previewThum,setPreviewThum] = useState('');

    useEffect(()=>{
        if(selectItem === undefined){
            setPageType('생성')
        }else{
            setPageType('수정');
        }
        stateValue();
    },[selectItem])

    const stateValue = () =>{
        setItemId(selectItem?.game_id ?? '');
        setName(selectItem?.game_name ?? '');
        setDesc(selectItem?.game_description ?? '');
        setComment(selectItem?.game_comment ?? '');
        setLink(selectItem?.game_url ?? '');
        setScore(selectItem?.game_reward_score ?? '0');
        setCount(selectItem?.game_oneday_count ?? '0');
        setType(selectItem?.game_is_multi ?? '0');
        setIcon(null);
        setThum(null);
        setPreviewIcon(selectItem?.game_icon_url ?? '');
        setPreviewThum(selectItem?.game_background_url ?? '');
        iconRef.current.value = null;
        thumRef.current.value = null;
    }

    // 파일선택 - 아이콘
    const fileSelectIcon = (e:any) =>{
        const file = e.target.files[0];
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'png' || fileType === 'jpg'){
            const preImg = URL.createObjectURL(file);
            setIcon(file);
            setPreviewIcon(preImg);
        }else{
            iconRef.current.value = null;
            setIcon(null);
            setPreviewIcon('');
            alert('png 또는 jpg 파일을 선택해주세요.');
        }
    }
    // 파일선택 - 썸네일
    const fileSelectThum = (e:any) =>{
        const file = e.target.files[0];
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'png' || fileType === 'jpg'){
            const preImg = URL.createObjectURL(file);
            setThum(file);
            setPreviewThum(preImg);
        }else{
            iconRef.current.value = null;
            setThum(null);
            setPreviewThum('');
            alert('png 또는 jpg 파일을 선택해주세요.');
        }
    }

    const stateChk = () =>{
        if(name === ''){
            alert('이름을 입력해주세요.');
            return false;
        }
        if(desc === ''){
            alert('설명을 입력해주세요.');
            return false;
        }
        if(comment === ''){
            alert('코멘트를 입력해주세요.');
            return false;
        }
        if(link === ''){
            alert('링크를 입력해주세요.');
            return false;
        }
        if(score === ''){
            alert('보상 스코어를 입력해주세요.');
            return false;
        }
        if(count === ''){
            alert('보상수를 입력해주세요.');
            return false;
        }
        return true;
    }

    const btnAction = async() => {
        if(window.confirm(`${pageType} 하시겠습니까?`)){
            const chk = stateChk();
            if(chk){
                let visible = '1';
                if(previewIcon === '' || previewThum === ''){
                    visible = '0';
                }
                if(pageType === '생성'){
                    const res = await apiSetGameCreate(userInfo.jwt_token,userInfo.admin_id,name,desc,comment,link,score,count,type,visible,icon,thum)
                    if(res.result){
                        alert('게임이 생성되었습니다.');
                        stateValue();
                        getList();
                        return;
                    }else{
                        alert(res.error);
                        return;
                    }
                }
                if(pageType === '수정'){
                    const res = await apiSetGameUpdate(userInfo.jwt_token,userInfo.admin_id,itemId,name,desc,comment,link,score,count,type,visible,icon,thum);
                    if(res.result){
                        alert('게임이 수정되었습니다.');
                        selectItemReset();
                        getList();
                        return;
                    }else{
                        alert(res.error);
                        return;
                    }
                }
            }
        }
    }

    const cancelBtn = () =>{
        if(window.confirm('취소 하시겠습니까?')){
            selectItemReset();
        }
    }

    return (
        <Wrap>
            <TableWrapTitleItem title={'게임 등록'} />
            <InputWrap>
                <BasicText marginBottom={1}>타입</BasicText>
                <SelectItem value={type} setValue={setType}>
                    {GAME_TYPE.map((data,index)=>{
                        return(
                            <MenuItem key={`game-type-${index}`} value={index}>{data}</MenuItem>
                        )
                    })}
                </SelectItem>
            </InputWrap>
            <InputWrap>
                <InputItem label='이름' inputLabel='none' value={name} valueChange={setName}/>
            </InputWrap>
            <InputWrap>
                <BasicText marginBottom={1}>내용</BasicText>
                <TextField
                    id="outlined-multiline-static"
                    label=""
                    multiline
                    rows={4}
                    value={desc}
                    fullWidth
                    onChange={e=>setDesc(e.target.value)}
                />
            </InputWrap>
            <InputWrap>
                <InputItem label='코멘트' inputLabel='none' value={comment} valueChange={setComment}/>
            </InputWrap>
            <InputWrap>
                <InputItem label='링크' inputLabel='none' value={link} valueChange={setLink}/>
            </InputWrap>
            <InputWrap>
                <InputItem label='보상 스코어' inputLabel='none' value={score} valueChange={setScore} type='number' />
            </InputWrap>
            <InputWrap>
                <InputItem label='보상수' inputLabel='none' value={count} valueChange={setCount} type='number' />
            </InputWrap>
            <InputWrap>
                <InputItem label={'아이콘'} inputLabel={'none'} type='file' inputProps={{accept:'image/*'}} fileRef={iconRef} valueChange={fileSelectIcon} />
                {previewIcon !== '' &&
                    <PreviewImgWrap>
                        <img alt='preview-img' src={previewIcon} />
                    </PreviewImgWrap>
                }
            </InputWrap>
            <InputWrap>
                <InputItem label={'썸네일'} inputLabel={'none'} type='file' inputProps={{accept:'image/*'}} fileRef={thumRef} valueChange={fileSelectThum} />
                {previewThum !== '' &&
                    <PreviewImgWrap>
                        <img alt='preview-img' src={previewThum} />
                    </PreviewImgWrap>
                }
            </InputWrap>
            <BtnWrap>
                <Button variant='contained' onClick={btnAction}>{pageType}</Button>
                {pageType === '수정' &&
                    <Button variant='contained' onClick={cancelBtn} color='error'>취소</Button>
                }
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const InputWrap = styled.div`
    margin-top: 10px;
    &>div>div{
        width: 100%;
    }
`;

const PreviewImgWrap = styled.div`
    margin-top: 15px;
    & img{
        max-width: 250px;
    }
`;

const BtnWrap = styled(Center)`
    margin-top: 30px;
    & button{
        margin: 0 10px;
    }
`;
import React, { useState,useEffect } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Button, Grid, TableRow } from '@mui/material'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import { useParams } from 'react-router'
import { MAIN_USER_TITLE } from '../../../data/tableTitle'
import TableContentItem from '../../common/item/TableContentItem'
import ListPart from '../../common/part/ListPart'
import TableNoItem from '../../common/item/TableNoItem'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import { apiGetMainUserList, apiSetMainUser } from '../../../api/apiUser'
import BannerAppMainUserListPart from '../part/BannerAppMainUserListPart'
import { AdminWrap } from '../../../style/basic/commonStyle'

export default function BannerAppMainUserPage() {
    const userInfo = useUserInfo();
    const pageTitle = '앱 메인 아티스트';
    const {page} = useParams();
    const nowPage = Number(page) ?? 1;

    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const [newListState,setNewListState] = useState(false);

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,nowPage])

    const getList = async() =>{
        const res = await apiGetMainUserList(userInfo.jwt_token,userInfo.admin_id,page,10);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    const delAction = async(user_id:any) =>{
        if(window.confirm('메인에서 해제하시겠습니까?')){
            const res = await apiSetMainUser(userInfo.jwt_token,userInfo.admin_id,user_id,'0');
            if(res.result){
                alert('해제되었습니다.');
                getList();
                setNewListState(true);
            }
        }
    }

    return (
        <>
            <Grid container={true} spacing={2}>
                <Grid item md={5} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'유저 리스트'} />
                        <BannerAppMainUserListPart mainUser={getList} newListState={newListState} setNewListState={setNewListState} />
                    </AdminWrap>
                </Grid>
                <Grid item md={7} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'앱 메인 아티스트 리스트'}></TableWrapTitleItem>
                        <ListPart titleArr={MAIN_USER_TITLE} minWidth={950} nowPage={nowPage} total={total} link={'bannerAppMain'}>
                            {list === undefined || list.length === 0 ?
                                <TableNoItem colSpan={MAIN_USER_TITLE.length}></TableNoItem> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <TableRow key={`tableContent-${index}`}>
                                            <TableContentItem text={data.user_id} />
                                            <TableContentItem>
                                                <img alt='user-profile' src={data.user_profile}></img>
                                            </TableContentItem>
                                            <TableContentItem text={data.user_nickname} />
                                            <TableContentItem text={data.profile_introduce} />
                                            <TableContentItem text={JSON.stringify(data.profile_act_part)} />
                                            <TableContentItem text={JSON.stringify(data.profile_act_genre)} />
                                            {/* <TableContentItem text={JSON.stringify(data.profile_act_channels)} /> */}
                                            <TableContentItem text={data.profile_reg_timestamp} type='time' />
                                            <TableContentItem>
                                                <Button variant='contained' onClick={()=>delAction(data.user_id)} color='error'>메인 해제</Button>
                                            </TableContentItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

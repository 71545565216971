import MonitorIcon from '@mui/icons-material/Monitor';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedIcon from '@mui/icons-material/Feed';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import CakeIcon from '@mui/icons-material/Cake';

const CHART = [
    {id: '1', name:'유저', router:'/statUser',routerName:'stat'},
    // {id: '2', name:'음악', router:'/statMusic',routerName:'stat'},
]

const USER = [
    {id: '1', name:'회원 정보 리스트', router:'/userList/1/',routerName:'userList'},
    // {id: '2', name:'회원 포인트 리스트', router:'/userPoint/1',routerName:'userPoint'},
    {id: '3', name:'회원 초대', router:'/userInvite/1',routerName:'userInvite'},
]

const MUSIC = [
    {id: '1', name:'음악 리스트', router:'/musicList/0/1/',routerName:'musicList'},
    {id: '2', name:'플레이 리스트', router:'/musicPlayList/1',routerName:'musicPlayList'},
    {id: '2-1', name:'플레이 리스트 생성', router:'/musicPlayListUpdate/0',routerName:'musicPlayListUpdate', show: false},
    {id: '3', name:'음악 카테고리', router:'/musicCategoryList/0/1',routerName:'musicCategoryList'},
    {id: '4', name:'유튜브 플레이리스트', router:'/youPlaylist/1',routerName:'youPlaylist'},
    {id: '4-1', name:'유튜브 플레이리스트 생성', router:'/youPlaylistUpdate/0',routerName:'youPlaylistUpdate', show: false}
]

const BANNER = [
    // {id:'1',name:'홈페이지 비디오', router:'/homeVideo',routerName:'banner'},
    // {id:'2',name:'홈페이지 하단 배너', router:'/homeBanner/1',routerName:'banner'},
    // {id:'3',name:'홈페이지 시즌', router:'/homeSeason',routerName:'banner'},
    // {id:'9',name:'홈페이지 카운트',router:'/homeCount',routerName:'banner'},
    // {id:'7',name:'파트너',router:'/partner',routerName:'banner'},
    // {id:'8',name:'홈페이지 플레이리스트',router:'/homePlayList/1',routerName:'banner'},
    // {id:'6',name:'홈페이지 하단',router:'/homeFooter',routerName:'banner'},
    {id:'10',name:'앱 배너',router:'/bannerApp/1',routerName:'bannerApp/'},
    {id:'11',name:'앱 메인 아티스트',router:'/bannerAppMain/1',routerName:'bannerAppMain'},
    {id:'13',name:'앱 메인 동영상',router:'/bannerAppVideo/1',routerName:'bannerAppVideo'},
    {id:'12',name:'웹 배너',router:'/bannerWeb/1',routerName:'bannerWeb'},
]

const SETTING = [
    {id:'1',name:'앱 버전',router:'/appVersion',routerName:'appVersion'},
    {id:'2',name:'앱 푸시알림',router:'/appPush/1',routerName:'appPush/'},
    {id:'3',name:'앱 푸시알림(자동)',router:'/appPushAuto/1',routerName:'appPushAuto'},
    {id:'4',name:'약관',router:'/terms/0/1',routerName:'terms'},
]

const SNS = [
    {id:'1',name:'Story 리스트',router:'/story/1',routerName:'story/'},
    // {id:'2',name:'Story 신고 리스트',router:'/storyReport/1',routerName:'storyReport'},
    {id:'3',name:'youtube 리스트',router:'/storyYoutube/0/1',routerName:'storyYoutube'},
    //x {id:'4',name:'youtube 신청 리스트',router:'/storyListing/1',routerName:'storyListing'},
    // {id:'5',name:'후원 리스트',router:'/storyDonate/1',routerName:'storyDonate'},
    // {id:'6',name:'소속사 리스트',router:'/agencyList',routerName:'agencyList'},
    // {id:'7',name:'가수 리스트',router:'/singerList',routerName:'singerList'}
]

const ADMIN = [
    {id:1,name:'관리자 접속 내역',router:'/adminAccess/1',routerName:'adminAccess'},
    {id:2,name:'관리자 리워드 설정',router:'/adminReward',routerName:'adminReward'}
]

const APP = [
    {id:1, name:'메인1', router:'/appMain', routerName:'appMain'},
]

const GAME = [
    {id:1, name:'게임 리스트', router:'/gameList/1'},
    {id:2, name:'게임 로그 리스트', router:'/gameLogList/1/0/1'},
]

const REWARD = [
    {id:1, name:'리워드 설정', router: '/rewardSetting', routerName:'rewardSetting'},
    {id:2, name:'리워드 지급', router: '/rewardSend', routerName:'rewardSend'},
]

export const TAB_MENU = [
    { id: '10', name: '통계', icon: <MonitorIcon sx={{color:'#fff'}}/>, router: '',sub:CHART, routerName:'stat' },
    { id: '1', name: '회원', icon: <AccountBoxIcon sx={{color:'#fff'}}/>, router: '',sub:USER, routerName:'user' },
    { id: '2', name: '음악', icon: <AudiotrackIcon sx={{color:'#fff'}}/>, router: '',sub:MUSIC, routerName:'music' },
    { id: '3', name: '배너', icon: <HomeIcon sx={{color:'#fff'}}/>, router: '',sub:BANNER, routerName:'banner' },
    { id: '5', name: 'Story', icon: <FeedIcon sx={{color:'#fff'}}/>, router: '',sub:SNS, routerName:'story' },
    { id: '4', name: '설정', icon: <SettingsIcon sx={{color:'#fff'}}/>, router: '',sub:SETTING, routerName:'setting' },
    { id: '6', name: '관리자', icon: <ManageAccountsIcon sx={{color:'#fff'}}/>, router: '',sub: ADMIN, routerName:'admin'},
    { id: '7', name: '앱 UI', icon: <PhonelinkSetupIcon sx={{color:'#fff'}}/>,router: '',sub: APP, routerName:'app'},
    // { id: '8', name: '게임', icon: <SportsEsportsIcon sx={{color:'#fff'}}/>,router: '/',sub: GAME},
    { id: '9', name: '리워드', icon: <CakeIcon sx={{color:'#fff'}}/>,router: '',sub: REWARD, routerName:'reward'},
];
import React from 'react'
import styled from 'styled-components'
import { RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import TitleRightBtnItem from '../item/TitleRightBtnItem';

export default function HorizontalScroll_100({data}:any) {
    const {header, container, item} = data;

    return (
        <Wrap styles={container?.styles && container?.styles} custom_styles={container?.custom_styles ? container?.custom_styles : ''}>
            <Header>
                <BasicText theme={header?.title?.styles?.color} fontSize={header?.title?.styles?.fontSize} bold={header?.title?.styles?.bold} custom_styles={header?.title?.custom_styles ?? ''}>{header?.title?.text}</BasicText>
                {header?.right_btn && 
                    <TitleRightBtnItem name={header?.right_btn?.icon_name} theme={header?.right_btn?.styles?.color} size={header?.right_btn?.styles?.size} />
                }
            </Header>
            <ListWrap>
                {item && 
                    item.list.map((data: any, index: any) => {
                        return (
                            <Item key={`horizontal-item-${index}`} styles={item?.styles && item?.styles}  custom_styles={item?.custom_styles ? item?.custom_styles : ''}>
                                <ImgWrap width={item?.styles?.width ?? 0} height={item?.styles?.height ?? 0} radius={item?.styles?.radius} >
                                    <img alt={`list-item-${index}`} src={data?.item_url} />
                                </ImgWrap>
                            </Item>
                        )
                    })
                }
            </ListWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{styles?: any, custom_styles?: any}>`
    padding: ${props => props?.styles?.padding ?? 0}px;
    ${props => props.custom_styles};
`;

const Header = styled(RowBetween)`

`;

const ListWrap = styled.div`
    display: flex;
    overflow-x:auto;
`;

const Item = styled.div<{styles: any, custom_styles?: any}>`
    border-radius: ${props => props?.styles?.radius ?? 0}px;
    ${props => props.custom_styles};
`; 

const ImgWrap = styled.div<{width:number,height:number,radius?:any}>`
    width: ${props=>props.width}px;
    height: ${props=>props.height}px;
    border-radius: ${props=>props.radius ?? 0}px;
    overflow: hidden;
    &>img{
        width: 100%;
        height: 100%;
    }
`;
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function UserBtnPart({open,setOpen,selectItem,blockEvent,getUserUrl,userMainUpdate}:any) {
    const close = () =>{
        setOpen(false);
    }

    const blockClick = () =>{
        if(blockEvent){
            blockEvent();
        }
        close();
    }

    const connectionClick = () =>{
        if(getUserUrl){
            getUserUrl();
        }
    }

    const userMainClick = () =>{
        if(userMainUpdate){
            userMainUpdate();
        }
    }

    return (
        <Wrap>
            <Dialog
                open={open} 
                onClose={close}
                sx={{
                    '.MuiDialog-container':{alignItems:'center'},
                    '.MuiPaper-root':{margin:'25px', maxWidth:'420px',width:'100%',borderRadius: '6px'}
                }}
            >
                <DialogTitle>
                    <BasicText fontSize={18}>관리</BasicText>
                </DialogTitle>
                <DialogContent>
                    <BtnWrap>
                        <Button 
                            sx={{margin:'0 2px'}} 
                            onClick={blockClick} 
                            color={selectItem?.user_block === '0' ? 'error' : 'info'}
                            variant='contained' 
                        >
                            {selectItem?.user_block === '0' ? '차단' : '해제'}
                        </Button>
                        <Button variant='contained' onClick={connectionClick}>연동</Button>
                        <Button variant='contained' onClick={userMainClick} color={selectItem?.is_main_artist === '0' ? 'info' : 'error'}>
                            {selectItem?.is_main_artist === '0' ? '메인 등록' : '메인 해제'}
                        </Button>
                    </BtnWrap>
                </DialogContent>
            </Dialog>
        </Wrap>
    )
}

const Wrap = styled.div``;
const BtnWrap = styled(Center)`
    gap: 20px;
`;
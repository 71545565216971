import styled from '@emotion/styled'
import React from 'react'
import { Row,RowBetween } from '../../../style/basic/commonStyle'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import BasicText from '../../basic/BasicText';



export default function TableWrapTitleItem({title,children,size}:any) {
    return (
        <Wrap>
            <TitleWrap>
                <PanoramaFishEyeIcon sx={{width:14,height:14}}></PanoramaFishEyeIcon>
                <TitleItemWrap fontSize={size ?? 24}>{title}</TitleItemWrap>
            </TitleWrap>
            {children}
        </Wrap>
    )
}

const Wrap = styled(RowBetween)`
    margin-bottom: 15px;
    flex-wrap: wrap;
    min-height: 42px;
`;

const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    &>p{
        flex-wrap: wrap;
    }  
`;

const TitleItemWrap = styled.div<{fontSize:number}>`
    font-size: ${({fontSize}) => fontSize}px;
`;
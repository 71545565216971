import styled from '@emotion/styled'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'
import BasicText from '../../basic/BasicText';
import { Center } from '../../../style/basic/commonStyle';

export default function YoutubeBtnPart({open,setOpen,selectItem,hideAction,recoverAction,approvalAction, mainBtnEvent}:any) {
    const close = () =>{
        setOpen(false);
    }
    const mainBtnClick = () =>{
        if(mainBtnEvent){
            mainBtnEvent();
        }
    }

    const hideBtnClick = () =>{
        if(hideAction){
            hideAction(selectItem);
        }
    }

    const recoverBtnClick = () =>{
        if(recoverAction){
            recoverAction(selectItem);
        }
    }

    const approvalBtnClick = () =>{
        if(approvalAction){
            approvalAction(selectItem);
        }
    }
    return (
        <Wrap>
            <Dialog
                open={open} 
                onClose={close}
                sx={{
                    '.MuiDialog-container':{alignItems:'center'},
                    '.MuiPaper-root':{margin:'25px', maxWidth:'420px',width:'100%',borderRadius: '6px'}
                }}
            >
                <DialogTitle>
                    <BasicText fontSize={18}>관리</BasicText>
                </DialogTitle>
                <DialogContent>
                    <BtnWrap>
                        {selectItem?.sns_visible === '0' &&
                            <Button variant='contained' color='success' onClick={recoverBtnClick} >복원</Button>
                        }
                        {selectItem?.sns_visible === '1' &&
                            <Button variant='contained' color='error' onClick={hideBtnClick}>숨김</Button>
                        }
                        {selectItem?.sns_visible === '3' && 
                            <Button variant='contained' onClick={approvalBtnClick}>승인</Button>
                        }
                        {selectItem?.sns_visible !== '0' &&
                            <Button variant='contained' onClick={mainBtnClick} color={selectItem?.is_recommend === '0' ? 'info' : 'error'}>
                                {selectItem?.is_recommend === '0' ? '메인 등록' : '메인 해제'}
                            </Button>
                        }
                    </BtnWrap>
                </DialogContent>
            </Dialog>
        </Wrap>
    )
}

const Wrap = styled.div``;
const BtnWrap = styled(Center)`
    gap: 20px;
`;

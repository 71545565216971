import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import LeftNavBar from '../../common/part/LeftNavBar';
import { Box, Button, Grid, TableRow } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import InputItem from '../../common/item/InputItem';
import { AdminWrap, Row, TextItem } from '../../../style/basic/commonStyle';
import { numberWithComma } from '../../../utils/format/number';
import ListPart from '../../common/part/ListPart';
import { USER_INVITE_TITLE } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import TableContentItem from '../../common/item/TableContentItem';
import { apiGetInviteList } from '../../../api/apiUser';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

export default function UserInvitePage() {
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const pageTitle = '유저 초대';
    const {page} = useParams();
    let nowPage = Number(page) ?? 1;
    
    const [totalPoint,setTotalPoint] = useState('100');

    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [search,setSearch] = useState('');

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo,nowPage,search]);

    // 초대리스트
    const getList = async() =>{
        const res = await apiGetInviteList(userInfo.jwt_token,userInfo.admin_id,page,10);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 검색
    const listSearch = (data:any) =>{
        navigate(`/userInvite/1`);
        setSearch(data.search);
    }

    return (
        <>
            <Grid container={true} spacing={2}>
                {/* <Grid item md={6} sm={12}>
                    <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2}}>
                        <TableWrapTitleItem title='총 지급한 포인트' />
                        <TextItem fontSize={26}>{numberWithComma(totalPoint)}</TextItem>
                    </Box>
                </Grid> */}
                <Grid item md={12} sm={12} sx={{width:'100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title='초대한 유저 리스트' />
                        <ListPart titleArr={USER_INVITE_TITLE} minWidth={850} nowPage={nowPage} total={total} link={'userInvite'} search setRes={listSearch}>
                            {list === undefined || list.length === 0 ? 
                                <TableNoItem colSpan={USER_INVITE_TITLE.length} /> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <TableRow key={`tableContent-${index}`}>
                                            <TableContentItem text={data.user_id} />
                                            <TableContentItem text={data.user_timestamp} type='time' />
                                            <TableContentItem text={data.user_parent_code} />
                                            <TableContentItem text={`${data.user_code} / ${data.user_nickname}`} />
                                            <TableContentItem text={data.reward_amount} />
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

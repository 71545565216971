import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Pagination from '@mui/material/Pagination';
import { Row } from '../../../style/basic/commonStyle';
import styled from '@emotion/styled';
import TableTitleItem from '../item/TableTitleItem';

interface tablePartType {
    minWidth? : number;
    titleArr?: Array<{name?:string,size?:number}>
    children?:any;
    totalPage?:number;
    setNowPage?: any;
    nowPage?:number;
    noCount?:boolean;
    titleChildren?: any;
}

export default function TablePart({minWidth,titleArr,children,titleChildren,totalPage,setNowPage,nowPage,noCount}:tablePartType) {
    
    return (
        <div>
            <TableContainer>
                <Table sx={{ minWidth: minWidth,border:'1px solid #ddd' }} aria-label="simple table">
                    <TableHead>
                        {titleChildren ?
                            titleChildren :
                            <TableTitleItem titleArr={titleArr}></TableTitleItem>
                        }
                    </TableHead>
                    <TableBody>
                        {children}
                    </TableBody>
                </Table>
                {noCount &&
                    totalPage !== undefined &&
                        <PaginationWrap>
                            <Pagination shape="rounded" page={nowPage} count={totalPage} onChange={(e, page) => setNowPage(page)}></Pagination>
                        </PaginationWrap>
                }
            </TableContainer>
        </div>
    );
}

const PaginationWrap = styled(Row)`
    padding: 20px 0;
    justify-content: end;
`;

const SearchWrap = styled.div`
    display: flex;
    justify-content: end;
`;
import React, { useState,useEffect } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import { TabPanelPart } from '../../common/part/TabPanelPart'
import StatUserListPart from '../part/StatUserListPart'
import LineChartPart from '../part/LineChartPart'
import { apiGetStatUserLoginLog, apiGetStatUserSignLog, apiGetStatUserTotal } from '../../../api/apiStat'
import { momentFormat } from '../../../utils/format/time'
import BasicText from '../../basic/BasicText'
import { numberWithComma } from '../../../utils/format/number'
import PieChartPart from '../part/PieChartPart'
import StatUserSignListPart from '../part/StatUserSignListPart'

export default function StatMusicPage() {
    const userInfo = useUserInfo();
    const pageTitle = '뮤직';
    const [tab,setTab] = useState(0);
    const [statLogin,setStatLogin] = useState<any>([]);
    const [statSign,setStatSign] = useState<any>([]);
    const [totalLogin,setTotalLogin] = useState(0);
    const [totalSign,setTotalSign] = useState(0);
    const [page1,setPage1] = useState(1);
    const [page2,setPage2] = useState(1);

    useEffect(()=>{
        if(userInfo.admin_id === 0) return;
        getChartLogin();
        getChartSign();
        getTotal();
    },[userInfo.admin_id])

    const getChartLogin = async() =>{
        const res = await apiGetStatUserLoginLog(userInfo.jwt_token,userInfo.admin_id,30,'1day');
        const res2 = await apiGetStatUserSignLog(userInfo.jwt_token,userInfo.admin_id,30,'1day');
        if(res.result){
            const newList = res.data.map((data:any)=>({...data,name:momentFormat('MM-DD',data.timestamp)}));
            setStatLogin(newList.reverse());
        }
    }

    const getChartSign = async() =>{
        const res = await apiGetStatUserSignLog(userInfo.jwt_token,userInfo.admin_id,30,'1day');
        if(res.result){
            const newList = res.data.map((data:any)=>({...data,name:momentFormat('MM-DD',data.timestamp)}));
            setStatSign(newList.reverse());
        }
    }

    const getTotal = async() =>{
        const res1 = await apiGetStatUserTotal(userInfo.jwt_token,userInfo.admin_id,'login_count');
        if(res1.result){
            setTotalLogin(res1.data.count);
            
        }
        const res2 = await apiGetStatUserTotal(userInfo.jwt_token,userInfo.admin_id,'user');
        if(res1.result){
            setTotalSign(res2.data.count);
        }
    }

    return (
        <Grid container={true} spacing={3}>
            {/* <Grid item md={12}>
                <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2}}>
                    <PieChartPart list={[{name:'t',count:300,},{name:'tt',count:900},{name:'ttt',count:1200}]}/>
                </Box>
            </Grid> */}
            <Grid item md={6}>
                <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2}}>
                    {/* <LineChartPart title='유저 접속자 수' titleCount={<BasicText fontSize={18}>총 접속자 수: {numberWithComma(totalLogin)}</BasicText>} labelList={['접속자','가입자']} lineList={['count','val']} colorList={['#ff6b00','#0063ff']} list={statLogin}/>
                    <LineChartPart title='유저 가입자 수' titleCount={<BasicText fontSize={18}>총 가입자 수: {numberWithComma(totalSign)}</BasicText>} labelList={['가입자']} lineList={['count']} colorList={['#0063ff']} list={statSign} /> */}
                </Box>
            </Grid>
            <Grid item md={6}>
                <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2}}>
                    <TableWrapTitleItem title={'리스트'}></TableWrapTitleItem>
                    {/* <Tabs value={tab} onChange={(e:any,val:any)=>setTab(val)}>
                        <Tab label={'접속자 로그'} />
                        <Tab label={'가입자 로그'} />
                    </Tabs>
                    <TabPanelPart value={tab} index={0}>
                        <></>
                    </TabPanelPart>
                    <TabPanelPart value={tab} index={1}>
                        <></>
                    </TabPanelPart> */}
                </Box>
            </Grid>
        </Grid>
    )
}

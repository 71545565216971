// 유저리스트 테이블 타이틀
export const USER_TITLE = [
    {name:'#',size:7},
    {name:'프로필',size:9},
    {name:'닉네임/유저코드',size:9},
    {name:'포인트',size:7},
    {name:'최근접속일',size:9},
    {name:'가입일',size:9},
    {name:'업로드한 음원수',size:7},
    {name:'지갑주소',size:10},
    {name:'국가',size:7},
    {name:'디바이스',size:8},
    {name:'리퍼럴코드',size:8},
    {name:'관리',size:8},
]

// 회원 포인트 리스트
export const POINT_LIST_TITLE = [
    {name:'#',size:20},
    {name:'유저',size:20},
    {name:'포인트',size:20},
    {name:'사용처',size:20},
    {name:'사용일',size:20},
]

// 회원 초대 리스트
export const USER_INVITE_TITLE = [
    {name:'#',size:20},
    {name:'지급일',size:20},
    {name:'유저코드',size:20},
    {name:'초대된 유저코드/닉네임',size:20},
    {name:'지급 포인트',size:20},
]

// 음악리스트 테이블 타이틀
export const MUSIC_LIST_TITLE = [
    {name:'#',size:6},
    {name:'아티스트/제목',size:12},
    {name:'장르',size:8},
    {name:'타입',size:8},
    {name:'재생시간',size:7},
    {name:'등록일',size:10},
    {name:'재생수',size:9},
    {name:'음원',size:22},
    {name:'상태',size:8},
    {name:'',size:9},
]

// 플레이 리스트 테이블 타이틀
export const PLAY_LIST_TITLE = [
    {name:'#',size:12},
    {name:'이미지',size:18},
    {name:'제목',size:18},
    {name:'곡수',size:12},
    {name:'작성자',size:16},
    {name:'수정',size:12},
    {name:'활성/비활성',size:12},
]

// 플레이 리스트 음악 검색 리스트
export const SEARCH_MUSIC_TITLE = [
    {name:'이미지',size:20},
    {name:'제목',size:20},
    {name:'음원',size:45},
    // {name:'장르',size:15},
    {name:'',size:15},
]
// 유튜브 플레이리스트 음악 검색 리스트
export const SEARCH_YOU_MUSIC_TITLE = [
    {name:'이미지',size:30},
    {name:'제목',size:30},
    {name:'장르',size:20},
    {name:'',size:20},
]

// 유튜브 음악 리스트
export const YOU_LIST_TITLE = [
    {name:'#',size:9},
    {name:'아티스트',size:14},
    {name:'제목',size:14},
    {name:'장르',size:13},
    {name:'음원',size:35},
    {name:'',size:15},
]

// 유튜브 플레이리스트 
export const YOU_PLAYLIST_TITLE =[
    {name:'#',size:8},
    {name:'제목',size:13},
    {name:'장르',size:13},
    {name:'음원',size:25},
    {name:'',size:13},
    {name:'',size:13},
    {name:'',size:15},
]

// 음악 카테고리 리스트
export const MUSIC_CATEGORY_TITLE = [
    {name:'#',size:16},
    {name:'코드',size:16},
    {name:'사용처',size:16},
    {name:'타이틀',size:18},
    {name:'수정',size:17},
    {name:'삭제',size:17},
]

// 앱 푸시알림 리스트
export const APP_PUSH_TITLE =[
    // {name:'#',size:10},
    {name:'보낸 사람',size:11},
    {name:'대상',size:11},
    {name:'제목',size:14},
    {name:'내용',size:19},
    {name:'데이터',size:14},
    {name:'보낸인원',size:11},
    {name:'날짜',size:20},
]

export const APP_PUSH_BASIC_TITLE = [
    {name:'제목',size:20},
    {name:'내용',size:20},
    {name:'데이터',size:20},
    {name:'이미지URL',size:20},
    {name:'',size:20},
];

export const APP_PUSH_AUTO_TITLE = [
    {name:'타입',size:12},
    {name:'제목',size:17},
    {name:'내용',size:18},
    {name:'데이터',size:19},
    {name:'이미지URL',size:20},
    {name:'',size:14},
    // {name:'',size:9},
]

// 홈페이지
// 하단배너
export const BOTTOM_BANNER_TITLE= [
    {name:'#',size:10},
    {name:'이미지',size:14},
    {name:'타이틀(kr)',size:20},
    {name:'타이틀(en)',size:20},
    {name:'링크',size:16},
    {name:'수정',size:10},
    {name:'삭제',size:10},
]

// 홈페이지 시즌
export const HOME_SEASON_LIST_TITLE= [
    {name:'#',size:10},
    {name:'타이틀',size:25},
    {name:'등록일',size:25},
    {name:'수정',size:20},
    {name:'삭제',size:20},
]


// 공지사항 리스트 테이블 타이틀
export const NOTICE_LIST_TITLE = [
    {name:'#',size:10},
    {name:'타입',size:15},
    {name:'이미지',size:15},
    {name:'제목',size:20},
    {name:'작성일',size:20},
    {name:'',size:10},
    {name:'',size:10},
]

// 파트너
export const PARTNER_TITLE = [
    {name:'#',size:15},
    {name:'이미지',size:15},
    {name:'링크',size:50},
    {name:'',size:10},
    {name:'',size:10},
]

// 홈 플레이리스트
export const HOME_PLAYLIST_TITLE = [
    {name:'#',size:15},
    {name:'이미지',size:15},
    {name:'정보',size:50},
    {name:'',size:10},
    {name:'',size:10},
]

// 홈페이지 하단
export const SNS_TITLE = [
    {name:'#',size:15},
    {name:'이미지',size:15},
    {name:'링크',size:46},
    {name:'',size:12},
    {name:'',size:12},
]

// 음악듣기 배너
export const MUSIC_BANNER_TITLE= [
    {name:'#',size:7},
    {name:'위치',size:8},
    {name:'타이틀',size:13},
    {name:'내용',size:14},
    {name:'이미지pc',size:14},
    {name:'이미지mo',size:14},
    {name:'이벤트',size:14},
    {name:'수정',size:8},
    {name:'삭제',size:8},
]

// 스토리 리스트
export const STORY_LIST_TITLE = [
    {name:'#',size:10},
    {name:'유저코드',size:10},
    {name:'타입',size:10},
    {name:'타이틀',size:15},
    {name:'내용',size:20},
    {name:'등록일',size:15},
    {name:'좋아요',size:10},
    {name:'',size:10},
]

// 스토리 유튜브 리스트
export const STORY_YOUTUBE_LIST_TITLE = [
    {name:'#',size:7},
    {name:'타입',size:8},
    {name:'장르',size:8},
    {name:'썸네일',size:9},
    {name:'제목',size:13},
    {name:'내용',size:13},
    {name:'등록일',size:13},
    {name:'좋아요',size:8},
    {name:'댓글',size:8},
    {name:'후원',size:8},
    {name:'상태',size:7},
    {name:'',size:7},
]

// 스토리 신고 리스트
export const STORY_REPORT_LIST_TITLE = [
    {name:'#',size:8},
    {name:'신고자',size:18},
    {name:'대상',size:18},
    {name:'타입',size:9},
    {name:'신고타입',size:12},
    {name:'신고일',size:15},
    {name:'',size:10},
    {name:'',size:10},
]

// 리스팅 신청 리스트
export const LISTING_LIST_TITLE = [
    {name:'#',size:10},
    {name:'신청자',size:20},
    {name:'타이틀',size:20},
    {name:'신청일',size:20},
    {name:'',size:10},
    {name:'',size:10},
    {name:'',size:10},
]

// 후원리스트
export const DONATE_LIST_TITLE = [
    {name:'#', size:10},
    {name:'닉네임', size:15},
    {name:'유저 코드', size:15},
    {name:'타이틀', size:20},
    {name:'후원', size:15},
    {name:'후원일', size:15},
]

// 관리자 접속 내역
export const ADM_ACCESS_TITLE = [
    {name:'이메일', size: 14},
    {name:'이름', size: 10},
    {name:'레벨', size: 10},
    {name:'국가', size: 10},
    {name:'ip', size: 14},
    {name:'os/브라우저',size:14},
    {name:'생성일', size: 14},
    {name:'접속일', size: 14},
]

// 게임 리스트
export const GAME_TITLE = [
    {name:'#', size: 8},
    {name:'이미지', size: 12},
    {name:'이름', size: 10},
    {name:'설명', size: 10},
    {name:'타입', size: 9},
    {name:'보상수', size: 9},
    {name:'보상스코어', size: 9},
    {name:'링크', size: 11},
    {name:'', size: 11},
    {name:'', size: 11},
]

// 게임 로그 페이지의 게임 리스트
export const GAME_LOG_TITLE = [
    {name:'#',size:14},
    {name:'아이콘',size:23},
    {name:'이름',size:21},
    {name:'내용',size:21},
    {name:'',size:21},
]

// 게임 로드 리스트
export const GAME_LOG = [
    {name:'프로필',size:20},
    {name:'네임',size:20},
    {name:'스코어',size:18},
    {name:'보상',size:18},
    {name:'날짜',size:24},
]

// 이용약관 리스트
export const TERMS_TITLE = [
    {name:'#',size:20},
    {name:'제목',size:20},
    {name:'내용',size:30},
    {name:'날짜',size:20},
    {name:'',size:10},
]

// 앱 메인 아티스트
export const MAIN_USER_TITLE = [
    {name:'#',size:10},
    {name:'프로필',size:13},
    {name:'닉네임',size:10},
    {name:'소개',size:15},
    {name:'분야',size:13},
    {name:'장르',size:13},
    {name:'생성일',size:16},
    {name:'',size:10},
    // {name:'#',size:8},
    // {name:'프로필',size:11},
    // {name:'닉네임',size:9},
    // {name:'소개',size:10},
    // {name:'분야',size:9},
    // {name:'장르',size:9},
    // {name:'링크',size:18},
    // {name:'생성일',size:16},
    // {name:'',size:10},
]

// 앱 메인 동영상
export const APP_MAIN_VIDEO = [
    {name:'#', size:16,},
    {name:'이미지', size:21,},
    {name:'타이틀', size:21,},
    {name:'내용', size:21,},
    {name:'', size:21,},
]

// 리워드 설정 리스트
export const REWARD_SETTING = [
    {name: '#', size: 10,},
    {name: '코드', size: 16,},
    {name: '네임', size: 16,},
    {name: '수량', size: 13,},
    {name: '지급 방법', size: 14,},
    {name: '박스 코드', size: 14,},
    {name: '', size: 17,},
]

// 리워드 유저 리스트
export const REWARD_USER = [
    {name: '#', size: 16,},
    {name: '프로필', size: 21,},
    {name: '닉네임', size: 21,},
    {name: '유저코드', size: 21,},
    {name: '', size: 21,},
]

// 소속사 리스트
export const AGENCY_LIST = [
    {name: '#', size: 10,},
    {name: '소속사명', size: 19,},
    {name: '코드', size: 19,},
    {name: '채널명', size: 18,},
    {name: '타입', size: 14,},
    {name: '', size: 20,},
]

// 소속사의 가수 리스트
export const SINGER_LIST = [
    {name: '#', size: 10,},
    {name: '가수명', size: 14,},
    {name: '채널명', size: 18,},
    {name: '코드', size: 19,},
    {name: '소속사명', size: 19,},
    {name: '', size: 20,},
]

// 통계

// 접속자
export const STAT_USER = [
    {name: '유저코드', size: 40},
    {name: '디바이스', size: 20},
    {name: '날짜', size: 40},
]
// 가입자
export const STAT_USER_REGISTER = [
    {name: '디바이스', size: 30},
    {name: '나라', size: 30},
    {name: '날짜', size: 40},
]
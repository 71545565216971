import { axiosConfigInstance } from "./config";

// 게임 타입
export const GAME_TYPE = ['싱글','멀티'];

// 활성/비활성
export const GAME_VISIBLE = ['비활성','활성'];

// 게임 리스트
export const apiGetGameList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/game',token).post('',{
            method:'adminGetGamelist',
            params:{
                admin_id,
                page,
                count,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 게임 활성/비활성
export const apiSetGameOnOff = async(token:string,admin_id:any,game_id:any,game_visible:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/game',token).post('',{
            method:'adminDeleteGame',
            params:{
                admin_id,
                game_id,
                game_visible,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 게임 생성
export const apiSetGameCreate = async(token:string,admin_id:any,game_name:string,game_description:string,game_comment:any,game_url:any,game_reward_score:any,game_oneday_count:any,game_is_multi:any,game_visible:any,game_icon:any,game_thumbnail:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','adminUploadGame');
        formData.append('params', JSON.stringify({
            admin_id,
            game_name,
            game_description,
            game_comment,
            game_url,
            game_reward_score,
            game_oneday_count,
            game_is_multi,
            game_visible,
        }));
        formData.append('game_icon',game_icon);
        formData.append('game_thumbnail',game_thumbnail);
        const res = await axiosConfigInstance(`/admin/game`,token,type).post('',formData);
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 게임 수정
export const apiSetGameUpdate = async(token:string,admin_id:any,game_id:any,game_name:string,game_description:string,game_comment:any,game_url:any,game_reward_score:any,game_oneday_count:any,game_is_multi:any,game_visible:any,game_icon:any,game_thumbnail:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','adminModifyGame');
        formData.append('params', JSON.stringify({
            admin_id,
            game_id,
            game_name,
            game_description,
            game_comment,
            game_url,
            game_reward_score,
            game_oneday_count,
            game_is_multi,
            game_visible,
        }));
        formData.append('game_icon',game_icon);
        formData.append('game_thumbnail',game_thumbnail);
        const res = await axiosConfigInstance(`/admin/game`,token,type).post('',formData);
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 게임 로그 리스트 
export const apiGetGameLogList = async(token:string,admin_id:any,game_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/game',token).post('',{
            method:'adminGetGameLogList',
            params:{
                admin_id,
                game_id,
                page,
                count,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

import React from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import styled from 'styled-components';

interface pieChartPartType {
    data:Array<{id:number,value:number,label:string}>
    palette?: Array<string>;
}

export default function PieChartPart({data,palette}:pieChartPartType) {
    return (
        <Wrap>
            <PieChart
                series={[
                    {
                        data: data,
                    },
                ]}
                sx={{width:'100%'}}
                height={200}
                {...palette}
            ></PieChart>
        </Wrap>
    )
}

const Wrap = styled.div`
    
`;
import React,{useEffect,useState} from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import {Grid, Box, Tabs, Tab} from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { useNavigate, useParams } from 'react-router';
import { TabPanelPart, a11yProps } from '../../common/part/TabPanelPart';
import styled from 'styled-components';
import YoutubeRegPart from '../part/YoutubeRegPart';
import YoutubeListPart from '../part/YoutubeListPart';
import { apiGetStoryList } from '../../../api/apiStory';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { getMusicCateList } from '../../../api/apiMusic';
import { AdminWrap } from '../../../style/basic/commonStyle';

export default function YoutubeListPage() {
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const pageTitle = 'Story youtube 리스트';
    const {page,tab} = useParams();
    let nowPage = Number(page) ?? 1;
    const value = Number(tab) ?? 0;

    const [total,setTotal] = useState(1);
    const [list,setList] = useState<any>([]);
    const [genreList,setGenreList] = useState<any>([]);

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getGenreList();
    },[userInfo.jwt_token])

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[nowPage,value,userInfo.jwt_token]);

    // 리스트
    const getList = async() =>{
        let state = 'all';
        if(value === 0){
            state = 'all';
        }
        if(value === 1){
            state = '3';
        }
        if(value === 2){
            state = '1'
        }
        if(value === 3){
            state = '0'
        }
        const res = await apiGetStoryList(userInfo.jwt_token,userInfo.admin_id,nowPage,"2","0",state);
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 장르 리스트
    const getGenreList = async() =>{
        const res = await getMusicCateList(userInfo.jwt_token,userInfo.admin_id,'1',1,100);
        if(res.result){
            const newList = res.data.filter((data:any)=> data.category_use === '1' || data.category_use === '3');
            setGenreList(newList);
        }else{
            setGenreList([]);
        }
    }

    // 탭이동 이동
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        // setValue(newValue);
        navigate(`/storyYoutube/${newValue}/1`);
    };

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <YoutubeRegPart getList={getList} genreList={genreList} />
                    </AdminWrap>
                </Grid>
                <Grid item sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={pageTitle} />
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="전체" {...a11yProps(0)} />
                            <Tab label="대기" {...a11yProps(1)} />
                            <Tab label="승인" {...a11yProps(2)} />
                            <Tab label="숨김" {...a11yProps(3)} />
                        </Tabs>
                        <TabContentWrap>
                            <TabPanelPart value={value} index={0}>
                                <YoutubeListPart list={list} index={0} nowPage={nowPage} total={total} genreList={genreList} getList={getList} />
                            </TabPanelPart>
                            <TabPanelPart value={value} index={1}>
                                <YoutubeListPart list={list} index={1} nowPage={nowPage} total={total} genreList={genreList} getList={getList} />
                            </TabPanelPart>
                            <TabPanelPart value={value} index={2}>
                                <YoutubeListPart list={list} index={2} nowPage={nowPage} total={total} genreList={genreList} getList={getList} />
                            </TabPanelPart>
                            <TabPanelPart value={value} index={3}>
                                <YoutubeListPart list={list} index={3} nowPage={nowPage} total={total} genreList={genreList} getList={getList} />
                            </TabPanelPart>
                        </TabContentWrap>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

const TabContentWrap = styled.div`
    margin-top: 10px;
`;
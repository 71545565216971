import { axiosConfigInstance } from "./config"

// // 관리자 계정 리스트
// export const apiAdminList = async(page:number,count:number,token?:string) =>{
//     try {
//         const res = await axiosConfigInstance('',token).post('',{
//             method:'list_Account',
//             params:{
//                 page:page,
//                 count:count,
//             }
//         })
//         return res.data;
//     } catch (error) {
//         return{
//             result:false,
//             error,
//         }
//     }
// }

// // 관리자 생성
// export const apiAdminCreate = async(email:string,name:string,pw:string,pwChk:string,level:string,token?:string) =>{
//     try {
//         const res = await axiosConfigInstance('',token).post('',{
//             method:'create_Account',
//             params:{
//                 admin_email:email,
//                 admin_name:name,
//                 admin_password:pw,
//                 admin_password_confirm:pwChk,
//                 admin_level:level,
//             }
//         })
//         return res.data;
//     } catch (error) {
//         return{
//             result:false,
//             error,
//         }
//     }
// }

// // 관리자 계정 정보
// export const apiAdminInfo = async(id:number,token?:string) =>{
//     try {
//         const res = await axiosConfigInstance('',token).post('',{
//             method:'select_Account',
//             params:{
//                 admin_id: id
//             }
//         })
//         return res.data;
//     } catch (error) {
//         return{
//             result:false,
//             error
//         }
//     }
// }

// // 관리자 계정 수정
// export const apiAdminUpdate = async(id:number,name:string,level:string,token?:string) =>{
//     try {
//         const res = await axiosConfigInstance('',token).post('',{
//             method:'update_Account',
//             params:{
//                 admin_id:id,
//                 admin_name:name,
//                 admin_level:level,
//             }
//         })
//         return res.data;
//     } catch (error) {
//         return{
//             result:false,
//             error
//         }
//     }
// }

// // 관리자 계정 삭제
// export const apiAdminDel = async(id:number,token?:string) =>{
//     try {
//         const res = await axiosConfigInstance('',token).post('',{
//             method:'delete_Account',
//             params:{
//                 admin_id: id
//             }
//         })
//         return res.data;
//     } catch (error) {
//         return{
//             result:false,
//             error
//         }
//     }
// }



// 관리자 로그 리스트
export const apiGetAdminLog = async(token:string,admin_id:any,page:number,count:number) =>{
    try {
        const res = await axiosConfigInstance('/admin/log',token).post('',{
            method:'admGetLoginLogList',
            params:{
                admin_id,
                page:page,
                count:count,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 관리자 리워드 리스트
export const apiGetRewardList = async(token:string,admin_id:any,page:any,count:any) => {
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminUtilGetRewardList',
            params:{
                admin_id,
                page,
                count,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 관리자 리워드 설정
export const apiSetReward = async(token:string,admin_id:any,reward_code:any,reward_process:any,reward_amount:any) => {
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminUtilRewardControl',
            params:{
                admin_id,
                reward_code,
                reward_process,
                reward_amount,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}
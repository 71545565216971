import React from 'react'
import styled from 'styled-components'
import { Row } from '../../../../style/basic/commonStyle';
import { Button } from '@mui/material';

export default function UiBtnItem({updateEvent}:any) {
    const clickEvent = () =>{
        console.log('no action');
    }
    return (
        <Wrap>
            <Button variant='contained' onClick={updateEvent ?? clickEvent}>수정</Button>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    justify-content: end;
`;
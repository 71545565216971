import React from 'react'
import styled from 'styled-components'
import { Row, TextItem } from '../../../style/basic/commonStyle'
import SelectItem from '../../common/item/SelectItem';
import { DEVICE, MEMBER } from '../../../api/apiUser';
import { MenuItem } from '@mui/material';

export default function UserListFilterPart({target_membership,setTarget_membership,target_device,setTarget_device}:any) {
    return (
        <Wrap>
            <Row style={{width:'auto'}}>
                <TextItem>가입여부</TextItem>
                <SelectItem value={target_membership} setValue={setTarget_membership} margin='0 0 0 4px'>
                    <MenuItem value="">all</MenuItem>
                    {MEMBER.map((data,index)=>{
                        return(
                            <MenuItem key={`filter-member-${index}`} value={index}>{data}</MenuItem>
                        )
                    })}
                </SelectItem>
            </Row>
            <Row style={{width:'auto'}}>
                <TextItem>디바이스</TextItem>
                <SelectItem value={target_device} setValue={setTarget_device} margin='0 0 0 4px'>
                    <MenuItem value="">all</MenuItem>
                    {DEVICE.map((data,index)=>{
                        return(
                            <MenuItem key={`filter-device-${index}`} value={index+1}>{data}</MenuItem>
                        )
                    })}
                </SelectItem>
            </Row>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    margin-right: 15px;
    justify-content: end;
    &>div:nth-of-type(1){
        margin-right: 20px;
    }
`;
import React, { useState,useEffect } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Button, Grid, TableRow } from '@mui/material'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import { useParams } from 'react-router'
import { MUSIC_BANNER_TITLE } from '../../../data/tableTitle'
import TableContentItem from '../../common/item/TableContentItem'
import { apiGetMusicBanner, apiSetBannerUpdate, apiSetMusicBannerCreate, apiSetMusicBannerOnOff } from '../../../api/apiHomepage'
import ListPart from '../../common/part/ListPart'
import TableNoItem from '../../common/item/TableNoItem'
import BannerInputPart from '../part/BannerInputPart'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import { AdminWrap } from '../../../style/basic/commonStyle'

const VISIBLE_TYPE = ['복원','삭제'];
const BANNER_TYPE = ['앱','웹'];
const BANNER_POSITION = ['메인 상단','메인 하단','아티스트 프로필'];

export default function BannerWebPage() {
    const userInfo = useUserInfo();
    const pageTitle = '웹 배너';
    const {page} = useParams();
    const nowPage = Number(page) ?? 1;

    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);

    const [selectItem, setSelectItem] = useState();

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,nowPage])

    const getList = async() =>{
        const res = await apiGetMusicBanner(userInfo.jwt_token,userInfo.admin_id,page,10,3);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 리스트 삭제
    const delAction = async(id:number,type:any) =>{
        const TYPE = [...VISIBLE_TYPE].reverse();
        if(window.confirm(`${TYPE[type]} 하시겠습니까?`)){
            const res = await apiSetMusicBannerOnOff(userInfo.jwt_token,userInfo.admin_id,id,type);
            if(res.result){
                alert(`${TYPE[type]} 되었습니다.`);
                await getList();
               
            }else{
                alert(res.error);
            }
        }
    }

    // 업로드
    const uploadAction = async(title:any,filePc:any,fileMo:any,content:any,name:any,action:any,navigator:any,screen:any,tab:any,webview_url:any,posType:number) =>{
        const res = await apiSetMusicBannerCreate(userInfo.jwt_token,userInfo.admin_id,title,filePc,fileMo,3,content,name,action,navigator,screen,tab,webview_url,posType);
        return res;
    }

    // 실제 수정
    const updateAction = async(id:any,title:any,filePc:any,fileMo:any,content:any,name:any,action:any,navigator:any,screen:any,tab:any,webview_url:any,posType:number) =>{
        const res = await apiSetBannerUpdate(userInfo.jwt_token,userInfo.admin_id,id,title,filePc,fileMo,3,content,name,action,navigator,screen,tab,webview_url,posType)
        return res;
    }

    // 수정
    const updateBtn = (item:any) =>{
        setSelectItem(item);
    }

    // 수정 취소
    const selectItemReset = () =>{
        setSelectItem(undefined);
    }

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={5} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'웹 배너 입력'} />
                        <BannerInputPart selectItem={selectItem} selectItemReset={selectItemReset} getList={getList} type={1} uploadAction={uploadAction} updateAction={updateAction}/>
                    </AdminWrap>
                </Grid>
                <Grid item md={7} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'웹 배너 리스트'}></TableWrapTitleItem>
                        <ListPart titleArr={MUSIC_BANNER_TITLE} minWidth={1350} nowPage={nowPage} total={total} link={'bannerWeb'}>
                            {list === undefined || list.length === 0 ?
                                <TableNoItem colSpan={MUSIC_BANNER_TITLE.length}></TableNoItem> :
                                list.map((data:any,index:any)=>{
                                    const visible = data.banner_visible == '0' ? '1' : '0';
                                    const visibleStr = VISIBLE_TYPE[Number(data.banner_visible)];
                                    const pos = BANNER_POSITION[Number(data.banner_use)-1];
                                    return(
                                        <TableRow key={`tableContent-${index}`}>
                                            <TableContentItem text={data.banner_id} />
                                            <TableContentItem text={pos} />
                                            <TableContentItem text={data.banner_title} />
                                            <TableContentItem text={data.banner_content} />
                                            <TableContentItem>
                                                <img alt='pc-banner-img' src={data.banner_web_img}></img>
                                            </TableContentItem>
                                            <TableContentItem>
                                                <img alt='mo-banner-img' src={data.banner_mo_img}></img>
                                            </TableContentItem>
                                            <TableContentItem text={data.event?.webview_url} />
                                            <TableContentItem>
                                                <Button variant='contained' onClick={()=>updateBtn(data)}>수정</Button>
                                            </TableContentItem>
                                            <TableContentItem>
                                                <Button variant='contained' color={visible == '0' ? 'error' : 'success'} onClick={()=>delAction(data.banner_id,visible)}>{visibleStr}</Button>
                                            </TableContentItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import InputItem from '../../common/item/InputItem';
import BasicText from '../../basic/BasicText';
import SelectItem from '../../common/item/SelectItem';
import { Button, MenuItem } from '@mui/material';

export default function SingerInputPart({selectItem,setSelectItem,agencyList}:any) {
    const [agency,setAgency] = useState(0);
    const [code,setCode] = useState('');
    const [chName,setChName] = useState('');
    const [name,setName] = useState('');

    useEffect(()=>{
        if(selectItem === undefined){
            reset();
        }else{
            // setCode(selectItem.code);
            // setChName(selectItem.ch_name);
            // setName(selectItem.name);
            // setAgency(selectItem.type-1);
        }
    },[selectItem])

    const reset = () =>{
        // setCode('');
        // setChName('');
        // setName('');
        // setAgency(0);
    }

    // 취소
    const cancel = () =>{
        setSelectItem(undefined);
    }

    // 수정
    const update = async() =>{

    }

    return (
        <Wrap>
            <InputWrap>
                <InputItemWrap>
                    <BasicText marginBottom={1}>소속사</BasicText>
                    <SelectItem value={agency} setValue={setAgency}>
                        {agencyList === undefined || agencyList === undefined ?
                            <MenuItem value="">none</MenuItem> :
                            agencyList.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`type-${index}`} value={index}>{data.name}</MenuItem>
                                )
                            })
                        }
                    </SelectItem>
                </InputItemWrap>
                <InputItem label='채널 코드' inputLabel='none' value={code} valueChange={setCode}/>
                <InputItem label='채널명' inputLabel='none' value={chName} valueChange={setChName}/>
                <InputItem label='가수명' inputLabel='none' value={name} valueChange={setName}/>
            </InputWrap>
            <BtnWrap>
                {selectItem === undefined ?
                    <Button variant='contained'>생성</Button> :
                    <>
                        <Button variant='contained' >수정</Button>
                        <Button variant='contained' color='error' onClick={cancel}>취소</Button>
                    </>
                }
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InputWrap = styled(Row)`
    width: auto;
    align-items: stretch;
    gap: 10px;
    flex-direction: column;
`;

const InputItemWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
`;

const BtnWrap = styled(Center)`
    margin-top: 20px;
    gap: 10px;
`;

import { TableCell, Tooltip } from '@mui/material'
import { momentFormat } from '../../../utils/format/time';
import React, { useRef, useState } from 'react'
import styled from '@emotion/styled';

interface tableContentType{
    text?:string | number;
    children?:any;
    type?: 'time'|string;
    clickEvent?:any;
    file?:boolean;
    id?:number;
    delChk?:boolean;
    padding?:string;
}

export default function TableContentItem({text,children,type,clickEvent,file,id,delChk,padding}:tableContentType) {
    let data = type !== 'time' ? text : momentFormat('yyyy-MM-DD HH:mm',Number(text));
    const fileRef = useRef<any>(null);
    
    const cellClick = () =>{
        if(clickEvent === undefined) return;
        if(file){
            fileRef.current.click();
            return;
        }
        clickEvent();
    }

    const imgSelect = (e:any) =>{
        const img = e.target.files[0];
        if(img === undefined) return;
        const fileImg = (URL.createObjectURL(img));
        clickEvent(fileImg,img,id);
    }

    return (
        <Wrap onClick={cellClick} component="th" scope="row" align='center' cursorstate={(clickEvent === undefined).toString()}>
            {children !== undefined ? 
                children 
                :   <Tooltip title={data} arrow={true}>
                        <Text delChk={delChk}>{data}</Text>
                    </Tooltip>
            }
            {file ?
                <>
                    <FileInput ref={fileRef} type={'file'} accept='image/*' onChange={imgSelect}></FileInput> 
                </>
                : <></>
            }
        </Wrap>
    )
}

const Wrap = styled(TableCell)<{cursorstate?:any}>`
    overflow:hidden;
    cursor: ${props=>props.cursorstate === 'true' ? 'default' :'pointer'};
    & img{
        height:70px;
        max-width:100%
    }
    & a:hover{
        text-decoration: underline;
    }
    & audio{
        width: 100%;
    }
    
`;

const Text = styled.div<{delChk?:boolean}>`
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: ${props=>props.delChk === undefined ? 'none' : props.delChk ? 'line-through' : 'none'};
    word-break: break-all;
`;

const FileInput = styled.input`
    display: none;
    width: 0;
    height: 0;
`;
import React from 'react'
import TableRow from '@mui/material/TableRow';
import { TableCell } from '@mui/material';

export default function TableTitleItem({titleArr}:any) {
    return (
        <TableRow sx={{bgcolor:'#5157ff'}}>
            {titleArr?.map((data:any,index:any)=>{
                return(
                    <TableCell key={`title-${index}`} sx={{width:`${data.size}%`,color:'#fff'}} align={'center'}>{data.name}</TableCell>
                )
            })}
        </TableRow>
    )
}

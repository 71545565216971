import React from 'react'
import styled from 'styled-components'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { addTheme } from '../../../style/addTheme';

export default function SelectItem({value,setValue,children,margin,sx,noneState,wrapStyle}:any) {

    const handleChange = (e:SelectChangeEvent) =>{
        setValue(e.target.value);
    }
    
    return (
        <Wrap margin={margin} wrapStyle={wrapStyle}>
            <Select
                value={value}
                onChange={handleChange}
                displayEmpty
                size='small'
                sx={{
                    '.MuiSelect-select':{color:'#000',border:'1px solid #000'},
                    ...sx,
                }}
                fullWidth

            >
                {noneState &&
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                }
                {children === undefined || children.length === 0 ? 
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>:
                    children
                }
            </Select>
        </Wrap>
    )
}

const Wrap = styled.div<{margin?:string,wrapStyle?:any}>`
    & svg{
        color: #000;
    }
    margin: ${props => props.margin ?? 0};
    ${({wrapStyle}) => wrapStyle ?? ''}
    /* max-width: 280px;
    @media ${addTheme.media.m}{
        max-width: 100%;
    } */
`;

import React, { useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Grid } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import RewardSettingListPart from '../part/RewardSettingListPart';
import RewardSettingPart from '../part/RewardSettingPart';
import { AdminWrap } from '../../../style/basic/commonStyle';

export default function RewardSettingPage() {
    const pageTitle = '리워드 설정';
    const [selectItem,setSelectItem] = useState<any>();
    const [newList,setNewList] = useState(false);

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={4} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={pageTitle} />
                        <RewardSettingPart selectItem={selectItem} setSelectItem={setSelectItem} setNewList={setNewList} />
                    </AdminWrap>
                </Grid>
                <Grid item md={8} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={pageTitle+' 리스트'} />
                        <RewardSettingListPart setSelectItem={setSelectItem} newList={newList} />
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

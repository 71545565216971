import { BrowserRouter, Route, Routes,Navigate } from 'react-router-dom';
import MainPage from '../components/home/page/MainPage';
import { useUserInfo } from '../utils/custom-hooks/common/useUserInfo';

import LoginPage from '../components/sign/page/LoginPage';
// 유저
import UserListPage from '../components/user/page/UserListPage';
import UserPointPage from '../components/user/page/UserPointPage';

// 음악
import MusicListPage from '../components/music/page/MusicListPage';
import MusicPlayListPage from '../components/music/page/MusicPlayListPage';
import MusicPlayListUpdatePage from '../components/music/page/MusicPlayListUpdatePage';
import MusicCategoryListPage from '../components/music/page/MusicCategoryListPage';
import MusicYouPlaylistPage from '../components/music/page/MusicYouPlaylistPage';

// 홈페이지
import NoticeListPage from '../components/homepage/page/NoticeListPage';
import NoticeUpdatePage from '../components/homepage/page/NoticeUpdatePage';

import HomeBannerPage from '../components/homepage/page/HomeBannerPage';
import HomeSeasonPage from '../components/homepage/page/HomeSeasonPage';
import HomeVideoPage from '../components/homepage/page/HomeVideoPage';
import HomeFooterPage from '../components/homepage/page/HomeFooterPage';
import HomePartnerPage from '../components/homepage/page/HomePartnerPage';
import HomePlayListPage from '../components/homepage/page/HomePlayListPage';
import HomeCountPage from '../components/homepage/page/HomeCountPage';

// 설정
import AppVersionPage from '../components/setting/page/AppVersionPage';
import AppPushPage from '../components/setting/page/AppPushPage';
import TermsPage from '../components/homepage/page/TermsPage';

// story
import StoryListPage from '../components/story/page/StoryListPage';
import StoryReportListPage from '../components/story/page/StoryReportListPage';
import YoutubeListPage from '../components/story/page/YoutubeListPage';

import StoryDonatePage from '../components/story/page/StoryDonatePage';
import AdminAccessListPage from '../components/admin/page/AdminAccessListPage';
import MusicYouPlayListUpdatePage from '../components/music/page/MusicYouPlayListUpdatePage';
import AppMainPage from '../components/appUi/page/AppMainPage';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { thunkTheme } from '../store/common/theme';
import UserInvitePage from '../components/user/page/UserInvitePage';
import HomeMusicBannerPage from '../components/homepage/page/HomeMusicBannerPage';
import AdminRewardPage from '../components/admin/page/AdminRewardPage';
import GameListPage from '../components/game/page/GameListPage';
import GameLogListPage from '../components/game/page/GameLogListPage';
import AppPushAutoPage from '../components/setting/page/AppPushAutoPage';

// 배너
import BannerAppPage from '../components/banner/page/BannerAppPage';
import BannerWebPage from '../components/banner/page/BannerWebPage';
import BannerAppMainUserPage from '../components/banner/page/BannerAppMainUserPage';
import BannerAppVideoPage from '../components/banner/page/BannerAppVideoPage';
import RewardSettingPage from '../components/reward/page/RewardSettingPage';
import RewardSendPage from '../components/reward/page/RewardSendPage';
import AgencyListPage from '../components/story/page/AgencyListPage';
import SingerListPage from '../components/story/page/SingerListPage';
import StatUserPage from '../components/statistics/page/StatUserPage';
import StatMusicPage from '../components/statistics/page/StatMusicPage';
import LeftNavBar from '../components/common/part/LeftNavBar';

export default function Router(){
    const dispatch = useDispatch();
    const user = useUserInfo();

    useEffect(()=>{
        dispatch(thunkTheme('dark'));
    },[])

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route path="/login" element={<LoginPage/>} />
                <Route path="/" element={user.admin_id === 0 ? <Navigate to='/login' replace/> : <LeftNavBar/>}>
                    <Route path='' element={<MainPage />} />
                    {user.admin_id !== 0 &&
                        <>
                            {/* 통계 */}
                            <Route path='statUser' element={<StatUserPage />} />
                            <Route path='statMusic' element={<StatMusicPage />} />

                            {/* 유저 */}
                            <Route path='userList/:page/:sc?' element={<UserListPage />} />
                            <Route path='userPoint/:page' element={<UserPointPage />} />
                            <Route path='userInvite/:page' element={<UserInvitePage />} />

                            {/* 음악 */}
                            <Route path='musicList/:tab/:page/:sc?' element={<MusicListPage />}/>
                            <Route path='musicPlayList/:page' element={<MusicPlayListPage />}/>
                            <Route path='musicPlayListUpdate/:id' element={<MusicPlayListUpdatePage />}/>
                            <Route path='musicCategoryList/:type/:page' element={<MusicCategoryListPage />}/>
                            <Route path='youPlaylist/:page' element={<MusicYouPlaylistPage />}/>
                            <Route path='youPlaylistUpdate/:id' element={<MusicYouPlayListUpdatePage />}/>

                            {/* 배너 */}
                            <Route path='bannerApp/:page' element={<BannerAppPage />}/>
                            <Route path='bannerAppMain/:page' element={<BannerAppMainUserPage />}/>
                            <Route path='bannerAppVideo/:page' element={<BannerAppVideoPage />}/>
                            <Route path='bannerWeb/:page' element={<BannerWebPage />}/>
                            {/* <Route path='/homeBanner/:page' element={< />}/> */}

                            {/* 홈페이지 */}
                            {/* <Route path='/homeVideo' element={<HomeVideoPage />}/>
                            <Route path='/homeSeason' element={<HomeSeasonPage />}/> */}
                            {/* <Route path='/noticeList/:page' element={<NoticeListPage />}/> */}
                            {/* <Route path='/noticeUpdate/:id' element={<NoticeUpdatePage />}/> */}
                            {/* <Route path='/homePlayList/:page' element={<HomePlayListPage />}/>
                            <Route path='/partner' element={<HomePartnerPage />}/> */}
                            
                            {/* <Route path='/homeFooter' element={<HomeFooterPage />}/>
                            <Route path='/homeCount' element={<HomeCountPage />}/>
                            <Route path='/musicBanner/:page' element={<HomeMusicBannerPage />} /> */}

                            {/* story */}
                            <Route path='story/:page' element={<StoryListPage/>}/>
                            <Route path='storyReport/:page' element={<StoryReportListPage/>}/>
                            <Route path='storyYoutube/:tab/:page' element={<YoutubeListPage/>}/>
                            <Route path='storyDonate/:page' element={<StoryDonatePage/>}/>
                            <Route path='agencyList' element={<AgencyListPage />} />
                            <Route path='singerList' element={<SingerListPage />} />

                            {/* 설정 */}
                            <Route path='appVersion' element={<AppVersionPage />}/>
                            <Route path='appPush/:page' element={<AppPushPage />}/>
                            <Route path='appPushAuto/:page' element={<AppPushAutoPage />}/>
                            <Route path='terms/:tab/:page' element={<TermsPage />}/>

                            {/* 관리자 */}
                            <Route path='adminAccess/:page' element={<AdminAccessListPage />}/>
                            <Route path='adminReward' element={<AdminRewardPage />} />

                            {/* 앱 UI */}
                            <Route path='appMain' element={<AppMainPage />} />

                            {/* 게임 */}
                            <Route path='gameList/:page' element={<GameListPage />} />
                            <Route path='gameLogList/:gamePage/:id/:logPage' element={<GameLogListPage />} />

                            {/* 리워드 */}
                            <Route path='rewardSetting' element={<RewardSettingPage />} />
                            <Route path='rewardSend' element={<RewardSendPage />} />
                        </>
                    } 
                </Route>
                <Route path='*' element={<LoginPage></LoginPage>} />
            </Routes>
        </BrowserRouter>
    )
}
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { apiGetRewardSettingList, apiSetRewardSend } from '../../../api/apiReward';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import BasicText from '../../basic/BasicText';
import SelectItem from '../../common/item/SelectItem';
import { Button, MenuItem } from '@mui/material';
import { Center, Row } from '../../../style/basic/commonStyle';
import { IoMdCloseCircleOutline } from "react-icons/io";

export default function RewardSendPart({userList,setUserList}:any) {
    const userInfo = useUserInfo();
    const [rewardList,setRewardList] = useState<any>([]);
    const [rewardSelect,setRewardSelect] = useState('');

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getRewardList();
    },[userInfo.jwt_token])
    
    const getRewardList = async() =>{
        const res = await apiGetRewardSettingList(userInfo.jwt_token,userInfo.admin_id,1,100);
        if(res.result){
            setRewardList(res.data);
            setRewardSelect(res.data[0].reward_config_code);
        }
    }

    // 유저 제거
    const userListDel = (data:any) =>{
        setUserList((arr:any) => arr.filter((item:any) => item.user_id != data.user_id));
    }

    // 전송
    const rewardSend = async() =>{
        if(rewardSelect === ''){
            alert('리워드를 선택해주세요.');
            return;
        }
        if(window.confirm('리워드를 전송하시겠습니까?')){
            const res = await apiSetRewardSend(userInfo.jwt_token,userInfo.admin_id,rewardSelect,userList);
            
            if(res.result){
                if(res.data.failed.length === 0){
                    setUserList([]);
                    alert('전송이 완료되었습니다.');
                }else{
                    const newList = res.data.failed.map((data:any) => data.user_nickname);
                    const nameStr = newList.join(', ');
                    alert(`총 ${newList.length}건 ${nameStr} 실패했습니다.`);
                    setUserList(res.data.failed);
                }
                
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <Wrap>
            <SelectWrap>
                <BasicText>리워드</BasicText>
                <SelectItem value={rewardSelect} setValue={setRewardSelect} wrapStyle={{maxWidth:'100%'}}>
                    {rewardList === undefined || rewardList.length === 0 ?
                        <MenuItem value=""></MenuItem> :
                        rewardList.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`reward-${index}`} value={data.reward_config_code}>{`${data.reward_config_name}(${data.reward_config_code})`}</MenuItem>
                            )
                        })
                    }
                </SelectItem>
            </SelectWrap>
            <ListWrap>
                <BasicText>지급 대상자</BasicText>
                <ContentWrap>
                    {userList === undefined || userList.length === 0 ?
                        <Center>대상자가 없습니다.</Center> :
                        userList.map((data:any,index:any)=>{
                            return(
                                <ContentItemWrap key={`select-user-${index}`}>
                                    <ContentItemLeft>
                                        {/* <img src={data.user_profile} alt='user-profile'></img> */}
                                        <BasicText>{data.user_nickname}</BasicText>
                                    </ContentItemLeft>
                                    <IoMdCloseCircleOutline size={18} onClick={()=>userListDel(data)} />
                                </ContentItemWrap>
                            )
                        })
                    }
                </ContentWrap>
            </ListWrap>
            <BtnWrap>
                <Button variant='contained' disabled={userList.length === 0} onClick={rewardSend}>전송</Button>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 15px;
`;
const SelectWrap = styled(Wrap)`
    gap: 8px;
`;
const ListWrap = styled(Wrap)`
    gap: 8px;
`;

const ContentWrap = styled(Row)`
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
`;

const ContentItemWrap = styled(Row)`
    width: auto;
    gap: 25px;
    background-color: #f1f1f1;
    padding: 6px 8px;
    border-radius: 6px;
    & svg{
        cursor: pointer;
    }
`;

const ContentItemLeft = styled(Row)`
    width: auto;
    gap: 6px;
    & img{
        width: 36px;
        overflow: hidden;
        border-radius: 48px;
    }
`;

const BtnWrap = styled(Center)`
    margin-top: 25px;
    width: 100%;
`;
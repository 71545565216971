import { axiosConfigInstance } from "./config";

export const PUSH_LIST_ITEM ={
    id:0,
    title:'',
    content:'',
    time:0,
}

// 앱버전 리스트
export const apiGetVersion = async(token:string,admin_id:number) =>{
    try {
        const res = await axiosConfigInstance('/admin/config/platform',token).post('',{
            method:'adminGetPlatformVersion',
            params:{
                admin_id
            }
        });
        return res.data;
    } catch (error) {
        return{
            result:false,
            error
        }
    }
}

// 앱버전 수정
// target_platform => 1 : android / 2 : ios
export const apiSetVersion = async(token:string,admin_id:number,target_platform:string,target_version:string,target_test_version:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/config/platform',token).post('',{
            method:'adminChangePlatformVersion',
            params:{
                admin_id,
                target_platform,
                target_version,
                target_test_version,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 앱 버전 심사 on/off
export const apiSetTestVersionState = async(token:string,admin_id:any,target_platform:any,version_testing:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/config/platform',token).post('',{
            method: 'adminModifyTestingStatus',
            params: {
                admin_id,
                target_platform,
                version_testing,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 푸시 보낸 리스트
export const apiGetPushList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminGetSendPushMessageLogList',
            params:{
                admin_id,
                page,
                count,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 푸시 보내기
export const apiSendPush = async(token:string,admin_id:any,push_title:string,push_content:string,push_data:any,push_image_url:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminUtilsSendPushMessage',
            params:{
                admin_id,
                push_title,
                push_content,
                push_data,
                push_image_url,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 푸시 자주보내는거
export const apiGetBasicList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminGetBasicPushMessageList',
            params:{
                admin_id,
                page,
                count,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 예약 푸시 리스트
export const apiGetAutoPushList = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminGetReservePushMessageList',
            params:{
                admin_id,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 예약 푸시 등록
export const apiSetAutoPush = async(token:string,admin_id:any,push_title:string,push_content:string,push_data:any,push_image_url:string,push_reserve_timestamp:any,push_type:any,) =>{
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminReservePushMessage',
            params:{
                admin_id,
                push_title,
                push_content,
                push_data,
                push_image_url,
                push_reserve_timestamp,
                push_type,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 예약 푸시 삭제
export const apiSettDelAutoPush = async(token:string,admin_id:any,push_uuid:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminCancelPushMessage',
            params:{
                admin_id,
                push_uuid
            }
        })
        return res.data;
    } catch (error) {
        return{
            result: false,
            error
        }
    }
}

// 약관 리스트
export const apiGetTerms = async(token:string,admin_id:number,page:any,count:any,terms_type:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/terms',token).post('',{
            method:'adminGetTermsList',
            params:{
                admin_id,
                page,
                count,
                terms_type,
            }
        });
        return res.data;
    } catch (error) {
        return{
            result: false,
            error,
        }
    }
}

// 약관 디테일
export const apiGetDetailTerms = async(token:string,admin_id:number,terms_id:any,) =>{
    try {
        const res = await axiosConfigInstance('/admin/terms',token).post('',{
            method:'adminGetTermsDetail',
            params:{
                admin_id,
                terms_id,
            }
        });
        return res.data;
    } catch (error) {
        return{
            result: false,
            error,
        }
    }
}

// 약관 추가
export const apiSetAddTerms = async(token:string,admin_id:number,terms_type:any,terms_content:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/terms',token).post('',{
            method:'adminRegisterTerms',
            params:{
                admin_id,
                terms_type,
                terms_content,
            }
        });
        return res.data;
    } catch (error) {
        return{
            result: false,
            error,
        }
    }
}
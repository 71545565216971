import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TablePart from '../../common/part/TablePart';
import { STORY_YOUTUBE_LIST_TITLE } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import { Button, Pagination, TableRow, Tooltip } from '@mui/material';
import TableContentItem from '../../common/item/TableContentItem';
import { Row, TextItem } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';
import { numberWithComma } from '../../../utils/format/number';
import YoutubeBtnPart from './YoutubeBtnPart';
import { set } from 'lodash';
import { apiSetVideoUpdate, apiSetYoutubeHideShow } from '../../../api/apiStory';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

export default function YoutubeListPart({list,index,nowPage,total,genreList,getList}:any) {
    const navigate = useNavigate();
    const [open,setOpen] = useState(false);
    const [selectItem,setSelectItem] = useState<any>();
    const userInfo = useUserInfo();

    useEffect(()=>{
        if(selectItem){
            setOpen(true);
        }else{
            setOpen(false);
        }
    },[selectItem])

    // 페이지 이동
    const pageChange = (count:number) =>{
        navigate(`/storyYoutube/${index}/${count}`);
    }

    // 이미지 클릭 -> 유튜브 
    const imgClick = (videoId:any) =>{
        window.open(`https://www.youtube.com/watch?v=${videoId}`);
    }

    // 숨김
    const hideAction = async(item:any) =>{
        if(window.confirm('숨김 하시겠습니까?')){
            const res = await apiSetYoutubeHideShow(userInfo.jwt_token,userInfo.admin_id,item.sns_id,"0");
            if(res.result){
                alert('숨김 되었습니다.');
                getList();
                close();
            }
        }
    }

    // 승인
    const approvalAction = async(item?:any) =>{
        if(window.confirm('승인 하시겠습니까?')){

        }
    }

    // 복원
    const recoverAction = async(item?:any) =>{
        if(window.confirm('복원 하시겠습니까?')){
            const res = await apiSetYoutubeHideShow(userInfo.jwt_token,userInfo.admin_id,item.sns_id,"1");
            if(res.result){
                alert('복원 되었습니다.');
                getList();
                close();
            }
        }
    }

    // 관리 클릭
    const itemClick = (data:any) =>{
        setSelectItem(data);
    }

    // 관리 닫기
    const close = () =>{
        setSelectItem(undefined);
    }

    // 메인 등록
    const mainAction = async() =>{
        const main = selectItem.is_recommend === '0' ? '1':'0';
        const resStr = selectItem.is_recommend === '0' ? '등록' : '해제'
        if(window.confirm(`${resStr} 하시겠습니까?`)){
            const res = await apiSetVideoUpdate(userInfo.jwt_token,userInfo.admin_id,selectItem.sns_id,main);
            if(res.result){
                alert(`${resStr} 되었습니다.`);
                getList();
                close();
            }
        }
    }


    return (
        <Wrap>
            <TablePart titleArr={STORY_YOUTUBE_LIST_TITLE} minWidth={850} >
                {list === undefined || list.length === 0 ? 
                    <TableNoItem colSpan={STORY_YOUTUBE_LIST_TITLE.length}></TableNoItem>:
                    list?.map((data:any,index:any)=>{
                        const genre = genreList.filter((item:any)=>item.sound_small_category === data.sns_genre);
                        const status = data.sns_visible === '0' ? '숨김' :
                                        data.sns_visible === '1' ? '승인' :
                                        data.sns_visible === '2' ? '친구만' :
                                        '대기' 
                        return(
                            <TableRow
                                key={`tableContent-${index}`}
                            >
                                <TableContentItem text={data.sns_id} />
                                <TableContentItem text={data.sns_small_category === '2' ? 'KPOP' : 'POP'} />
                                <TableContentItem text={genre.length === 0 ? data.sns_genre : genre[0].category_title} />
                                <TableContentItem>
                                    <ImgItem onClick={()=>imgClick(data.sns_youtube_video_id)} src={`https://img.youtube.com/vi/${data.sns_youtube_video_id}/0.jpg`} />
                                </TableContentItem>
                                <TableContentItem>
                                    <Tooltip title={data.sns_title} arrow={true}>
                                        <TextItem line={1}>{data.sns_title}</TextItem>
                                    </Tooltip>
                                </TableContentItem>
                                <TableContentItem>
                                    <Tooltip title={data.sns_description} arrow={true}>
                                        <TextItem line={2}>{data.sns_description}</TextItem>
                                    </Tooltip>
                                </TableContentItem>
                                <TableContentItem text={data.sns_timestamp} type='time' />
                                <TableContentItem text={numberWithComma(data.like_cnt)}></TableContentItem>
                                <TableContentItem text={numberWithComma(data.reply_cnt)}></TableContentItem>
                                <TableContentItem text={numberWithComma(data.donate_amount)}></TableContentItem>
                                <TableContentItem text={status}></TableContentItem>
                                <TableContentItem>
                                    {/* {data.sns_visible === '0' &&
                                        <Button variant='contained' color='success' onClick={()=>recoverAction(data.sns_id)}>복원</Button>
                                    }
                                    {data.sns_visible === '1' &&
                                        <Button variant='contained' color='error' onClick={()=>hideAction(data.sns_id)}>숨김</Button>
                                    }
                                    {data.sns_visible === '3' && 
                                        <Button variant='contained' onClick={()=>approvalAction(data.sns_id)}>승인</Button>
                                    } */}
                                    <Button variant='contained' onClick={()=>itemClick(data)}>관리</Button>
                                </TableContentItem>
                            </TableRow>
                        )
                    })
                }
            </TablePart>
            <PaginationWrap>
                <Pagination shape="rounded" page={nowPage} count={total} onChange={(e, page) => pageChange(page)}></Pagination>
            </PaginationWrap>
            <YoutubeBtnPart open={open} setOpen={close} selectItem={selectItem} hideAction={hideAction} recoverAction={recoverAction} approvalAction={approvalAction} mainBtnEvent={mainAction} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const PaginationWrap = styled(Row)`
    padding: 20px 0;
    justify-content: end;
`;

const ImgItem = styled.img`
    cursor: pointer;
`;
import React from 'react'
import styled from 'styled-components'
import ContentItem from '../item/ContentItem';
import { Center } from '../../../style/basic/commonStyle';
import GoogleAdBanner_001_input from '../input/GoogleAdBanner_001_input';
import HorizontalScroll_001_input from '../input/HorizontalScroll_001_input';
import VerticalScroll_001_input from '../input/VerticalScroll_001_input';
import HorizontalScroll_100_input from '../input/HorizontalScroll_100_input';

export default function AppMainInputPart({contentList,update}:any) {
    
    return (
        <Wrap>
            {contentList === undefined || contentList.length === 0 ?
                <Center>
                    No items to display
                </Center> :
                <ContentWrap>
                    {contentList.map((data:any,index:any)=>{
                        return(
                            <ContentItemWrap key={`content-${index}`} >
                                {data.type === 'google_ad_banner_001' && <GoogleAdBanner_001_input data={data} update={update} index={index} /> }
                                {data.type === 'horizon_scroll_001' && <HorizontalScroll_001_input data={data} update={update} index={index} /> }
                                {data.type === 'horizon_scroll_100' && <HorizontalScroll_100_input data={data} update={update} index={index} /> }
                                {data.type === 'vertical_scroll_001' && <VerticalScroll_001_input data={data} update={update} index={index} /> }
                                {/* {
                                    data.type === 'google_ad_banner_001' ? <GoogleAdBanner_001_input data={data} update={update} index={index} /> :
                                    data.type === 'horizon_scroll_001' ? <HorizontalScroll_001_input data={data} update={update} index={index} /> :
                                    data.type === 'horizon_scroll_100' ? <HorizontalScroll_100_input data={data} update={update} index={index} /> :
                                    data.type === 'vertical_scroll_001' ? <VerticalScroll_001_input data={data} update={update} index={index} /> :
                                    <></>
                                } */}
                            </ContentItemWrap>
                        )
                    })}
                </ContentWrap>
            }
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div``;
const ContentItemWrap = styled.div`
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 4px;
    margin: 8px 0;
`;
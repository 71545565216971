import { axiosConfigInstance } from "./config"

// 유저 로그인 차트
export const apiGetStatUserLoginLog = async(token:string,admin_id:any,count:any,period_str='1day') =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatUserLoginLog',
            params: {
                admin_id,
                count,
                period_str,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 유저 가입 차트
export const apiGetStatUserSignLog = async(token:string,admin_id:any,count:any,period_str='1day') =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatUserSign',
            params: {
                admin_id,
                count,
                period_str,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 유저 가입,가입 총수
export const apiGetStatUserTotal = async(token:string,admin_id:any,part:'login_count'|'user') =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatCount',
            params: {
                admin_id,
                part,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 유저 로그인 리스트
export const apiGetStatUserLoginList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/log',token).post('',{
            method: 'admGetUserLoginLogList',
            params: {
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 유저 회원가입 리스트
export const apiGetStatUserSignList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/log',token).post('',{
            method: 'admGetUserSignLogList',
            params: {
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 유저 os별 개수 가져오기
export const apiGetStatOs = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatDeviceOSCount',
            params: {
                admin_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 국가별 유저
export const apiGetStatUserCountry = async(token:string,admin_id:any,count:any,period_str:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/stat',token).post('',{
            method: 'adminGetStatUserCountry',
            params: {
                admin_id,
                count,
                period_str,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
import { axiosConfigInstance } from "./config";

// ui json 가져오기
export const apiGetAppUi = async(token:string,admin_id:any,part:string,is_prev:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/layout',token).post('',{
            method:'adminGetLayoutPart',
            params:{
                admin_id,
                part,
                is_prev,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// ui 수정
export const apiSetAppUiUpdate = async(token:string,admin_id:any,part:string,json_data:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/layout',token).post('',{
            method:'adminSetModifyLayoutPart',
            params:{
                admin_id,
                part,
                json_data,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 수정된 ui 적용
export const apiSetAppUiUpdateRes = async(token:string,admin_id:any,part:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/layout',token).post('',{
            method:'adminSetConfirmLayoutPart',
            params:{
                admin_id,
                part,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}
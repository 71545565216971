import { Button, Dialog, DialogContent } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { Center, Row, RowBetween, TextItem } from '../../../style/basic/commonStyle';
import { GoCopy } from "react-icons/go";
import { CopyAction } from '../../basic/extends/CopyExtends';

export default function UserUrlOpenItem({open,setOpen,data}:any) {
    const close = () =>{
        setOpen(false);
    }

    const copyClick = (txt:string) =>{
        CopyAction(txt);
    }
    
    return (
        <Dialog
            open={open}
            onClose={close}
            sx={{
                '.MuiDialog-container':{alignItems:'center'},
                '.MuiPaper-root':{margin:'25px',maxWidth:'420px',width:'100%',borderRadius: '6px'}
            }}
        >
            <DialogContent dividers sx={{overflow:'hidden'}}>
                <Wrap>
                    <div>
                        <RowBetween>
                            <TextItem fw={700} fontSize={16}>Link</TextItem>
                            <GoCopy size={16} color='#000' onClick={()=>copyClick(data.link)} />
                        </RowBetween>
                        <TextItem fontSize={17} marginTop={6}>{data.link}</TextItem>
                    </div>
                    <div>
                        <RowBetween>
                            <TextItem fw={700} fontSize={16}>Code</TextItem>
                            <GoCopy size={16} color='#000' onClick={()=>copyClick(data.code)} />
                        </RowBetween>
                        <TextItem fontSize={17} marginTop={6}>{data.code}</TextItem>
                    </div>
                </Wrap>
                <BtnWrap>
                    <Button variant='contained' onClick={close}>확인</Button>
                </BtnWrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled(Row)`
    flex-direction: column;
    gap: 20px;
    align-items: start;
    width: 100%;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    &>div{
        width: 100%;
    }
    & svg{
        cursor: pointer;
    }
`;

const BtnWrap = styled(Center)`
    margin-top: 26px;
`;
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { useTheme } from '../../utils/custom-hooks/common/useTheme';
import { numberWithComma } from '../../utils/format/number';

interface BasicTextProps {
    bold?:any;
    custom_styles?:any;
    noWrap?: boolean;
    fontFamily?: string;
    fontSize?: number;
    children?: any;
    textAlign?: any;
    fontWeight?: string | number;
    theme?: any;
    color?: any;
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    xs?: boolean;
    md?: boolean;
    comma?: boolean;
    hover?: boolean;
    tooltip?: boolean;
    tooltipText?: string;
    wrapText?:any;
    style?:any;
    onClick?:any;
}

export default function BasicText({noWrap, fontFamily, fontSize, theme, color, children, textAlign, fontWeight, marginTop, marginBottom, marginLeft, marginRight, xs, md, comma, hover, tooltip, tooltipText,wrapText, bold,custom_styles, ...rest}: BasicTextProps){

    const themeColor = useTheme();
    const addStyle = custom_styles && String(custom_styles).replaceAll(';',' !important;');
    
    return (
        <>
        <Text
            noWrap={noWrap ?? false}
            fontSize={fontSize}
            fontFamily={fontFamily ?? 'unset'}
            color={color ?? themeColor[theme]}
            textAlign={textAlign}
            fontWeight={fontWeight}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            hover={hover}
            wraptext={wrapText}
            bold={bold?.toString()}
            custom_styles={addStyle}
            sx={
                {
                    display: {
                        xs: xs ? 'none' : 'flex',
                        md: md ? 'none' : 'flex'
                    }
                }
            }
            {...rest}
            >
            {comma ? numberWithComma(children) : children}
        </Text>
        </>
    )
}

const Text = styled(Typography)<{
    marginTop?: number,
    marginBottom?: number,
    marginLeft?: number,
    marginRight?: number,
    hover?: boolean,
    wraptext?:any,
    bold?:any,
    custom_styles?:any,
}>`
    word-break: ${props => props.wraptext ? 'break-word' : 'normal'};
    font-weight: ${props => props.bold === 'true' ? '800':'400'};
    ${props => props.custom_styles};
`;

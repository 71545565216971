import React,{useEffect, useState} from 'react'
import LeftNavBar from '../../common/part/LeftNavBar';
import { Box, Button, Grid, TableRow } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { AdminWrap, Center, RowBetween } from '../../../style/basic/commonStyle';
import InputItem from '../../common/item/InputItem';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import { PUSH_LIST_ITEM, apiGetBasicList, apiGetPushList, apiSendPush } from '../../../api/apiSetting';
import ListPart from '../../common/part/ListPart';
import { APP_PUSH_BASIC_TITLE, APP_PUSH_TITLE } from '../../../data/tableTitle';
import TableContentItem from '../../common/item/TableContentItem';
import AppPushTargetPart from '../part/AppPushTargetPart';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import TableNoItem from '../../common/item/TableNoItem';

// 타겟 1 전체 / 2 회원 / 3 비회원
const TARGET = ['전체','회원','비회원'];

export default function AppPushPage() {
    const userInfo = useUserInfo();
    const pageTitle = '앱 푸시 알림';
    const {page} = useParams();
    const nowPage = Number(page) ?? 1;

    const [basicList,setBasicList] = useState<any>([]);
    const [basicPage,setBasicPage] = useState(1);
    const [basicTotal,setBasicTotal] = useState(0);
    
    const [target,setTarget] = useState('');
    const [title,setTitle] = useState('');
    const [content,setContent] = useState('');
    const [imgUrl,setImgUrl] = useState('');
    const [action,setAction] = useState('');
    
    const [navigator,setNavigator] = useState('');
    const [screen,setScreen] = useState('');
    const [tab,setTab] = useState('');

    const [webUrl,setWebUrl] = useState('');

    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);

    // useEffect(()=>{
    //     console.log(target);
    // },[target]);

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,nowPage]);

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getBasicList();
    },[userInfo.jwt_token,basicPage])

    const getBasicList = async() =>{
        const res = await apiGetBasicList(userInfo.jwt_token,userInfo.admin_id,basicPage,5);
        if(res.result){
            setBasicList(res.data);
            setBasicTotal(res.total);
        }else{
            setBasicList([]);
            setBasicTotal(0);
        }
    }

    const getList = async() =>{
        const res = await apiGetPushList(userInfo.jwt_token,userInfo.admin_id,nowPage,10);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    const reset = () =>{
        setTitle('');
        setContent('');
        setImgUrl('');
        setAction('');
        setNavigator('');
        setScreen('');
        setTab('');
        setWebUrl('');
    }

    // 푸시알림 전송
    const sendAction = async() =>{
        // if(target === ''){
        //     alert('푸시알림을 받을 대상을 선택해주세요.');
        //     return;
        // }
        
        if(title === ''){
            alert('제목을 입력해주세요.');
            return;
        }

        if(content === ''){
            alert('내용을 입력해주세요.');
            return;
        }
        let data = {
            action,
            screen,
            navigator,
            tab,
            webview_url:webUrl,
        }
        const res = await apiSendPush(userInfo.jwt_token,userInfo.admin_id,title,content,data,imgUrl);
        if(res.result){
            alert('푸시 메세지를 보냈습니다.');
            reset();
            getList();
        }else{
            console.log(res.error);
        }
    }

    // 기본 푸시 알림 클릭
    const basicListItemClick = (data:any) =>{
        setTitle(data.push_title);
        setContent(data.push_content);
        setImgUrl(data.push_image_url);
        if(data.push_data === ''){
            setAction('');
            setScreen('');
        }else{
            const item = String(data.push_data).replaceAll("'",'"');
            const obj = JSON.parse(item);
            
            setAction(obj.action ?? '');
            setNavigator(obj.navigator ?? '');
            setScreen(obj.screen ?? '');
            setTab(obj.tab ?? '');
            setWebUrl(obj.webview_url ?? '');
        }
    }

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'푸시알림'} />
                        {/* <AppPushTargetPart target={target} setTarget={setTarget}></AppPushTargetPart> */}
                        <InputWrap>
                            <InputItem label='제목' inputLabel='none' inputSx={{width:'100%'}} value={title} valueChange={setTitle} />
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='내용' inputLabel='none' inputSx={{width:'100%'}} value={content} valueChange={setContent} />
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='이미지URL' inputLabel='none' inputSx={{width:'100%'}} value={imgUrl} valueChange={setImgUrl} />
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='액션' inputLabel='none' inputSx={{width:'100%'}} value={action} valueChange={setAction} />
                        </InputWrap>
                        <InputWrap>
                            <RowBetween>
                                <InputItem label='네비게이터' inputLabel='none' inputSx={{width:'100%'}} value={navigator} valueChange={setNavigator} />
                                <InputItem label='스크린' inputLabel='none' inputSx={{width:'100%'}} value={screen} valueChange={setScreen} />
                                <InputItem label='탭' inputLabel='none' inputSx={{width:'100%'}} value={tab} valueChange={setTab} />
                            </RowBetween>
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='웹뷰링크' inputLabel='none' inputSx={{width:'100%'}} value={webUrl} valueChange={setWebUrl} />
                        </InputWrap>
                        <BtnWrap>
                            <Button variant='contained' onClick={sendAction}>전송</Button>
                        </BtnWrap>
                    </AdminWrap>
                </Grid>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'기본 푸시알림'} />
                        <ListPart titleArr={APP_PUSH_BASIC_TITLE} minWidth={600} nowPage={basicPage} setPage={setBasicPage} total={basicTotal}>
                            {basicList === undefined || basicList.length === 0 ?
                                <TableNoItem colSpan={APP_PUSH_BASIC_TITLE.length} /> :
                                basicList.map((data:any,index:any)=>{
                                    return(
                                        <TableRow key={`basicList-${index}`}>
                                            <TableContentItem text={data.push_title} />
                                            <TableContentItem text={data.push_content} />
                                            <TableContentItem text={data.push_data} />
                                            <TableContentItem text={data.push_image_url} />
                                            <TableContentItem>
                                                <Button variant='contained' onClick={()=>basicListItemClick(data)}>선택</Button>
                                            </TableContentItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </AdminWrap>
                </Grid>
                <Grid item md={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'전송 내역'} />
                        <ListPart titleArr={APP_PUSH_TITLE} minWidth={700} nowPage={nowPage} total={total} link={'appPush'}>
                            {list === undefined || list.length === 0 ?
                                <TableNoItem colSpan={APP_PUSH_TITLE.length} /> :
                                list.map((data:any,index:any)=>{
                                    const targetName = TARGET[Number(data.push_target) - 1];
                                    const dataStr = JSON.stringify(data.push_send_data);
                                    return(
                                        <TableRow key={`push-list-${index}`}>
                                            <TableContentItem text={data.admin_name}></TableContentItem>
                                            <TableContentItem text={targetName}></TableContentItem>
                                            <TableContentItem text={data.push_title}></TableContentItem>
                                            <TableContentItem text={data.push_content}></TableContentItem>
                                            <TableContentItem text={dataStr}></TableContentItem>
                                            <TableContentItem text={data.push_user_cnt}></TableContentItem>
                                            <TableContentItem text={data.push_timestamp} type='time'></TableContentItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

const InputWrap = styled.div`
    margin-top: 15px;
`;

const BtnWrap = styled(Center)`
    margin-top: 30px;
`;
import { blue, grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import thunk from 'redux-thunk';
import GlobalLoadingModalPage from './components/common/page/GlobalLoadingModalPage';
import Router from './routes/Router';
import reducer from './store/store';
import { GlobalStyle } from './style/globalStyle';
import { sizeTheme } from './style/sizeTheme';
import { thunkTheme } from './store/common/theme';

export default function App(){

  const myReducer = reducer();
  const store = createStore(myReducer, applyMiddleware(thunk));
  const persistor = persistStore(store);
  // const customTheme = useTheme();
  
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // const store = createStore(rootReducer);


  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: blue[500],
          },
          secondary: {
            main: grey[100],
          }
        },
        typography: {
          fontFamily: "'ChakraPetch-Regular', sans-serif"
        },
        components: {
        }
      }),
    [prefersDarkMode],
  );
  let newTheme:any = {
    ...theme,
    ...sizeTheme
  }
  
  return (
    <>
    {/* <StrictMode> */}
        <ThemeProvider theme={newTheme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <GlobalStyle/>
                <Router/>
                <GlobalLoadingModalPage/>
            </PersistGate>
          </Provider>
        </ThemeProvider>
    {/* </StrictMode> */}
    </>
  )
}
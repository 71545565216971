import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import { Row } from '../../../style/basic/commonStyle';

interface searchPartType {
    setRes?:any;
    inputLabel?:any;
}

export default function SearchPart({setRes,inputLabel}:searchPartType) {
    const [search,setSearch] = useState('');

    const searchChange = (event: { target: { value: string } }) =>{
        setSearch(event.target.value);
    }

    // 검색 버튼 클릭
    const searchClick = () =>{
        if(setRes === undefined) return;
        setRes(search);
    }

    // 검색어 입력 엔터
    const submitAction = (e:any) =>{
        e.preventDefault();
        searchClick();
    }
    
    return (
        <Wrap>
            <FormControl size='small' variant="standard" sx={{'& .MuiInputBase-formControl':{borderRadius:'4px 0 0 4px'}}}>
                <Form onSubmit={submitAction}>
                    <TextField size='small' id="outlined-basic" label={inputLabel === undefined ? "search" : inputLabel} variant="outlined" value={search || ''} onChange={searchChange}/>
                </Form>
            </FormControl>
            <FormControl>
                <Button variant='contained' sx={{height:'40px', borderRadius:'0 4px 4px 0'}} onClick={()=>searchClick()}>
                    <SearchIcon></SearchIcon>
                </Button>
            </FormControl>
        </Wrap>
    )
}
const Wrap = styled(Row)`
    width: auto;
    flex-shrink: 0;
`;
const Form = styled.form``;
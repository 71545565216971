import React, { useState,useEffect } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import { TabPanelPart } from '../../common/part/TabPanelPart'
import StatUserListPart from '../part/StatUserListPart'
import LineChartPart from '../part/LineChartPart'
import { apiGetStatOs, apiGetStatUserCountry, apiGetStatUserLoginLog, apiGetStatUserSignLog, apiGetStatUserTotal } from '../../../api/apiStat'
import { momentFormat } from '../../../utils/format/time'
import { numberWithComma } from '../../../utils/format/number'
import StatUserSignListPart from '../part/StatUserSignListPart'
import PieChartPart from '../part/PieChartPart'
import styled, { keyframes } from 'styled-components'
import { AdminWrap, Center, Row } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme'
import StackBarChartPart from '../part/StackBarChartPart'
import AreaChartPart from '../part/AreaChartPart'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import LoadingItem from '../item/LoadingItem'

const CHART_FILTER = [{name:'1hour',count:24,format:'hh:mm',title:'1시간'}, {name:'12hour',count:14,format:'DD hh:mm',title:'12시간'}, {name:'1day',count:30, format:'MM-DD',title:'일간'}, {name:'1month',count:27,format:'YY-MM',title:'월간'}];

export default function StatUserPage() {
    const userInfo = useUserInfo();
    const pageTitle = '유저';
    const [tab,setTab] = useState(0);
    const [statLoginLoading,setStatLoginLoading] = useState(false);
    // const [statLogin,setStatLogin] = useState<any>({data:[],xaxis:[]});
    const [statLogin,setStatLogin] = useState<any>([]);
    const [statLoginMore,setStatLoginMore] = useState(false);
    const [statLoginSign,setStatLoginSign] = useState(false);
    const [statSignMore,setStatSignMore] = useState(false);
    const [statSign,setStatSign] = useState<any>([]);
    const [totalLogin,setTotalLogin] = useState(0);
    const [totalSign,setTotalSign] = useState(0);
    const [page1,setPage1] = useState(1);
    const [page2,setPage2] = useState(1);
    const [filterLogin,setFilterLogin] = useState(2);
    const [filterSign,setFilterSign] = useState(2);
    
    const [osCount,setOsCount] = useState<any>([]);
    
    const [filterCountry,setFilterCountry] = useState(2);
    const [statCountryLoading,setStatCountryLoading] = useState(false);
    const [statCountryMore,setStatCountryMore] = useState(false);
    const [statCountry,setStatCountry] = useState<any>({list:[], linList:[],colorList:[]});

    useEffect(()=>{
        if(userInfo.admin_id === 0) return;
        getTotal();
        getChartOs();
    },[userInfo.admin_id]);

    useEffect(()=>{
        if(userInfo.admin_id === 0) return;
        getChartLogin();
    },[userInfo.admin_id,filterLogin]);
    useEffect(()=>{
        if(userInfo.admin_id === 0) return;
        getChartSign();
    },[userInfo.admin_id,filterSign]);
    useEffect(()=>{
        if(userInfo.admin_id === 0) return;
        getChartCountry();
    },[userInfo.admin_id,filterCountry]);

    const getChartLogin = async() =>{
        setStatLoginLoading(true);
        const res = await apiGetStatUserLoginLog(userInfo.jwt_token,userInfo.admin_id,CHART_FILTER[filterLogin].count,CHART_FILTER[filterLogin].name);
        if(res.result){
            // let timeline:any = [];
            // let countLine:any = [];
            // const newList = res.data.map((data:any)=>{
            //     timeline.push(momentFormat(CHART_FILTER[filterLogin].format,data.timestamp) );
            //     countLine.push(data.count);
            // });
            // setStatLogin({data:countLine.reverse(),xaxis:timeline.reverse()});

            // const newList = res.data.map((data:any)=>({date:new Date(data.timestamp),open:data.count,high:data.count,low:data.count,close:data.count,volume:data.count}))
            // setStatLogin(newList.reverse());

            const newList = res.data.map((data:any)=>({...data,name:momentFormat(CHART_FILTER[filterLogin].format,data.timestamp)}));
            setStatLogin(newList.reverse());
        }
        setStatLoginLoading(false);
    }

    const getChartSign = async() =>{
        setStatLoginSign(true);
        const res = await apiGetStatUserSignLog(userInfo.jwt_token,userInfo.admin_id,CHART_FILTER[filterSign].count,CHART_FILTER[filterSign].name);
        if(res.result){
            const newList = res.data.map((data:any)=>({...data,name:momentFormat(CHART_FILTER[filterSign].format,data.timestamp)}));
            setStatSign(newList.reverse());
        }
        setStatLoginSign(false);
    }

    const getChartOs = async() =>{
        const res = await apiGetStatOs(userInfo.jwt_token,userInfo.admin_id);
        if(res.result){
            const newList = Object.keys(res.data).map((key:any)=>({name:key,count:res.data[key]}));
            setOsCount(newList);
        }
    }

    const getTotal = async() =>{
        const res1 = await apiGetStatUserTotal(userInfo.jwt_token,userInfo.admin_id,'login_count');
        if(res1.result){
            setTotalLogin(res1.data.count);
            
        }
        const res2 = await apiGetStatUserTotal(userInfo.jwt_token,userInfo.admin_id,'user');
        if(res1.result){
            setTotalSign(res2.data.count);
        }
    }

    const getChartCountry = async() =>{
        setStatCountryLoading(true);
        const res = await apiGetStatUserCountry(userInfo.jwt_token,userInfo.admin_id,CHART_FILTER[filterCountry].count,CHART_FILTER[filterCountry].name)
        if(res.result){
            const {timestamp, ...rest} = res.data[0];
            const lineList = Object.keys(rest);
            const newList = res.data.map((data:any)=>({name:momentFormat(CHART_FILTER[filterCountry].format,data.timestamp),...data}));
            const colorList = lineList.map(()=>{
                const hex = '#' + Math.round(Math.random() * 0xffffff).toString(16);
                return hex
            });
            
            setStatCountry({list:newList.reverse(),lineList,colorList});
        }
        setStatCountryLoading(false);
    }

    return (
        <Grid container={true} spacing={2}>
            <Grid item lg={8} md={12} sm={12}>
                <CharWrap>
                    <CharItem>
                        <AdminWrap>
                            <AreaChartPart titleStr={`${CHART_FILTER[filterLogin].title} 활성 사용자`} title={<Row style={{width:'auto'}}>{CHART_FILTER[filterLogin].title} 활성 사용자 수 <span style={{fontSize:14,marginLeft:'5px',alignSelf:'center'}}>(총: {numberWithComma(totalLogin)})</span></Row>} labelList={['접속자','안드','애플','웹']} lineList={['count']} colorList={['#ff6a006f','#00bb3260','#2594b649','#c800ff65']} list={statLogin} filterList={CHART_FILTER} filter={filterLogin} setFilter={setFilterLogin} more={statLoginMore} setMore={setStatLoginMore}/>
                            {statLoginLoading && <LoadingItem />}
                            <DetailWrap more={statLoginMore}>
                                <AreaChartPart titleStr={`${CHART_FILTER[filterLogin].title} 활성 사용자`} height={800} title={<Row style={{width:'auto'}}>{CHART_FILTER[filterLogin].title} 활성 사용자 수 <span style={{fontSize:14,marginLeft:'5px',alignSelf:'center'}}>(총: {numberWithComma(totalLogin)})</span></Row>} labelList={['접속자','안드','애플','웹']} lineList={['count']} colorList={['#ff6a006f','#00bb3260','#2594b649','#c800ff65']} list={statLogin} filterList={CHART_FILTER} filter={filterLogin} setFilter={setFilterLogin} more={statLoginMore} setMore={setStatLoginMore}/>
                                {statLoginLoading && <LoadingItem />}
                            </DetailWrap>
                        </AdminWrap>
                    </CharItem>
                    <CharItem>
                        <AdminWrap>
                            <LineChartPart title={<>{CHART_FILTER[filterSign].title} 가입자 수 <span style={{fontSize:14,marginLeft:'5px',alignSelf:'center'}}>(총: {numberWithComma(totalSign)})</span></>} labelList={['가입자','안드','애플','웹']} lineList={['count']} colorList={['#0063ff','#06bb82','#d2a813','#cc04d3']} list={statSign} filterList={CHART_FILTER} filter={filterSign} setFilter={setFilterSign} more={statSignMore} setMore={setStatSignMore}/>
                            {statLoginSign && <LoadingItem />}
                            <DetailWrap more={statSignMore}>
                                <LineChartPart height={800} title={<>{CHART_FILTER[filterSign].title} 가입자 수 <span style={{fontSize:14,marginLeft:'5px',alignSelf:'center'}}>(총: {numberWithComma(totalSign)})</span></>} labelList={['가입자','안드','애플','웹']} lineList={['count']} colorList={['#0063ff','#06bb82','#d2a813','#cc04d3']} list={statSign} filterList={CHART_FILTER} filter={filterSign} setFilter={setFilterSign} more={statSignMore} setMore={setStatSignMore}/>
                                {statLoginSign && <LoadingItem />}
                            </DetailWrap>
                        </AdminWrap>
                    </CharItem>
                    {/* <CharItem>
                        <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2}}>
                            <PieChartPart title={'OS 별'} list={osCount} />
                        </Box>
                    </CharItem> */}
                    <CharItem>
                        <AdminWrap>
                            <StackBarChartPart title={`${CHART_FILTER[filterCountry].title} 국가별 별`} list={statCountry?.list} labelList={statCountry?.lineList} lineList={statCountry?.lineList} colorList={statCountry?.colorList} filterList={CHART_FILTER} filter={filterCountry} setFilter={setFilterCountry} more={statCountryMore} setMore={setStatCountryMore}/>
                            {statCountryLoading && <LoadingItem />}
                            <DetailWrap more={statCountryMore}>
                                <StackBarChartPart height={800} title={`${CHART_FILTER[filterCountry].title} 국가별 별`} list={statCountry?.list} labelList={statCountry?.lineList} lineList={statCountry?.lineList} colorList={statCountry?.colorList} filterList={CHART_FILTER} filter={filterCountry} setFilter={setFilterCountry} more={statCountryMore} setMore={setStatCountryMore}/>
                                {statLoginSign && <LoadingItem />}
                            </DetailWrap>
                        </AdminWrap>
                    </CharItem>
                </CharWrap>
            </Grid>
            <Grid item lg={4} md={12} sm={12} sx={{width: '100%'}}>
                <AdminWrap>
                    <TableWrapTitleItem title={'리스트'}></TableWrapTitleItem>
                    <Tabs value={tab} onChange={(e:any,val:any)=>setTab(val)}>
                        <Tab label={'접속자 로그'} />
                        <Tab label={'가입자 로그'} />
                    </Tabs>
                    <TabPanelPart value={tab} index={0}>
                        <StatUserListPart page={page1} setPage={setPage1}/>
                    </TabPanelPart>
                    <TabPanelPart value={tab} index={1}>
                        <StatUserSignListPart page={page2} setPage={setPage2} />
                    </TabPanelPart>
                </AdminWrap>
            </Grid>
        </Grid>
    )
}

const CharWrap = styled(Row)`
    gap: 15px;
    flex-wrap: wrap;
    align-items: start;
`;

const CharItem = styled.div`
    width: calc(50% - 15px);
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;

const DetailWrap = styled.div<{more:boolean}>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 20;
    display: ${({more}) => more ? 'block' : 'none'};
`;
import { axiosConfigInstance } from "./config"

export const apiLogin = async(email:string,pass:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/account','').post('',{
            method:'adminLogin',
            params:{
                admin_email:email,
                admin_password:pass,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error
        }
    }
}

export const apiLoginLog = async(id:number) =>{
    try {
        const ipData = await fetch('https://geolocation-db.com/json/');
        const locationIp = await ipData.json();

        const res = await axiosConfigInstance('/admin/user').post('',{
            method:'login_log',
            params:{
                admin_id:id,
                admin_log_ip:locationIp.IPv4,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error
        }
    }
}
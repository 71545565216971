import React, { SetStateAction, useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, LineChart, Line } from 'recharts';
import styled from 'styled-components';
import { Center, Row, RowBetween, TextItem } from '../../../style/basic/commonStyle';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { numberWithComma } from '../../../utils/format/number';
import SelectItem from '../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import ApexChartPart from './ApexChartPart';
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import FinancialChartPart from './FinancialChartPart';


const COLOR = ['#ff6b00','#0063ff','#00bb31','#c900ff'];

export default function AreaChartPart({title,height,titleStr,list,labelList,lineList,colorList,filterList,filter,setFilter,more,setMore}:any) {
    const [data,setData] = useState<any>([]);

    useEffect(()=>{
        setData(list);
    },[list]);

    const CustomAxis = (props:any) =>{
        const { x, y, payload } = props;
        return (
            <text className='x-text' x={x} y={y} dy={16} textAnchor="middle" fill="#666">
              { payload.value}
            </text>
        );
    }
    const CustomTooltip = (data:any) => {
        if(data.active){
            return(
                <TooltipWrap>
                    <p>{data.label}</p>
                    <TooltipItemWrap>
                        {data.payload.map((item:any,index:any)=>{
                            return(
                                <p key={`labe-${index}`}><span>{labelList[index]}:</span> <span>{numberWithComma(item.value)}</span></p>
                            )
                        })}
                    </TooltipItemWrap>
                </TooltipWrap>
            )
        }
        return null;
    }
    return (
        <Wrap>
            <RowBetween style={{flexWrap:'wrap'}}>
                <TableWrapTitleItem title={title} size={17}>
                    <Row style={{width:'auto', gap: '8px',}}>
                        {filterList === undefined || filterList.length === 0 ?
                            <></> :
                            <SelectItem value={filter} setValue={setFilter} sx={{'.MuiSelect-select ':{padding:'5px'}}}>
                                {filterList.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`filter-${index}`} value={index}>{data.name}</MenuItem>
                                    )
                                })}
                            </SelectItem>
                        }
                        <Center onClick={()=>setMore(!more)} style={{cursor:'pointer'}}>
                            {more ?
                                <BsFullscreenExit size={24}/> :
                                <BsFullscreen size={24}/>
                            }
                        </Center>
                    </Row>
                </TableWrapTitleItem>
            </RowBetween>
            <ChartWrap>
                {/* {list === undefined || list?.data.length === 1 ?
                    <></> :
                    <ApexChartPart title={titleStr} list={list}></ApexChartPart>
                } */}
                <ResponsiveContainer width={"100%"} height={height ?? 300}>
                    <AreaChart
                        data={data}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" tick={CustomAxis} />
                        <YAxis tickCount={30} domain={([dataMin, dataMax]) => { const newMin = filter === 3 ? 1000 : dataMin; return [newMin, dataMax]}} />
                        <Tooltip
                            labelStyle={{ color: '#5b5b5b', marginBottom:'6px' }}
                            content={<CustomTooltip />}
                        />
                        {lineList === undefined || lineList.length === 0 ?
                            <Area dataKey="data" stroke="#8884d8" activeDot={{ r: 6 }} /> :
                            lineList.map((data:any,index:any)=>{
                                return(
                                    <Area key={`line-${index}`} stackId="1" dataKey={data} stroke={colorList[index]} fill={colorList[index]} activeDot={{ r: 4 }} />
                                )
                            })
                        }
                    </AreaChart>
                </ResponsiveContainer>
                {/* {data.length !== 0 &&
                    <FinancialChartPart initialData={data} />
                } */}
            </ChartWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    border-radius: 5px;
    padding: 0 5px 5px;
`;

const ChartWrap = styled.div`
    & .recharts-default-tooltip{
        background-color: #28364d;
    }
    & .x-text{
        font-size: 12px;
    }
`;

const TooltipWrap = styled.div`
    background-color: #fff;
    padding: 8px;
    border: 1px solid #ddd;
`;

const TooltipItemWrap = styled(Row)`
    margin-top: 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
`;
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import LeftNavBar from '../../common/part/LeftNavBar';
import { Box, Button, Grid, TableRow, Tooltip } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { useNavigate, useParams } from 'react-router';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import BannerAppVideoListPart from '../part/BannerAppVideoListPart';
import ListPart from '../../common/part/ListPart';
import TableContentItem from '../../common/item/TableContentItem';
import { apiGetVideoList, apiSetVideoUpdate } from '../../../api/apiStory';
import { APP_MAIN_VIDEO } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import { AdminWrap, TextItem } from '../../../style/basic/commonStyle';

export default function BannerAppVideoPage() {
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const pageTitle = '앱 메인 동영상';
    const {page} = useParams();
    let nowPage = Number(page) ?? 1;

    const [total,setTotal] = useState(1);
    const [list,setList] = useState<any>([]);
    const [newListState,setNewListState] = useState(false);

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,nowPage]);

    // 리스트
    const getList = async() =>{
        const res = await apiGetVideoList(userInfo.jwt_token,userInfo.admin_id,nowPage,10);
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 등록 해제
    const delAction = async(sns_id:any) =>{
        if(window.confirm('메인에서 해제하시겠습니까?')){
            const res = await apiSetVideoUpdate(userInfo.jwt_token,userInfo.admin_id,sns_id,'0');
            
            if(res.result){
                alert('해제되었습니다.');
                getList();
                setNewListState(true);
            }
        }
    }

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={5} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'유튜브 리스트'} />
                        <BannerAppVideoListPart mainList={getList} newListState={newListState} setNewListState={setNewListState} />
                    </AdminWrap>
                </Grid>
                <Grid item md={7} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'앱 메인 동영상 리스트'}></TableWrapTitleItem>
                        <ListPart titleArr={APP_MAIN_VIDEO} minWidth={800} nowPage={nowPage} total={total} link={'bannerAppVideo'}>
                            {list === undefined || list.length === 0 ?
                                <TableNoItem colSpan={APP_MAIN_VIDEO.length}></TableNoItem> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <TableRow key={`tableContent-${index}`}>
                                            <TableContentItem text={data.sns_id} />
                                            <TableContentItem>
                                                <img alt='user-profile' src={`https://img.youtube.com/vi/${data.sns_youtube_video_id}/0.jpg`}></img>
                                            </TableContentItem>
                                            <TableContentItem>
                                                <Tooltip title={data.sns_title} arrow={true}>
                                                    <TextItem line={1}>{data.sns_title}</TextItem>
                                                </Tooltip>
                                            </TableContentItem>
                                            <TableContentItem>
                                                <Tooltip title={data.sns_description} arrow={true}>
                                                    <TextItem line={2}>{data.sns_description}</TextItem>
                                                </Tooltip>
                                            </TableContentItem>
                                            <TableContentItem>
                                                <Button variant='contained' onClick={()=>delAction(data.sns_id)} color='error'>메인 해제</Button>
                                            </TableContentItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

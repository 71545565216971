import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import InputItem from '../../common/item/InputItem';
import SelectItem from '../../common/item/SelectItem';
import BasicText from '../../basic/BasicText';
import { REWARD_SETTING_SEND_TYPE, apiSetRewardSettingCreate, apiSetRewardSettingUpdate } from '../../../api/apiReward';
import { Button, MenuItem } from '@mui/material';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

export default function RewardSettingPart({selectItem,setSelectItem,setNewList}:any) {
    const userInfo = useUserInfo();
    const [code,setCode] = useState('');
    const [name,setName] = useState('');
    const [amount,setAmount] = useState(0);
    const [sendType,setSendType] = useState<'1'|'2'>('1');
    const [boxType,setBoxType] = useState('2');

    useEffect(()=>{
        if(selectItem === undefined){
            reset();
        }else{
            setItem();
        }
    },[selectItem])

    const reset = () =>{
        setCode('');
        setName('');
        setAmount(0);
        setSendType('1');
    }

    const setItem = () =>{
        setCode(selectItem.reward_config_code);
        setName(selectItem.reward_config_name);
        setAmount(selectItem.reward_config_amount);
        setSendType(selectItem.reward_config_process)
    }

    // 생성
    const createClick = async() =>{
        if(code === '' || name === '' ) {
            alert('코드와 네임을 입력해주세요.');
            return;
        }
        if(sendType === '1'){
            if( amount === 0 || amount > 500){
                alert('수량은 500 이 최대입니다.');
                return
            }
        }
        if(sendType === '2'){
            if( amount === 0 || amount > 10){
                alert('수량은 10 이 최대입니다.');
                return
            }
        }
        const res = await apiSetRewardSettingCreate(userInfo.jwt_token,userInfo.admin_id,code,name,amount,sendType);
        
        if(res.result){
            alert('생성되었습니다.');
            reset();
            setNewList((bol:boolean) => !bol);
        }else{
            alert(res.error);
        }
    }

    // 수정
    const updateClick = async() =>{
        if(code === '' || name === '' ) {
            alert('코드와 네임을 입력해주세요.');
            return;
        }
        if(sendType === '1'){
            if( amount === 0 || amount > 500){
                alert('수량은 500 이 최대입니다.');
                return
            }
        }
        if(sendType === '2'){
            if( amount === 0 || amount > 10){
                alert('수량은 10 이 최대입니다.');
                return
            }
        }
        const res = await apiSetRewardSettingUpdate(userInfo.jwt_token,userInfo.admin_id,selectItem.reward_config_id,code,name,amount,sendType);
        
        if(res.result){
            alert('수정되었습니다.');
            cancelClick();
            setNewList((bol:boolean) => !bol);
        }else{
            alert(res.error);
        }
    }

    // 취소
    const cancelClick = () =>{
        setSelectItem(undefined);
    }
    
    return (
        <Wrap>
            <InputWrap>
                <InputItem label='코드' inputLabel='none' value={code} valueChange={setCode}/>
                <InputItem label='네임' inputLabel='none' value={name} valueChange={setName}/>
                <InputItem label='수량' inputLabel='none' value={amount} valueChange={setAmount} type={'number'}/>
                <InputItemWrap>
                    <BasicText marginBottom={1}>지급방식</BasicText>
                    <SelectItem value={sendType} setValue={setSendType}>
                        {REWARD_SETTING_SEND_TYPE.map((data,index)=>{
                            return(
                                <MenuItem key={`send-type-${index}`} value={String(index+1)}>{data}</MenuItem>
                            )
                        })}
                    </SelectItem>
                </InputItemWrap>
            </InputWrap>
            <BtnWrap>
                {selectItem === undefined ?
                    <Button variant='contained' onClick={createClick}>생성</Button> :
                    <>
                        <Button variant='contained' onClick={updateClick}>수정</Button>
                        <Button variant='contained' color='error' onClick={cancelClick}>취소</Button>
                    </>
                }
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InputWrap = styled(Row)`
    width: auto;
    align-items: stretch;
    gap: 10px;
    flex-direction: column;
`;

const InputItemWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
`;

const BtnWrap = styled(Center)`
    margin-top: 20px;
    gap: 20px;
`;
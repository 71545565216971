import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { apiGetSearchUserList, apiGetUserList, apiSetMainUser } from '../../../api/apiUser';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import ListPart from '../../common/part/ListPart';
import TableNoItem from '../../common/item/TableNoItem';
import { Button, TableRow } from '@mui/material';
import TableContentItem from '../../common/item/TableContentItem';
import InputItem from '../../common/item/InputItem';
import SearchPart from '../../common/part/SearchPart';
import { Row, SearchWrap } from '../../../style/basic/commonStyle';

const TITLE = [
    {name:'#',size:20},
    {name:'프로필',size:20},
    {name:'닉네임',size:30},
    {name:'',size:30},
]

export default function BannerAppMainUserListPart({mainUser,newListState,setNewListState}:any) {
    const userInfo = useUserInfo();
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(1);

    const [search,setSearch] = useState('');

    useEffect(()=>{
        if(newListState){
            getList();
            setNewListState(false);
        }
    },[newListState])

    useEffect(()=>{
        if(search === ''){
            getList();
        }else{
            getSearchList();
        }
    },[page,search])

    // 유저 리스트
    const getList = async() =>{
        const res = await apiGetUserList(userInfo.jwt_token,userInfo.admin_id,page,'1','');
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 검색 유저리스트
    const getSearchList = async() =>{
        const res = await apiGetSearchUserList(userInfo.jwt_token,userInfo.admin_id,page,search);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 등록 해제
    const userMainUpdate = async(user_id:any,is_main_artist:any) =>{
        let main = is_main_artist === '0' ? '1' : '0';
        let resStr = is_main_artist === '0' ? '등록' : '해제';
        if(window.confirm(`${resStr} 하시겠습니까?`)){
            const res = await apiSetMainUser(userInfo.jwt_token,userInfo.admin_id,user_id,main);
            if(res.result){
                alert(`${resStr}되었습니다.`);
                getList();
                mainUser();
            }
        }
    }

    // 검색
    const searchAction = (sc:string) => {
        setPage(1);
        setSearch(sc);
    }

    return (
        <Wrap>
            <SearchWrap style={{marginBottom:'15px'}}>
                <SearchPart setRes={searchAction} />
            </SearchWrap>
            <ListPart titleArr={TITLE} minWidth={450} nowPage={page} total={total} setPage={setPage}>
                {list === undefined || list.length === 0 ?
                    <TableNoItem colSpan={TITLE.length}></TableNoItem> :
                    list.map((data:any,index:any)=>{
                        return(
                            <TableRow key={`tableContent-${index}`}>
                                <TableContentItem text={data.user_id} />
                                <TableContentItem>
                                    <img alt='user-profile' src={data.user_profile}></img>
                                </TableContentItem>
                                <TableContentItem text={data.user_nickname} />
                                {/* <TableContentItem text={data.profile_reg_timestamp} type='time' /> */}
                                <TableContentItem>
                                    <Button onClick={()=>{userMainUpdate(data.user_id,data.is_main_artist)}} variant='contained' color={data?.is_main_artist === '0' ? 'info' : 'error'}>
                                        {data?.is_main_artist === '0' ? '메인 등록' : '메인 해제'}
                                    </Button>
                                </TableContentItem>
                            </TableRow>
                        )
                    })
                }
            </ListPart>
        </Wrap>
    )
}

const Wrap = styled.div``;
// const SearchWrap = styled(Row)`
//     padding: 10px 0;
//     justify-content: end;
// `;
import styled from '@emotion/styled'
import React from 'react'
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import TitleRightBtnItem from '../item/TitleRightBtnItem';

export default function VerticalScroll_001({data}:any) {
    const {header, container, item} = data;
    
    return (
        <Wrap styles={container?.styles && container?.styles} custom_styles={container?.custom_styles ? container?.custom_styles : ''}>
            <Header>
                <BasicText theme={header?.title?.styles?.theme} fontSize={header?.title?.styles?.fontSize} bold={header?.title?.styles?.bold} custom_styles={header?.title?.custom_styles ?? ''}>{header?.title?.text}</BasicText>
                {header?.right_btn && 
                    <TitleRightBtnItem name={header?.right_btn?.icon_name} theme={header?.right_btn?.styles?.color} size={header?.right_btn?.styles?.size} />
                }
            </Header>
            <ListWrap>
                {item &&
                    item.list.map((data:any,index:any)=>{
                        return(
                            <Item 
                                key={`list-item-${index}`}
                                styles={item?.styles && item?.styles} 
                                custom_styles={item?.custom_styles ? item?.custom_styles : ''}    
                            >
                                <BasicImage>
                                    <Img alt='list-icon' src={data?.item_url} img_styles={item?.img_styles ? item?.img_styles : ''} />
                                    <div>
                                        <BasicText theme="white" fontSize={15} bold>{data.item_main_text}</BasicText>
                                        <BasicText theme="lightgray" fontSize={12}>{data.item_main_text}</BasicText>
                                    </div>
                                </BasicImage>
                            </Item>
                        )
                    })
                }
            </ListWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{styles?: any, custom_styles?: any}>`
    padding: ${props => props?.styles?.padding ?? 0}px;
    ${props => props.custom_styles};
`;

const Header = styled(RowBetween)`
    align-items: center;
`;
const ListWrap = styled.div``;

const Item = styled.div<{styles: any, custom_styles?: any}>`
    border-radius: ${props => props?.styles?.radius ?? 0}px;
    ${props => props.custom_styles};
`; 

const BasicImage = styled(Row)``;

const Img = styled.img<{img_styles?:any}>`
    ${props => props.img_styles}
`;
import { Grid, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import TableContentItem from '../../common/item/TableContentItem'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import LeftNavBar from '../../common/part/LeftNavBar'
import { POINT_LIST_TITLE } from '../../../data/tableTitle'
import { useParams } from 'react-router'
import ListPart from '../../common/part/ListPart'
import TableNoItem from '../../common/item/TableNoItem'
import { numberWithComma } from '../../../utils/format/number'
import { AdminWrap, TextItem } from '../../../style/basic/commonStyle'
import PieChartPart from '../../common/part/PieChartPart'

const DUMMY = [
    {id:1,user_nick:'닉네임1',user_code:'3edfg2dv',point:400,type:'후원',time:1691720585003},
    {id:2,user_nick:'닉네임2',user_code:'8r7d44eff',point:1400,type:'상품 구매',time:1691720585003},
]

export default function UserPointPage() {
    const pageTitle = '회원 포인트 리스트';
    const {page} = useParams();
    let nowPage = Number(page) ?? 1;
    const [list,setList] = useState(DUMMY);
    const [total,setTotal] = useState(1);

    useEffect(()=>{
        getList();
    },[nowPage])

    // 유저 리스트 api
    const getList = async() =>{

    }

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={4} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'총 포인트'}></TableWrapTitleItem>
                        <TextItem textAlign='right' fontSize={29} fw={700}>{numberWithComma(15000)}</TextItem>
                        <PieChartPart 
                            data={[{id:1,value:10000,label:'미사용'},{id:2,value:5000,label:'사용'}]} palette={['',]}
                        ></PieChartPart>
                    </AdminWrap>
                </Grid>
                <Grid item md={4} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'총 박스'}></TableWrapTitleItem>
                        <TextItem textAlign='right' fontSize={29} fw={700}>{numberWithComma(86)}</TextItem>
                        <PieChartPart data={[{id:1,value:40,label:'미사용'},{id:2,value:46,label:'사용'}]}></PieChartPart>
                    </AdminWrap>
                </Grid>
            </Grid>
            <AdminWrap style={{marginTop:'25px'}}>
                <TableWrapTitleItem title={pageTitle}></TableWrapTitleItem>
                <ListPart titleArr={POINT_LIST_TITLE} minWidth={850} nowPage={nowPage} total={total} link={'userPoint'}>
                    {list.length === 0 || list[0].id === 0 ?
                        <TableNoItem colSpan={POINT_LIST_TITLE.length}></TableNoItem> :
                        list?.map((data,index:number)=>{
                            return(
                                <TableRow
                                    key={`tableContent-${index}`}
                                >
                                    <TableContentItem text={data.id}></TableContentItem>
                                    <TableContentItem text={`${data.user_nick}(${data.user_code})`}></TableContentItem>
                                    <TableContentItem text={numberWithComma(data.point)}></TableContentItem>
                                    <TableContentItem text={data.type}></TableContentItem>
                                    <TableContentItem text={data.time} type='time'></TableContentItem>
                                </TableRow>
                            )
                        })
                    }
                </ListPart>
            </AdminWrap>
        </>
    )
}


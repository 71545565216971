import React,{useEffect,useState} from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import {Grid, Box, TableRow, Tooltip, Button} from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { useParams } from 'react-router';
import ListPart from '../../common/part/ListPart';
import { STORY_LIST_TITLE } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import TableContentItem from '../../common/item/TableContentItem';
import { AdminWrap, Row, TextItem } from '../../../style/basic/commonStyle';
import { apiGetYouList } from '../../../api/apiMusic';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

const DUMMY = [
    {
        id:1,
        user_nick:'테스트',
        user_code:'suejm3j5d',
        sns_type:'1',
        sns_content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        sns_time:1691720585003,
        sns_report:4,
    },
    {
        id:2,
        user_nick:'테스트2',
        user_code:'cg2rm3j5d',
        sns_type:'2',
        sns_content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        sns_time:1691720585003,
        sns_report:0,
    },
]

export default function StoryListPage() {
    const pageTitle = 'Story 리스트';
    const {page} = useParams();
    let nowPage = Number(page) ?? 1;
    const [total,setTotal] = useState(1);
    const [list,setList] = useState<any>([]);
    const userInfo = useUserInfo();

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,nowPage]);

    // 리스트
    const getList = async() =>{
        const res = await apiGetYouList(userInfo.jwt_token,userInfo.admin_id,nowPage,'1','1','all','');
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 삭제
    const delAction = async(id:number) =>{
        if(window.confirm('삭제 하시겠습니까?')){
            
        }
    }

    return (
        <>
            <AdminWrap>
                <TableWrapTitleItem title={pageTitle}></TableWrapTitleItem>
                <ListPart titleArr={STORY_LIST_TITLE} minWidth={950} nowPage={nowPage} total={total} link={'story'}>
                    {list === undefined || list.length === 0 ?
                        <TableNoItem colSpan={STORY_LIST_TITLE.length}></TableNoItem> :
                        list?.map((data:any,index:number)=>{
                            return(
                                <TableRow
                                    key={`tableContent-${index}`}
                                >
                                    <TableContentItem text={data.sns_id}></TableContentItem>
                                    <TableContentItem text={data.user_id}></TableContentItem>
                                    <TableContentItem 
                                        text={
                                            data.sns_type === '1' ? '텍스트' 
                                            : data.sns_type === '2' ? '이미지' 
                                            : data.sns_type === '3' ? '비디오'
                                            : data.sns_type === '4' ? '오디오'
                                            : '유튜브'
                                        }
                                        ></TableContentItem>
                                    <TableContentItem>
                                        <Tooltip title={data.sns_title} arrow={true}>
                                            <TextItem line={3}>{data.sns_title}</TextItem>
                                        </Tooltip>
                                    </TableContentItem>
                                    <TableContentItem>
                                    {data.sns_type === '2' ?
                                            data.images === undefined || data.images.length === 0 ?
                                                <></>:
                                                <>
                                                    <Row style={{gap:'5px'}}>
                                                        {data.images.map((item:any,idx:any)=>{
                                                            return(
                                                                <img style={{width: data.images.length === 1 ? 'auto' :`calc(${100 / data.images.length}% - 5px)`,maxHeight:'100%'}} src={item} key={`${data.sns_id}-${idx}`}></img>
                                                            )
                                                        })}
                                                    </Row>
                                                    <Tooltip title={data.sns_description} arrow={true}>
                                                        <TextItem line={1} marginTop={8}>{data.sns_description}</TextItem>
                                                    </Tooltip>
                                                </>
                                            :
                                            <Tooltip title={data.sns_description} arrow={true}>
                                                <TextItem line={3}>{data.sns_description}</TextItem>
                                            </Tooltip>
                                        }
                                    </TableContentItem>
                                    <TableContentItem text={data.sns_timestamp} type='time'></TableContentItem>
                                    <TableContentItem text={data.like_cnt}></TableContentItem>
                                    <TableContentItem>
                                        {/* <Button onClick={()=>delAction(data.sns_id)} variant='contained' color='error'>삭제</Button> */}
                                    </TableContentItem>
                                </TableRow>
                            )
                        })
                    }
                </ListPart>
            </AdminWrap>
        </>
    )
}

import React,{useEffect, useState} from 'react'
import LeftNavBar from '../../common/part/LeftNavBar';
import { Box, Button, Grid, MenuItem, TableRow } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { AdminWrap, Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import InputItem from '../../common/item/InputItem';
import styled from 'styled-components';
import { useParams } from 'react-router';
import ListPart from '../../common/part/ListPart';
import { APP_PUSH_AUTO_TITLE } from '../../../data/tableTitle';
import TableContentItem from '../../common/item/TableContentItem';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import TableNoItem from '../../common/item/TableNoItem';
import BasicText from '../../basic/BasicText';
import SelectItem from '../../common/item/SelectItem';
import { apiGetAutoPushList, apiSetAutoPush, apiSettDelAutoPush } from '../../../api/apiSetting';

// 타겟 1 전체 / 2 회원 / 3 비회원
const TARGET = ['전체','회원','비회원'];
const TYPE = ['자동','예약'];
export default function AppPushAutoPage() {
    const userInfo = useUserInfo();
    const pageTitle = '앱 푸시 알림(자동)';
    const today = new Date().toISOString().slice(0, 16);
    const [autoList,setAutoList] = useState<any>([]);
    
    const [type,setType] = useState(0);
    const [target,setTarget] = useState('');
    const [title,setTitle] = useState('');
    const [content,setContent] = useState('');
    const [imgUrl,setImgUrl] = useState('');
    const [action,setAction] = useState('');
    
    const [navigator,setNavigator] = useState('');
    const [screen,setScreen] = useState('');
    const [tab,setTab] = useState('');

    const [webUrl,setWebUrl] = useState('');
    const [date,setDate] = useState('');
    const [time,setTime] = useState('');

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getAutoList();
    },[userInfo.jwt_token])

    const getAutoList = async() =>{
        const res = await apiGetAutoPushList(userInfo.jwt_token,userInfo.admin_id);
        
        if(res.result){
            setAutoList(res.data);
        }else{
            setAutoList([]);
        }
    }

    const reset = () =>{
        setTitle('');
        setContent('');
        setImgUrl('');
        setAction('');
        setNavigator('');
        setScreen('');
        setTab('');
        setWebUrl('');
        setDate('');
        setTime('');
    }

    const timeToArr = () =>{
        if(date === '') return [];
        const dateArr = date.split('T');
        const date1 = dateArr[0].split('-');
        const date1Res = date1.map((item,index) => (index === 1 ? (Number(item) - 1).toString() : item));
        const date2 = dateArr[1].split(':');
        const dateRes = [...date1Res, ...date2];
        return dateRes;
    }

    // 푸시알림 등록
    const pushAdd = async() =>{
        // if(target === ''){
        //     alert('푸시알림을 받을 대상을 선택해주세요.');
        //     return;
        // }
        
        if(title === ''){
            alert('제목을 입력해주세요.');
            return;
        }

        if(content === ''){
            alert('내용을 입력해주세요.');
            return;
        }
        let data = {
            action,
            screen,
            navigator,
            tab,
            webview_url:webUrl,
        }

        let dateRes = timeToArr();
        if(dateRes.length < 5){
            alert('날짜를 선택해주세요.');
            return;
        }
        const typeNum = Number(type) + 1;
        const res = await apiSetAutoPush(userInfo.jwt_token,userInfo.admin_id,title,content,data,imgUrl,dateRes,typeNum);
        if(res.result){
            alert('등록되었습니다.');
            reset();
            getAutoList();
        }else{
            console.log(res.error);
        }
    }

    // 삭제
    const delClick = async(uuid:any) =>{
        if(window.confirm('삭제 하시겠습니까?')){
            const res = await apiSettDelAutoPush(userInfo.jwt_token,userInfo.admin_id,uuid);
            if(res.result){
                alert('삭제 되었습니다.');
                getAutoList();
            }else{
                console.log(res.error);
            }
        }
    }


    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'자동 푸시알림 등록'} />
                        {/* <AppPushTargetPart target={target} setTarget={setTarget}></AppPushTargetPart> */}
                        <InputWrap>
                            <BasicText marginBottom={1}>타입</BasicText>
                            <SelectItem value={type} setValue={setType} sx={{width:'100px'}}>
                                {TYPE.map((data,index)=>{
                                    return(
                                        <MenuItem key={`type-${index}`} value={index}>{data}</MenuItem>
                                    )
                                })}
                            </SelectItem>
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='제목' inputLabel='none' inputSx={{width:'100%'}} value={title} valueChange={setTitle} />
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='내용' inputLabel='none' inputSx={{width:'100%'}} value={content} valueChange={setContent} />
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='이미지URL' inputLabel='none' inputSx={{width:'100%'}} value={imgUrl} valueChange={setImgUrl} />
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='액션' inputLabel='none' inputSx={{width:'100%'}} value={action} valueChange={setAction} />
                        </InputWrap>
                        <InputWrap>
                            <RowBetween>
                                <InputItem label='네비게이터' inputLabel='none' inputSx={{width:'100%'}} value={navigator} valueChange={setNavigator} />
                                <InputItem label='스크린' inputLabel='none' inputSx={{width:'100%'}} value={screen} valueChange={setScreen} />
                                <InputItem label='탭' inputLabel='none' inputSx={{width:'100%'}} value={tab} valueChange={setTab} />
                            </RowBetween>
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='웹뷰링크' inputLabel='none' inputSx={{width:'100%'}} value={webUrl} valueChange={setWebUrl} />
                        </InputWrap>
                        <InputWrap>
                            <InputItem type='datetime-local' label='날짜' inputLabel='none' value={date} valueChange={setDate} inputProps={{min:today}} />
                        </InputWrap>
                        <BtnWrap>
                            <Button variant='contained' onClick={pushAdd}>등록</Button>
                        </BtnWrap>
                    </AdminWrap>
                </Grid>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'자동 리스트'} />
                        <ListPart titleArr={APP_PUSH_AUTO_TITLE} minWidth={950} >
                            {autoList === undefined || autoList.length === 0 ?
                                <TableNoItem colSpan={APP_PUSH_AUTO_TITLE.length} /> :
                                autoList.map((data:any,index:any)=>{
                                    const typeStr = TYPE[Number(data.push_type) - 1];
                                    const sendData = JSON.stringify(data.push_send_data);
                                    return(
                                        <TableRow key={`autoList-${index}`}>
                                            <TableContentItem text={typeStr} />
                                            <TableContentItem text={data.push_title} />
                                            <TableContentItem text={data.push_content} />
                                            <TableContentItem text={sendData} />
                                            <TableContentItem text={data.push_image_url} />
                                            <TableContentItem>
                                                <Button variant='contained' color='error' onClick={()=>delClick(data.push_uuid)}>삭제</Button>
                                            </TableContentItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

const InputWrap = styled.div`
    margin-top: 15px;
`;

const BtnWrap = styled(Center)`
    margin-top: 30px;
`;
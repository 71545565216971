import { axiosConfigInstance } from "./config";

// 지급방법
export const REWARD_SETTING_SEND_TYPE = ['포인트','박스'];

// 리워드 설정 리스트
export const apiGetRewardSettingList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/reward',token).post('',{
            method: 'adminGetRewardConfigList',
            params: {
                admin_id,
                page,
                count,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리워드 수정
// reward_config_process - 지급 방법 1: 포인트 지급 / 2: 박스 지급
export const apiSetRewardSettingUpdate = async(token:string,admin_id:any,reward_config_id:any,reward_config_code:any,reward_config_name:any,reward_config_amount:any,reward_config_process:'1'|'2') =>{
    try {
        const res = await axiosConfigInstance('/admin/reward',token).post('',{
            method: 'adminRewardConfigEdit',
            params: {
                admin_id,
                reward_config_id,
                reward_config_code,
                reward_config_name,
                reward_config_amount,
                reward_config_process,
                reward_config_goods_code: '2',
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리워드 삭제
export const apiSetRewardSettingDel = async(token:string,admin_id:any,reward_config_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/reward',token).post('',{
            method: 'adminRewardConfigDelete',
            params: {
                admin_id,
                reward_config_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리워드 생성
export const apiSetRewardSettingCreate = async(token:string,admin_id:any,reward_config_code:any,reward_config_name:any,reward_config_amount:any,reward_config_process:'1'|'2') =>{
    try {
        const res = await axiosConfigInstance('/admin/reward',token).post('',{
            method: 'adminRewardConfigAdd',
            params: {
                admin_id,
                reward_config_code,
                reward_config_name,
                reward_config_amount,
                reward_config_process,
                reward_config_goods_code: '2',
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리워드 전송
export const apiSetRewardSend = async(token:string,admin_id:any,reward_config_code:any,target_user_list:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/reward',token).post('',{
            method: 'adminSendReward',
            params: {
                admin_id,
                reward_config_code,
                target_user_list,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
} 
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import AppInputItem from '../item/AppInputItem';
import UiBtnItem from './item/UiBtnItem';

export default function GoogleAdBanner_001_input({data,update,index}:any) {
    const [paddingTop,setPaddingTop] = useState('');
    const [paddingBottom,setPaddingBottom] = useState('');

    useEffect(()=>{
        if(data === undefined) return;
        setPaddingTop(data.styles.paddingTop);
        setPaddingBottom(data.styles.paddingBottom);
    },[data])

    const updateEvent = () =>{
        const newStyle = data;
        newStyle.styles.paddingBottom = paddingBottom;
        newStyle.styles.paddingTop = paddingTop;
        update(newStyle,index);
    }
    
    return (
        <Wrap>
            <ItemWrap>
                <TitleWrap>type</TitleWrap>
                <ContentWrap>
                    {data.type}
                </ContentWrap>
            </ItemWrap>
            <ItemWrap>
                <TitleWrap>styles</TitleWrap>
                <ContentWrap>
                    <Row>
                        <ContentTitle>paddingTop:</ContentTitle>
                        <AppInputItem type='number' value={paddingTop} valueChange={setPaddingTop} />
                    </Row>
                    <Row>
                        <ContentTitle>paddingBottom:</ContentTitle>
                        <AppInputItem type='number' value={paddingBottom} valueChange={setPaddingBottom} />
                    </Row>
                </ContentWrap>
            </ItemWrap>
            <UiBtnItem updateEvent={updateEvent} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const ItemWrap = styled(Row)`
    align-items: start;
`;
const TitleWrap = styled.div`
    width: 20%;
    font-weight: 700;
`;
const ContentWrap = styled.div`
    &>div{
        margin-bottom: 4px;
    }
`;

const ContentTitle = styled.div`
    margin-right: 4px;
`;
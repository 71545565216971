import React,{useEffect, useState} from 'react'
import LeftNavBar from '../../common/part/LeftNavBar';
import { Box, Button, Grid } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { AdminWrap, Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import InputItem from '../../common/item/InputItem';
import styled from 'styled-components';
import { apiGetVersion, apiSetTestVersionState, apiSetVersion } from '../../../api/apiSetting';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

export default function AppVersionPage() {
    const pageTitle = '앱 버전';
    const user = useUserInfo();
    const [andVersion,setAndVersion] = useState('');
    const [iosVersion,setIosVersion] = useState('');

    const [andAudit,setAndAudit] = useState(false);
    const [iosAudit,setIosAudit] = useState(false);

    const [andAuditVs,setAndAuditVs] = useState('');
    const [iosAuditVs,setIosAuditVs] = useState('');

    const [nowAnd,setNowAnd] = useState('');
    const [nowIos,setNowIos] = useState('');

    const [updateChk,setUpdateChk] = useState(false);

    useEffect(()=>{
        if(user.jwt_token === '') return;
        getVersion();
    },[user.jwt_token,updateChk])

    const getVersion = async() => {
        const res = await apiGetVersion(user.jwt_token,user.admin_id);
        
        if(res.result){
            setNowAnd(res.data.android_version);
            setAndVersion(res.data.android_version);
            setAndAuditVs(res.data.android_version_test);
            setNowIos(res.data.ios_version);
            setIosVersion(res.data.ios_version);
            setIosAuditVs(res.data.ios_version_test)
            if(res.data.is_android_testing == '1'){
                setAndAudit(true);
            }else{
                setAndAudit(false);
            }

            if(res.data.is_ios_testing == '1'){
                setIosAudit(true);
            }else{
                setIosAudit(false);
            }
        }
    }
    
    const valueChk = (value:any) =>{
        let arr = value.split('');
        let chkArr = arr.map((data:any)=>{
            let chk = isNaN(data);
            if(chk){
                if(data === '.'){
                    return true;
                }
                alert('글자를 입력할 수 없습니다.');
                return false
            }else{
                return true
            }
        })
        return chkArr.filter((data:any)=>data === false);
    }

    const and = (value:any) =>{
        const chk = valueChk(value);
        if(chk.length === 0){
            setAndVersion(value);
        }
    } 

    const andAuditVersion = (value:any) =>{
        const chk = valueChk(value);
        if(chk.length === 0){
            setAndAuditVs(value);
        }
    } 

    const ios = (value:any) =>{
        const chk = valueChk(value);
        if(chk.length === 0){
            setIosVersion(value);
        }
    }

    const iosAuditVersion = (value:any) =>{
        const chk = valueChk(value);
        if(chk.length === 0){
            setIosAuditVs(value);
        }
    } 

    const versionUpdate = async(type:string,version:string,test_version:string,) =>{
        const res = await apiSetVersion(user.jwt_token,user.admin_id,type,version,test_version);
        return res;
        
    }

    const andChange = async() =>{
        const res:any = await versionUpdate('1',andVersion,andAuditVs);
        if(res.result){
            alert('안드로이드 버전이 수정되었습니다.');
            setUpdateChk(!updateChk);
        }else{
            alert(res.error)
        }
    }

    const iosChange = async() =>{
        const res:any = await versionUpdate('2',iosVersion,iosAuditVs);
        if(res.result){
            alert('ios 버전이 수정되었습니다.');
            setUpdateChk(!updateChk);
        }else{
            alert(res.error)
        }
    }

    const stateToNum = (val:any) =>{
        let testState = '0';
        if(val){
            testState = '1';
        }else{
            testState = '0';
        }
        return testState;
    }

    const andTestStateChange = async(val:any) =>{
        const testState = stateToNum(val);
        setAndAudit(val);
        await apiSetTestVersionState(user.jwt_token,user.admin_id,"1",testState);
    }
    const iosTestStateChange = async(val:any) =>{
        const testState = stateToNum(val);
        setIosAudit(val);
        await apiSetTestVersionState(user.jwt_token,user.admin_id,"2",testState);
    }
    
    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'안드로이드'}></TableWrapTitleItem>
                        <BasicText fontSize={18}>현재 버전 : {nowAnd}</BasicText>
                        <ChkWrap>
                            <input type='checkbox' checked={andAudit} onChange={(e:any)=> andTestStateChange(e.target.checked)} />
                            심사중
                        </ChkWrap>
                        <InputWrap>
                            <InputItem label='변경할 버전' inputLabel='none' value={andVersion} valueChange={and} />
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='심사중 버전' inputLabel='none' value={andAuditVs} valueChange={andAuditVersion} />
                        </InputWrap>
                        <BtnWrap>
                            <Button variant='contained' onClick={andChange}>변경</Button>
                        </BtnWrap>
                    </AdminWrap>
                </Grid>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'IOS'}></TableWrapTitleItem>
                        <BasicText fontSize={18}>현재 버전 : {nowIos}</BasicText>
                        <ChkWrap>
                            <input type='checkbox' checked={iosAudit} onChange={(e:any)=> iosTestStateChange(e.target.checked)} />
                            심사중
                        </ChkWrap>
                        <InputWrap>
                            <InputItem label='변경할 버전' inputLabel='none' value={iosVersion} valueChange={ios}></InputItem>
                        </InputWrap>
                        <InputWrap>
                            <InputItem label='심사중 버전' inputLabel='none' value={iosAuditVs} valueChange={iosAuditVersion}></InputItem>
                        </InputWrap>
                        <BtnWrap>
                            <Button variant='contained' onClick={iosChange}>변경</Button>
                        </BtnWrap>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

const InputWrap = styled.div`
    margin-top: 10px;
`;

const BtnWrap = styled(Center)`
    margin-top: 30px;
`;

const ChkWrap = styled.label`
    font-size: 16px;
    margin-top: 10px;
    display: block;
    & input{
        margin-right: 6px;
    }
`;
export interface ThemeProps {
    theme: 'dark' | 'bright' | 'red'
}

export const theme = (theme: string) => {
    const themeTotal = {
        'dark': {
            fixblack: '#000',
            fixwhite: '#FFF',
            black: '#000',
            white: '#fff',
            red: 'red',
            lightRed: '#f2bdae',
            signalRed: '#eb493d',
            blue: '#0071BC',
            lightBlue: '#aac5f0',
            signalBlue: '#4590e6',
            gray: 'gray',
            main: '#717fbf',
            submain: '#ebd0f5',
            lightmain: '#d7e5f5',
            more: '#969696',
            subtext: '#afb3c4',
            container: '#051226',
            navBar: '#000',
            actionSheetContainer: '#000',
            donateActionSheetContainer: '#000',
            actionSheetTop: '#000',
            svgcolor: '#000',
            skyblue : '#70a9bd',
            error: 'red',
            navBarContainer: '#000',
            cardContainer: '#0b192e',
            lightWhite: '#121e30',
            toastColor: '#b7d0f7',
            lightgray: '#cdcdcd',
        },
        'bright': {
            fixblack: '#000',
            fixwhite: '#FFF',
            black: '#fff',
            white: '#000',
            red: 'red',
            lightRed: '#f2bdae',
            signalRed: '#eb493d',
            blue: '#0071BC',
            lightBlue: '#aac5f0',
            signalBlue: '#4590e6',
            gray: 'gray',
            lightmain: '#d7e5f5',
            more: '#969696',
            subtext: '#afb3c4',
            container: '#fafafa',
            navBar: '#FFF',
            actionSheetContainer: '#fff',
            donateActionSheetContainer: '#FFF',
            actionSheetTop: '#FFF',
            svgcolor: '#FFF',
            skyblue : '#70a9bd',
            error: 'red',
            navBarContainer: '#FFF',
            cardContainer: '#fff',
            lightWhite: '#f5f5f5',
            toastColor: '#b7d0f7',
            main: 'rgba(0, 223, 255, 1.0)',
            // main: 'rgba(222, 4, 196, 1.0)',
            mainLight: 'rgba(0, 223, 255, 0.3)',
            submain: 'rgba(11, 160, 249, 1.0)',
            modalContainer: 'rgba(0, 0, 0, 0.8)',
            hover: 'rgba(0, 223, 255, 1.0)',
            state_green: ['rgba(135, 245, 66, 1.0)', 'rgba(113, 240, 34, 1.0)', 'rgba(135, 245, 66, 1.0)'],
            increase: '#1890ff',
            decrease: '#faad14',
            green: 'rgba(135, 245, 66, 1.0)'
        },
        'red': {
            fixblack: 'red',
            fixwhite: 'red',
            black: 'red',
            white: 'red',
            yellow: 'red',
            red: 'red',
            blue: '#0071BC',
            gray: 'gray',
            orange: '#F15A24',
            main: '#cf71f0',
            submain: '#ebd0f5',
            more: '#969696',
            subtext: '#afb3c4',
            artistname: '#eee',
            container: 'red',
            authcontainer: '#37C3E1',
            navBar: '#FFF',
            basicbutton: '#00FFFF',
            notReadNotification : '#daf5f5',
            playerContainer: '#FFF',
            playerActiveContainer: '#FFF',
            actionSheetContainer: '#FFF',
            donateActionSheetContainer: '#FFF',
            actionSheetTop: '#FFF',
            svgcolor: '#FFF',
            skyblue : '#70a9bd',
            textinputBorder: '#969696',
            error: 'red',
            chart1: 'blue',
            chart2: 'red',
            chart3: 'yellow',
            chart4: 'orange',
            border: '#000',
            bottomplayer: '#f5f5f5',
            stickyheader: '#fafafa',
            playingcolor: '#ebd0f5',
            shadowOuterColor: '#fff',
            shadowInnerDarkColor: '#f5f5f5',
            shadowInnerLightColor: '#fff',
            shadowButtonColor: '#f2ebf5',
            swipeBackgroudColor: 'rgba(0, 0, 0, 0.25)',
            textinputContainer: '#FFF',
            navBarContainer: 'red'
        }
    };
    if(theme === 'dark'){
        return themeTotal.dark;
    } else if (theme === 'bright'){
        return themeTotal.bright;
    } else {
        return themeTotal.dark;
    }
};

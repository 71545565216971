import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import InputItem from '../../common/item/InputItem';

export default function TermsContentPart({title,setTitle,content,setContent}:any) {
    return (
        <Wrap>
            <BasicText marginBottom={1}>제목</BasicText>
            <InputItem value={title} valueChange={setTitle} inputProps={{readOnly:true}} />
            <BasicText marginTop={2} marginBottom={1}>내용</BasicText>
            <textarea rows={30} value={content} onChange={(e:any)=>setContent(e.target.value)}></textarea>
        </Wrap>
    )
}

const Wrap = styled.div`
    & textarea{
        border: 1px solid #ddd;
        width: 100%;
        padding: 15px;
        border-radius: 4px;
    }
    & .MuiTextField-root{
        width: 100%;
    }
`;


import React from 'react'
import styled from 'styled-components';
import { Row } from '../../../style/basic/commonStyle';
import { Pagination } from '@mui/material';
import TablePart from './TablePart';
import TableNoItem from '../item/TableNoItem';
import { useNavigate } from 'react-router';

interface listPartType {
    titleArr: Array<any>;
    minWidth: number;
    children: any;
    nowPage: number;
    setPage?: any;
    total?: number;
    link?: string;
    searchRes?: string;
    titleChildren?: any;
}

export default function ListPart({titleArr,minWidth,children,nowPage,titleChildren,setPage,total,link,searchRes=''}:any) {
    
    const navigate = useNavigate();
    const pageChange = (page:any) =>{
        if(link === undefined || link === '') {
            if(setPage){
                setPage(page);
            }
            return
        };
        navigate(`/${link}/${page}/${searchRes}`);
    }
    
    return (
        <Wrap>
            <TablePart titleArr={titleArr} minWidth={minWidth} titleChildren={titleChildren}>
                {children === undefined ?
                    <TableNoItem colSpan={titleArr.length}></TableNoItem> :
                    children
                }
            </TablePart>
            <PaginationWrap>
                {total === undefined ? 
                    <></> :
                    <Pagination shape="rounded" page={nowPage} count={total} onChange={(e, page) => pageChange(page)}></Pagination>
                }
                {/* <Pagination shape="rounded" page={nowPage} count={total} onChange={(e, page) => pageChange(page)}></Pagination> */}
            </PaginationWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const PaginationWrap = styled(Row)`
    padding: 20px 0;
    justify-content: end;
`;
import { axiosConfigInstance } from "./config";

// 유저 디바이스
export const DEVICE = ['android','ios','web','exe'];
export const DEVICE_OBJ:any = {
    "1": "android",
    "2": "ios",
    "3": "web",
    "4": "exe",
}

// 유저 가입여부
export const MEMBER = ['비회원','회원'];

// 유저리스트 아이템
export const USER_ITEM =  {
    "user_id": 0,
    "user_address": "",
    "user_code": "",
    "user_parent_code": "",
    "user_nickname": "",
    "user_profile": "hdapi.subox.co.kr/user_profile/basic_profile.png",
    "user_device": "1",
    "user_country": "ko",
    "user_timestamp": "1685581133998",
    "user_block": "0",
    "user_membership": "1",
    "user_recent_timestamp": "1685581133998",
    "sound_count": 0
}

// 유저 리스트
export const apiGetUserList = async(token:string,admin_id:number,page:number,target_membership:string,target_device:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/user',token).post('',{
            method:'adminGetUserList',
            params:{
                admin_id: admin_id,
                page: page,
                count: 50,
                target_membership:target_membership,
                target_device:target_device,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 검색 유저 리스트
export const apiGetSearchUserList = async(token:string,admin_id:number,page:number,search_query:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/user',token).post('',{
            method:'adminSearchUserList',
            params:{
                admin_id,
                page,
                count: 50,
                search_query
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 유저 연동
export const apiGetUserUrl = async(token:string,admin_id:any,user_id:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/user',token).post('',{
            method: 'adminGetUserRestoreLink',
            params: {
                admin_id,
                user_id,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 유저 차단
export const apiSetUserBlock = async(userId:any,userBlock:any,token:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/user/client',token).post('',{
            method:'block',
            params:{
                userId,userBlock
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 유저 디테일
export const apiGetUserDetail = async(userId:any,token:string) =>{
    try {
        const res = await axiosConfigInstance('/admin/user/client',token).post('',{
            method:'detail',
            params:{
                userId
            }
        })
        return res.data;
    } catch (error) {
        return {
            result:false,
            error,
        }
    }
}

// 초대리스트 내역
export const apiGetInviteList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/user',token).post('',{
            method: 'adminGetUserInviteList',
            params: {
                admin_id,
                page,
                count,
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 메인 아티스트 등록,해제
export const apiSetMainUser = async(token:string,admin_id:any,user_id:any,is_main_artist:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/artist/support',token).post('',{
            method: 'admSetMainArtist',
            params: {
                admin_id,
                user_id,
                is_main_artist
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 메인 아티스트 리스트
export const apiGetMainUserList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/artist/support',token).post('',{
            method: 'admGetMainProfileList',
            params: {
                admin_id,
                page,
                count
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}
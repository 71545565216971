import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Grid, TableRow } from '@mui/material'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import { apiGetRewardList, apiSetReward } from '../../../api/apiAdmin'
import AdminRewardPart from '../part/AdminRewardPart'
import { AdminWrap } from '../../../style/basic/commonStyle'

export default function AdminRewardPage() {
    const TITLE = '관리자 리워드 설정'
    const userInfo = useUserInfo();
    const [list,setList] = useState<any>([]);

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token]);

    const getList = async() =>{
        const res = await apiGetRewardList(userInfo.jwt_token,userInfo.admin_id,1,30);
        if(res.result){
            setList(res.data);
        }else{
            setList([]);
        }
    }

    const clickEvent = async(code:any,type:any,amount:any) =>{
        const res = await apiSetReward(userInfo.jwt_token,userInfo.admin_id,code,type,amount);
        console.log(res);
        if(res.result){
            getList()
            alert('변경되었습니다');
        }
    }

    return (
        <>
            <AdminWrap>
                <TableWrapTitleItem title={TITLE} />
                {list === undefined || list.length === 0 ?
                    <></> :
                    <Grid container={true} spacing={2}>
                        {
                            list.map((data:any,index:any)=>{
                                return(
                                    <AdminRewardPart key={`reward-${index}`} data={data} clickEvent={clickEvent} />
                                )
                            })
                        }
                    </Grid>
                }
            </AdminWrap>
        </>
    )
}

import React from 'react'
import styled from 'styled-components'
import { FaChevronRight } from "react-icons/fa";
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';

export default function TitleRightBtnItem({name,theme,size}:any) {

    return (
        <Wrap>
            {name === "chevron-forward-outline" &&
                <FaChevronRight color={theme ?? '#fff'} size={size ?? '16px'} />
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 10px;
`;

import React,{useState,useEffect,useRef} from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { useNavigate, useParams } from 'react-router';
import InputItem from '../../common/item/InputItem';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import BasicText from '../../basic/BasicText';
import { AdminWrap, Center } from '../../../style/basic/commonStyle';
import { Box, Button, Grid, TableRow } from '@mui/material';
import { fileTypeRes } from '../../../utils/format/string';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import SearchIcon from '@mui/icons-material/Search';
import ListPart from '../../common/part/ListPart';
import { SEARCH_YOU_MUSIC_TITLE } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import TableContentItem from '../../common/item/TableContentItem';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { apiGetYouList, apiSGetYouListDetail, apiSetAddYouList, apiSetUpdateYouList, getMusicCateList } from '../../../api/apiMusic';
import MusicYoutubeListItem from '../item/MusicYoutubeListItem';

export default function MusicYouPlayListUpdatePage() {
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const {id} = useParams();
    const [pageTitle,setPageTitle] = useState('');
    
    const [title,setTitle] = useState('');
    const [content,setContent] = useState('');

    const [genreList,setGenreList] = useState<any>([]);
   
    const [musicList,setMusicList] = useState<any>([]);

    const [search,setSearch] = useState('');
    const [searchRes,setSearchRes] = useState('');
    const [searchList,setSearchList] = useState<any>([1]);
    const [searchPage,setSearchPage] = useState(1);
    const [searchTotal,setSearchTotal] = useState(0);

    const fileRef = useRef<any>(null);
    const [file,setFile] = useState(null);
    const [preview,setPreview] = useState('');

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getGenreList();
    },[userInfo.jwt_token])

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        setPageTitle(id === '0' ? '생성' : '수정');
        if(id !== '0'){
            getDetail()
        }
    },[userInfo.jwt_token,id]);

    // 검색
    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getMusicSearchList();
    },[userInfo.jwt_token,searchRes,searchPage]);

    // 디테일 - 수정일때
    const getDetail = async() =>{
        const res = await apiSGetYouListDetail(userInfo.jwt_token,userInfo.admin_id,id);
        if(res.result){
            const {info,list} = res.data;
            setTitle(info.y_playlist_name);
            setContent(info.y_playlist_description);
            setPreview(info.y_playlist_thumbnail);
            setMusicList(list);
        }else{
            alert(res.error)
        }
    }

    // 내용
    const contentChange = (value:any) =>{
        setContent(value);
    }

    // 장르 리스트
    const getGenreList = async() =>{
        const res = await getMusicCateList(userInfo.jwt_token,userInfo.admin_id,'1',1,100);
        if(res.result){
            const newList = res.data.filter((data:any)=> data.category_use === '1' || data.category_use === '3');
            setGenreList(newList);
        }else{
            setGenreList([]);
        }
    }

    // 리스트에서 음악 제거
    const musicDel = (id:any) =>{
        setMusicList((arr:any)=>arr.filter((data:any)=>data.sns_id != id));
    }

    // 리스트에 뮤직 추가
    const musicAdd = (data:any) =>{
        setMusicList(musicList.concat(data));
    }

    // 취소
    const cancel = () =>{
        navigate('/youPlaylist/1')
    }

    // 파일선택
    const fileSelect = (e:any) =>{
        const file = e.target.files[0];
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'png' || fileType === 'jpg'){
            const preImg = URL.createObjectURL(file);
            setFile(file);
            setPreview(preImg);
        }else{
            fileRef.current.value = null;
            setFile(null);
            setPreview('');
            alert('png 또는 jpg 파일을 선택해주세요.');
        }
    }

    // 음악 검색 리스트
    const getMusicSearchList = async() =>{
        const res = await apiGetYouList(userInfo.jwt_token,userInfo.admin_id,searchPage,"2","0","1",searchRes);
        
        if(res.result){
            setSearchList(res.data);
            setSearchTotal(res.total);
        }else{
            setSearchList([]);
            setSearchTotal(0);
        }
    }

    // 이미지 클릭 -> 유튜브 
    const imgClick = (videoId:any) =>{
        window.open(`https://www.youtube.com/watch?v=${videoId}`);
    }

    // 음악 검색
    const musicSearch = async(e:any) =>{
        e.preventDefault();
        setSearchRes(search);
        setSearchPage(1);
    }

    const btnAction = async() =>{
        if(title === ''){
            alert('제목을 입력해주세요.');
            return;
        }
        if(preview === ''){
            alert('썸네일를 선택해주세요');
            return;
        }
        if(musicList.length === 0){
            alert('음악을 선택해주세요.');
        }
        let newList:any = [];
        musicList.map((data:any)=>{
            newList.push({sns_id:data.sns_id,sns_youtube_video_id:data.sns_youtube_video_id});
        })
        if(pageTitle === '생성'){
            const res = await apiSetAddYouList(userInfo.jwt_token,userInfo.admin_id,title,content,newList,file);
            if(res.result){
                alert('생성 되었습니다.');
                window.location.reload();
            }else{
                alert(res.error);
            }
            return;
        }
        if(pageTitle === '수정'){
            const res = await apiSetUpdateYouList(userInfo.jwt_token,userInfo.admin_id,id,title,content,newList,file);
            if(res.result){
                alert('수정 되었습니다.');
                navigate(-1);
            }else{
                alert(res.error);
            }
            return;
        }
        
    }
    
    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={7} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'플레이 리스트'} />
                        <InputWrap>
                            <InputItem label={'제목'} inputLabel={'none'} value={title} valueChange={setTitle} inputSx={{width:'100%'}}></InputItem>
                        </InputWrap>
                        <InputWrap>
                            <BasicText marginBottom={1}>내용</BasicText>
                            <TextField
                                id="outlined-multiline-static"
                                label=""
                                multiline
                                rows={4}
                                value={content}
                                fullWidth
                                onChange={e=>contentChange(e.target.value)}
                            />
                        </InputWrap>
                        <InputWrap>
                            <InputItem label={'썸네일'} inputLabel={'none'} type='file' inputProps={{accept:'image/*'}} fileRef={fileRef} valueChange={fileSelect}></InputItem>
                            {preview !== '' &&
                                <PreviewImgWrap>
                                    <img alt='preview-img' src={preview} />
                                </PreviewImgWrap>
                            }
                        </InputWrap>
                        <MusicWrap>
                            <BasicText marginBottom={1}>리스트</BasicText>
                            <MusicListWrap>
                                {musicList === undefined || musicList.length === 0 ?
                                    <></> :
                                    musicList.map((data:any,index:any)=>{
                                        return(
                                            <MusicYoutubeListItem key={`music-item${index}`} title={data.sns_title} img={data.sns_youtube_video_id} clickEvent={()=>{musicDel(data.sns_id)}}></MusicYoutubeListItem>
                                        )
                                    })
                                }
                            </MusicListWrap>
                        </MusicWrap>
                        <BtnWrap>
                            <Button variant='contained' onClick={btnAction}>{pageTitle}</Button>
                            <Button variant='contained' color='error' onClick={cancel}>취소</Button>
                        </BtnWrap>
                    </AdminWrap>
                </Grid>
                <Grid item md={5} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'음악 리스트'} />
                        <SearchWrap onSubmit={musicSearch}>
                            <InputItem label={'음악검색'} inputLabel={'none'} value={search} valueChange={setSearch} inputSx={{width:'100%'}} />
                            <Button type='submit' variant='contained' sx={{width:'64px',height:'40px', borderRadius:'4px', flexShrink:0}} >
                                <SearchIcon />
                            </Button>
                        </SearchWrap>
                        <SearchListWrap>
                            <ListPart titleArr={SEARCH_YOU_MUSIC_TITLE} minWidth={500} nowPage={searchPage} setPage={setSearchPage} total={searchTotal} >
                                {searchList === undefined || searchList.length === 0 ?
                                    <TableNoItem colSpan={SEARCH_YOU_MUSIC_TITLE.length} /> :
                                    searchList.map((data:any,index:any)=>{
                                        const genre = genreList.filter((item:any)=>item.sound_small_category === data.sns_genre);
                                        const chk = musicList.filter((item:any)=>item.sns_id === data.sns_id);

                                        return(
                                            <TableRow key={`tableContent-${index}`}>
                                               <TableContentItem>
                                                    <ImgItem onClick={()=>imgClick(data.sns_youtube_video_id)} src={`https://img.youtube.com/vi/${data.sns_youtube_video_id}/0.jpg`} />
                                                </TableContentItem>
                                                <TableContentItem text={data.sns_title}></TableContentItem>
                                                <TableContentItem text={genre.length === 0 ? '' : genre[0].category_title}></TableContentItem>
                                                <TableContentItem>
                                                    {chk.length === 0 ?
                                                        <Button variant='contained' onClick={()=>musicAdd(data)}>추가</Button> :
                                                        <Button variant='contained' color='error' onClick={()=>musicDel(data.sns_id)}>제거</Button>
                                                    }
                                                </TableContentItem>
                                            </TableRow>
                                        )
                                    })
                                }
                                <></>
                            </ListPart>
                        </SearchListWrap>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

const InputWrap = styled.div`
    margin-top: 15px;
`;

const MusicWrap = styled.div`
    margin-top: 15px;
`;

const PreviewImgWrap = styled.div`
    margin-top: 15px;
    & img{
        max-width: 150px;
    }
`;

const MusicListWrap = styled.div`
    border: 1px solid #ddd;
    min-height: 240px;
    max-height: 300px;
    border-radius: 5px;
    overflow: auto;
`;

const ImgItem = styled.img`
    cursor: pointer;
`;

const BtnWrap = styled(Center)`
    margin-top: 30px;
    & button{
        margin: 0 8px;
    }
`;

const SearchWrap = styled.form`
    align-items: end;
    display: flex;
    justify-content: space-between;
    &>div{
        width: calc(100% - 70px);
    }
`;

const SearchListWrap = styled.div`
    margin-top: 15px;
`;
import React, { useState,useEffect } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import { Grid, Pagination, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import { AdminWrap, Row, SearchWrap } from '../../../style/basic/commonStyle'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {TabPanelPart,a11yProps} from '../../common/part/TabPanelPart'
import MusicListContentPart from '../part/MusicListContentPart'
import { MUSIC_CATEGORY_ITEM, MUSIC_LIST_ITEM, getMusicCateList, getMusicList, getSearchMusicList } from '../../../api/apiMusic'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import SearchPart from '../../common/part/SearchPart'

export default function MusicListPage() {
    const pageTitle = '음악 리스트';
    const navigate = useNavigate();
    const {tab,page,sc} = useParams();
    let searchRes = sc === undefined ? '' : String(sc);
    
    const [list,setList] = useState([MUSIC_LIST_ITEM]);
    const [total,setTotal] = useState(1);

    const [categoryList,setCategoryList] = useState([MUSIC_CATEGORY_ITEM]);

    const value = Number(tab) ?? 0;
    const nowPage = Number(page) ?? 1;

    const userInfo = useUserInfo();

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getCategoryList();
    },[userInfo.jwt_token])

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,value,nowPage,searchRes])
    
    // 탭이동 이동
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        // setValue(newValue);
        navigate(`/musicList/${newValue}/1`);
    };

    // 음악 카테고리 리스트
    const getCategoryList = async() =>{
        const res = await getMusicCateList(userInfo.jwt_token,userInfo.admin_id,1,1,50);
        if(res.result){
            setCategoryList(res.data);
            return;
        }
        setCategoryList([MUSIC_CATEGORY_ITEM]);
    }

    // 음악 리스트
    const getList = async() =>{
        let res = {
            result:false,
            code:1000,
            data:[MUSIC_LIST_ITEM],
            total:0,
        };
        if(value === 0){
            // 전체
            if(searchRes !== ''){
                res = await getSearchMusicList(userInfo.jwt_token,userInfo.admin_id,searchRes,nowPage);
            }else{
                res = await getMusicList(userInfo.jwt_token,userInfo.admin_id,10,nowPage);
            }
        }
        if(value === 1){
            // 대기
            res = await getMusicList(userInfo.jwt_token,userInfo.admin_id,0,nowPage);
        }

        if(value === 2){
            // 승인
            res = await getMusicList(userInfo.jwt_token,userInfo.admin_id,1,nowPage);
        }

        if(value === 3){
            // 거절
            res = await getMusicList(userInfo.jwt_token,userInfo.admin_id,2,nowPage);
        }

        if(value === 4){
            // 숨김
            res = await getMusicList(userInfo.jwt_token,userInfo.admin_id,3,nowPage);
        }
        if(value === 100){
            res = await getMusicList(userInfo.jwt_token,userInfo.admin_id,100,nowPage);
        }
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
            return
        }
        setList([MUSIC_LIST_ITEM]);
        setTotal(0)
    }

    const searchAction = (val:string) =>{
        navigate(`/musicList/0/1/${val}`);
    }
    return (
        <>
            <AdminWrap>
                <TableWrapTitleItem title={pageTitle}></TableWrapTitleItem>
                <Tabs 
                    value={value} 
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{width:'100%'}}
                >
                    <Tab label="전체" {...a11yProps(0)} />
                    <Tab label="대기" {...a11yProps(1)} />
                    <Tab label="승인" {...a11yProps(2)} />
                    <Tab label="거절" {...a11yProps(3)} />
                    <Tab label="숨김" {...a11yProps(4)} />
                    <Tab label="삭제" {...a11yProps(100)} value={100} />
                </Tabs>
                <TabContentWrap>
                    {/* 전체 */}
                    <TabPanelPart value={value} index={0}>
                        <SearchWrap style={{marginBottom:'15px'}}>
                            <SearchPart setRes={searchAction} />
                        </SearchWrap>
                        <MusicListContentPart getList={getList} index={0} list={list} nowPage={nowPage} total={total} categoryList={categoryList} searchRes={searchRes} />
                    </TabPanelPart>
                    {/* 대기 */}
                    <TabPanelPart value={value} index={1}>
                        <MusicListContentPart getList={getList} index={1} list={list} nowPage={nowPage} total={total} categoryList={categoryList} />
                    </TabPanelPart>
                    {/* 승인 */}
                    <TabPanelPart value={value} index={2}>
                        <MusicListContentPart getList={getList} index={2} list={list} nowPage={nowPage} total={total} categoryList={categoryList} />
                    </TabPanelPart>
                    {/* 거절 */}
                    <TabPanelPart value={value} index={3}>
                        <MusicListContentPart getList={getList} index={3} list={list} nowPage={nowPage} total={total} categoryList={categoryList} />
                    </TabPanelPart>
                    {/* 숨김 */}
                    <TabPanelPart value={value} index={4}>
                        <MusicListContentPart getList={getList} index={4} list={list} nowPage={nowPage} total={total} categoryList={categoryList} />
                    </TabPanelPart>
                    {/* 삭제 */}
                    <TabPanelPart value={value} index={100}>
                        <MusicListContentPart getList={getList} index={4} list={list} nowPage={nowPage} total={total} categoryList={categoryList} />
                    </TabPanelPart>
                </TabContentWrap>
            </AdminWrap>
        </>
    )
}

const TabContentWrap = styled.div`
    margin-top: 10px;
`;

// const SearchWrap = styled.div`
//     display: flex;
//     justify-content: end;
//     margin: 15px 0;
// `;
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import InputItem from '../../common/item/InputItem';
import { Center, Row } from '../../../style/basic/commonStyle';
import { Button, MenuItem, TextField } from '@mui/material';
import { fileTypeRes } from '../../../utils/format/string';
import SelectItem from '../../common/item/SelectItem';
import BasicText from '../../basic/BasicText';
import { apiSetMusicBannerCreate } from '../../../api/apiHomepage';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

interface appBannerInputPartType {
    selectItem: any;
    selectItemReset: any;
    getList: any;
    type: number;
    uploadAction?: any;
    updateAction?: any;
}

const BANNER_TYPE = ['앱','웹'];
const BANNER_ACTION = ['go_inner_screen','go_webview','go_linkurl','web_profile','web_creator_profile','web_play'];
const BANNER_POSITION = ['메인 상단','메인 하단','아티스트 프로필'];

export default function BannerInputPart({selectItem,selectItemReset,getList,type,uploadAction,updateAction}:appBannerInputPartType) {
    const userInfo = useUserInfo();
    const [pageType,setPageType] = useState('생성');
    const [itemid,setItemId] = useState('');
    const [banner_type,setBanner_type] = useState<any>(type)
    const [title,setTitle] = useState('');
    const [content,setContent] = useState('');
    const [action,setAction] = useState('go_inner_screen');
    const [name,setName] = useState('');
    const [navigator,setNavigator] = useState('');
    const [screen,setScreen] = useState('');
    const [tab,setTab] = useState('');
    const [webview_url,setWebview_url] = useState('');
    const [filePc,setFilePc] = useState<any>(null);
    const [fileMo,setFileMo] = useState<any>(null);
    const fileRefPc = useRef<any>(null);
    const fileRefMo = useRef<any>(null);
    const [previewPc,setPreviewPc] = useState('');
    const [previewMo,setPreviewMo] = useState('');
    const [posType,setPosType] = useState(1);

    useEffect(()=>{
        if(selectItem === undefined){
            setPageType('생성')
        }else{
            setPageType('수정');
        }
        stateValue();
    },[selectItem]);

    const stateValue = () =>{
        setItemId(selectItem?.banner_id ?? '');
        // let bannerType = selectItem?.banner_type === undefined ? '0' : selectItem?.banner_type == '1' ? '0' : '1';
        // setBanner_type(bannerType);
        setTitle(selectItem?.banner_title ?? '');
        setContent(selectItem?.banner_content ?? '');
        setAction(selectItem?.event?.action ?? 'go_inner_screen');
        setName(selectItem?.event?.name ?? '');
        setNavigator(selectItem?.event?.navigator ?? '');
        setScreen(selectItem?.event?.screen ?? '');
        setTab(selectItem?.event?.tab ?? '');
        setWebview_url(selectItem?.event?.webview_url ?? '');
        setFilePc(null);
        setFileMo(null);
        setPreviewPc(selectItem?.banner_web_img ?? '');
        setPreviewMo(selectItem?.banner_mo_img ?? '');
        fileRefPc?.current && (fileRefPc.current.value = null);
        fileRefMo?.current && (fileRefMo.current.value = null);
    }

    // pc 이미지 선택
    const fileSelectPc = (e:any) =>{
        const file = e.target.files[0];
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'png' || fileType === 'jpg'){
            const preImg = URL.createObjectURL(file);
            setFilePc(file);
            setPreviewPc(preImg);
        }else{
            fileRefPc.current.value = null;
            setFilePc(null);
            setPreviewPc('');
            alert('png 또는 jpg 파일을 선택해주세요.');
        }
    }

    // 모바일 이미지 선택
    const fileSelectMo = (e:any) =>{
        const file = e.target.files[0];
        const fileType = fileTypeRes(e.target.value);
        if(fileType === 'png' || fileType === 'jpg'){
            const preImg = URL.createObjectURL(file);
            setFileMo(file);
            setPreviewMo(preImg);
        }else{
            fileRefPc.current.value = null;
            setFileMo(null);
            setPreviewMo('');
            alert('png 또는 jpg 파일을 선택해주세요.');
        }
    }

    // 생성 / 수정 버튼 클릭
    const btnAction = async() =>{
        if(previewPc === '' || previewMo === ''){
            alert('이미지를 선택해주세요.');
            return;
        }
        if(pageType === '생성'){
            if(uploadAction){
                const res = await uploadAction(title,filePc,fileMo,content,name,action,navigator,screen,tab,webview_url,posType);
                console.log(res);
                
                if(res.result){
                    alert('배너가 생성되었습니다.');
                    stateValue();
                    getList();
                    return;
                }
            }
            // const res = await apiSetMusicBannerCreate(userInfo.jwt_token,userInfo.admin_id,title,filePc,fileMo,bannerType,content,name,action,navigator,screen,tab,webview_url);
        }
        if(pageType === '수정'){
            if(updateAction){
                const res = await updateAction(itemid,title,filePc,fileMo,content,name,action,navigator,screen,tab,webview_url,posType);
                if(res.result){
                    alert('배너가 수정되었습니다.');
                    stateValue();
                    getList();
                    selectItemReset();
                    return;
                }
            }
        }
    }

    // 수정 취소
    const cancelBtn = () =>{
        if(window.confirm('취소 하시겠습니까?')){
            selectItemReset();
        }
    }

    return (
        <Wrap>
            {/* <BasicText marginBottom={1}>타입</BasicText>
            <SelectItem value={banner_type} setValue={setBanner_type}>
                {BANNER_TYPE.map((data:any,index:any)=>{
                    return(
                        <MenuItem key={`banner_type-${index}`} value={index}>{data}</MenuItem>
                    )
                })}
            </SelectItem> */}
            <BasicText marginBottom={1}>위치</BasicText>
            <SelectItem value={posType} setValue={setPosType}>
                {BANNER_POSITION.map((data:any,index:any)=>{
                    return(
                        <MenuItem key={`banner_type-${index}`} value={index+1}>{data}</MenuItem>
                    )
                })}
            </SelectItem>
            <InputItem inputLabel={'none'} margin='8px 0 0' label='타이틀' value={title} valueChange={setTitle} inputSx={{width:'100%'}} />
            <BasicText marginBottom={1}>내용</BasicText>
            <TextField
                id="outlined-multiline-static"
                label=""
                multiline
                rows={4}
                value={content}
                fullWidth
                onChange={e=>setContent(e.target.value)}
            />
            {banner_type === 0 &&
                <>
                    <BasicText marginBottom={1}>action</BasicText>
                    <SelectItem value={action} setValue={setAction}>
                        {BANNER_ACTION.map((data,index)=>{
                            return(
                                <MenuItem key={`action-${index}`} value={data}>{data}</MenuItem>
                            )
                        })}
                    </SelectItem>
                    <InputItem inputLabel={'none'} margin='8px 0 0' label='name' value={name} valueChange={setName} inputSx={{width:'100%'}} />
                    <InputItem inputLabel={'none'} margin='8px 0 0' label='navigator' value={navigator} valueChange={setNavigator} inputSx={{width:'100%'}} />
                    <InputItem inputLabel={'none'} margin='8px 0 0' label='screen' value={screen} valueChange={setScreen} inputSx={{width:'100%'}} />
                    <InputItem inputLabel={'none'} margin='8px 0 0' label='tab' value={tab} valueChange={setTab} inputSx={{width:'100%'}} />
                </> 
            }
            <InputItem inputLabel={'none'} margin='8px 0 0' label='webview url' value={webview_url} valueChange={setWebview_url} inputSx={{width:'100%'}} />
            <InputItem inputLabel={'none'} margin='8px 0 0' label='이미지 pc' type={'file'} valueChange={fileSelectPc} inputProps={{accept:'image/*'}} fileRef={fileRefPc} />
            <PreviewImgWrap>
                {previewPc !== '' &&
                    <img alt='preview-img' src={previewPc}></img>
                }
            </PreviewImgWrap>
            <InputItem inputLabel={'none'} margin='8px 0 0' label='이미지 mobile' type={'file'} valueChange={fileSelectMo} inputProps={{accept:'image/*'}} fileRef={fileRefMo}></InputItem>
            <PreviewImgWrap>
                {previewMo !== '' &&
                    <img alt='preview-img' src={previewMo}></img>
                }
            </PreviewImgWrap>
            <BtnWrap>
                <Button variant='contained' onClick={btnAction}>{pageType}</Button>
                {pageType === '수정' &&
                    <Button variant='contained' onClick={cancelBtn} color='error'>취소</Button>
                }
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
   
`;
const BtnWrap = styled(Center)`
    margin: 15px 0 5px;
    & button{
        margin: 0 15px;
    }
`;

const PreviewImgWrap = styled(Row)`
    margin-top: 10px;
    & img{
        max-width: 260px;
    }
`;
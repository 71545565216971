import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { apiGetYouList } from '../../../api/apiMusic';
import SearchPart from '../../common/part/SearchPart';
import { Row, SearchWrap } from '../../../style/basic/commonStyle';
import { apiSetVideoUpdate } from '../../../api/apiStory';
import ListPart from '../../common/part/ListPart';
import TableNoItem from '../../common/item/TableNoItem';
import { Button, TableRow } from '@mui/material';
import TableContentItem from '../../common/item/TableContentItem';

const TITLE = [
    {name:'#', size:20},
    {name:'이미지', size:30},
    {name:'타이틀', size:25},
    {name:'', size:25},
]

export default function BannerAppVideoListPart({mainList,newListState,setNewListState}:any) {
    const userInfo = useUserInfo();
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(1);
    const [search,setSearch] = useState('');

    useEffect(()=>{
        if(newListState){
            getList();
            setNewListState(false);
        }
    },[newListState])

    useEffect(()=>{
        getList();
    },[page,search])

    // 음악 검색 리스트
    const getList = async() =>{
        const res = await apiGetYouList(userInfo.jwt_token,userInfo.admin_id,page,"2","0","1",search);
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 등록 해제
    const listUpdate = async(sns_id:any,main_state:any) =>{
        let main = main_state === '0' ? '1' : '0';
        let resStr = main_state === '0' ? '등록' : '해제';
        if(window.confirm(`${resStr} 하시겠습니까?`)){
            const res = await apiSetVideoUpdate(userInfo.jwt_token,userInfo.admin_id,sns_id,main);
            if(res.result){
                alert(`${resStr}되었습니다.`);
                getList();
                mainList();
            }
        }
    }

    // 검색
    const searchAction = (sc:string) => {
        setPage(1);
        setSearch(sc);
    }

    return (
        <Wrap>
            <SearchWrap style={{marginBottom: '15px'}}>
                <SearchPart setRes={searchAction} />
            </SearchWrap>
            <ListPart titleArr={TITLE} minWidth={500} nowPage={page} setPage={setPage} total={total} >
                {list === undefined || list.length === 0 ?
                    <TableNoItem colSpan={TITLE.length} /> :
                    list.map((data:any,index:any)=>{
                        return(
                            <TableRow key={`tableContent-${index}`}>
                                <TableContentItem text={data.sns_id}></TableContentItem>
                                <TableContentItem>
                                    <img alt='video-img' src={`https://img.youtube.com/vi/${data.sns_youtube_video_id}/0.jpg`} />
                                </TableContentItem>
                                <TableContentItem text={data.sns_title}></TableContentItem>
                                <TableContentItem>
                                    <Button onClick={()=>{listUpdate(data.sns_id,data.is_recommend)}} variant='contained' color={data?.is_recommend === '0' ? 'info' : 'error'}>
                                        {data?.is_recommend === '0' ? '등록' : '해제'}
                                    </Button>
                                </TableContentItem>
                            </TableRow>
                        )
                    })
                }
                <></>
            </ListPart>
        </Wrap>
    )
}

const Wrap = styled.div``;
// const SearchWrap = styled(Row)`
//     padding: 10px 0;
//     justify-content: end;
// `;
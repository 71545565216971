import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ListPart from '../../common/part/ListPart';
import { REWARD_SETTING } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import { REWARD_SETTING_SEND_TYPE, apiGetRewardSettingList, apiSetRewardSettingDel } from '../../../api/apiReward';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { Button, TableRow } from '@mui/material';
import TableContentItem from '../../common/item/TableContentItem';
import { numberWithComma } from '../../../utils/format/number';
import { Row } from '../../../style/basic/commonStyle';

export default function RewardSettingListPart({setSelectItem,newList}:any) {
    const userInfo = useUserInfo();
    const [list,setList] = useState<any>([]);
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(0);

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,page,newList])

    const getList = async() =>{
        const res = await apiGetRewardSettingList(userInfo.jwt_token,userInfo.admin_id,page,10);
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 수정 버튼 
    const updateClick = (data:any) =>{
        setSelectItem(data);
    }

    // 삭제 버튼
    const deleteClick = async(data:any) =>{
        if(window.confirm('삭제하시겠습니까?')){
            const res = await apiSetRewardSettingDel(userInfo.jwt_token,userInfo.admin_id,data.reward_config_id);
            if(res.result){
                alert('삭제되었습니다.');
                getList();
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <Wrap>
            <ListPart titleArr={REWARD_SETTING} minWidth={1000} nowPage={page} setPage={setPage} total={total} >
                {list === undefined || list.length === 0 ? 
                    <TableNoItem colSpan={REWARD_SETTING.length} /> :
                    list.map((data:any,index:any)=>{
                        const sendTypeToNum = Number(data.reward_config_process);
                        const sendType = REWARD_SETTING_SEND_TYPE[sendTypeToNum-1];
                        return(
                            <TableRow key={`table-content-${index}`}>
                                <TableContentItem text={data.reward_config_id} />
                                <TableContentItem text={data.reward_config_code} />
                                <TableContentItem text={data.reward_config_name} />
                                <TableContentItem text={numberWithComma(data.reward_config_amount)} />
                                <TableContentItem text={sendType} />
                                <TableContentItem text={data.reward_config_goods_code} />
                                <TableContentItem>
                                    <Row style={{width:'auto',gap:'4px'}}>
                                        <Button variant='contained' onClick={()=>updateClick(data)}>수정</Button>
                                        <Button variant='contained' onClick={()=>deleteClick(data)} color='error'>삭제</Button>
                                    </Row>
                                </TableContentItem>
                            </TableRow>
                        )
                    })
                }
            </ListPart>
        </Wrap>
    )
}

const Wrap = styled.div``;

import { Button, Grid, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import TableContentItem from '../../common/item/TableContentItem'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import LeftNavBar from '../../common/part/LeftNavBar'
import { GAME_TITLE } from '../../../data/tableTitle'
import { useParams } from 'react-router'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import ListPart from '../../common/part/ListPart'
import TableNoItem from '../../common/item/TableNoItem'
import { GAME_TYPE, GAME_VISIBLE, apiGetGameList, apiSetGameOnOff } from '../../../api/apiGame'
import GameUploadPart from '../part/GameUploadPart'
import { numberWithComma } from '../../../utils/format/number'

export default function GameListPage() {
    const pageTitle = '게임 리스트';
    const userInfo = useUserInfo();
    const {page} = useParams();
    let nowPage = Number(page) ?? 1;
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(1);
    const [selectItem,setSelectItem] = useState<any>();

    useEffect(()=>{
        getList();
    },[nowPage])

    // 리스트 api
    const getList = async() =>{
        const res = await apiGetGameList(userInfo.jwt_token,userInfo.admin_id,nowPage,10);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 리스트 링크 클릭시
    const linkClick = (url:string) => {
        window.open(url);
    }

    // 활성 비활성
    const gameOnOff = async(id:any,visible:any) =>{
        const visibleNum = Number(visible)
        const visibleState = GAME_VISIBLE[visibleNum];
        if(window.confirm(`${visibleState}화 하시겠습니까?`)){
            const res = await apiSetGameOnOff(userInfo.jwt_token,userInfo.admin_id,id,visible);
            if(res.result){
                alert(`${visibleState}화 되었습니다.`);
                await getList();
            }else{
                alert(res.error);
            }
        }
    }

    // 수정
    const updateBtn = (item:any) =>{
        if(window.confirm('수정 하시겠습니까?')){
            setSelectItem(item);
        }
    }

    // 수정 취소
    const selectItemReset = () =>{
        setSelectItem(undefined);
    }

    return (
        <LeftNavBar idKey={'8-1'} titleText={pageTitle}>
            <Grid container={true} spacing={3}>
                <Grid item md={4}>
                    <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2, marginTop:'15px'}}>
                        <GameUploadPart selectItem={selectItem} selectItemReset={selectItemReset} getList={getList} />
                    </Box>
                </Grid>
                <Grid item md={8}>
                    <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2, marginTop:'15px'}}>
                        <TableWrapTitleItem title={pageTitle} />
                        <ListPart titleArr={GAME_TITLE} minWidth={1200} nowPage={nowPage} total={total} link={'gameList'} >
                            {list === undefined || list.length === 0 ?
                                <TableNoItem colSpan={GAME_TITLE.length} /> :
                                list.map((data:any,index:any)=>{
                                    const type = GAME_TYPE[Number(data.game_is_multi)];
                                    return(
                                        <TableRow key={`table-content-${index}`}>
                                            <TableContentItem text={data.game_id} />
                                            <TableContentItem>
                                                <img alt='game-img' src={data.game_icon_url} />
                                            </TableContentItem>
                                            <TableContentItem text={data.game_name} />
                                            <TableContentItem text={data.game_description} />
                                            <TableContentItem text={type} />
                                            <TableContentItem text={numberWithComma(data.game_oneday_count)} />
                                            <TableContentItem text={numberWithComma(data.game_reward_score)} />
                                            <TableContentItem text={data.game_url} clickEvent={()=>linkClick(data.game_url)}></TableContentItem>
                                            <TableContentItem>
                                                {data.game_visible === '1' ?
                                                    <Button variant='contained' color='error' onClick={()=>gameOnOff(data.game_id,"0")}>비활성화</Button> :
                                                    <Button variant='contained' onClick={()=>gameOnOff(data.game_id,"1")}>활성화</Button> 
                                                }
                                            </TableContentItem>
                                            <TableContentItem>
                                                <Button variant='contained' onClick={()=>updateBtn(data)}>수정</Button>
                                            </TableContentItem>
                                        </TableRow>
                                    )
                                })
                            }
                        </ListPart>
                    </Box>
                </Grid>
            </Grid>
        </LeftNavBar>
    )
}

import { TextField } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

export default function AppInputItem({inputSx,value,valueChange,multi,type}:any) {
    return (
        <>
            {multi ?
                <MultiText value={value} onChange={(e)=>valueChange(e.target.value)} rows={3} /> :
                <TextField 
                    type={type ?? 'text'}
                    size='small' 
                    sx={{
                        '.MuiInputBase-root' : {padding:'4px'},
                        '.MuiInputBase-input':{padding:'4px',fontSize:'15px'},
                        width:'80%',
                        ...inputSx
                    }} 
                    variant="outlined" 
                    value={value} 
                    onChange={(e)=>valueChange(e.target.value)}
                />
            }
        </>
    )
}

const MultiText = styled.textarea`
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 4px;
    border-radius: 4px;
    font-size:15px;
    width: 80%;
`;
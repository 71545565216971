import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Row } from '../../../style/basic/commonStyle';
import AppInputItem from '../item/AppInputItem';
import AppBooleanItem from '../item/AppBooleanItem';
import VerticalScroll_001_list_item from './item/VerticalScroll_001_list_item';
import UiBtnItem from './item/UiBtnItem';

export default function VerticalScroll_001_input({data,update,index}:any) {
    const [container,setContainer] = useState<any>();
    const [h_title_text,setH_title_text] = useState('');
    const [h_styles,setH_styles] = useState<any>();
    const [h_title_customStyles,setH_title_customStyles] = useState('');
    const [h_btnEvent,setH_btnEvent] = useState<any>();
    const [h_btnIcon,setH_btnIcon] = useState('');
    const [h_btnStyles,setH_btnStyles] = useState<any>();
    const [i_customStyle,setI_customStyle] = useState('');
    const [i_styles,setI_styles] = useState<any>();
    const [i_list,setI_list] = useState<any>([]);
    const [i_img_styles,setI_img_styles] = useState('');
    
    useEffect(()=>{
        if(data === undefined) return;
        setContainer(data?.container);
        setH_title_text(data?.header?.title?.text);
        setH_styles(data?.header?.title?.styles);
        setH_title_customStyles(data?.header?.title?.custom_styles);
        setH_btnEvent(data?.header?.right_btn?.event);
        setH_btnIcon(data?.header?.right_btn?.icon_name);
        setH_btnStyles(data?.header?.right_btn?.styles);
        setI_customStyle(data?.item?.custom_styles);
        setI_styles(data?.item?.styles);
        setI_list(data?.item?.list);
        setI_img_styles(data?.item?.img_styles);
    },[data]);

    const containerUpdate = (val:string,type:string) =>{
        const newData = {...container};
        const keys = type.split('.');
        let currentObj = newData;
        for (let i = 0; i < keys.length -1; i++) {
            currentObj = currentObj[keys[i]];
        }
        const targetKey = keys[keys.length - 1];
        currentObj[targetKey] = val;
        setContainer(newData);
    }

    const headerStylesUpdate = (val:string,type:string) =>{
        const newData = {...h_styles};
        newData[type] = val;
        setH_styles(newData);
    }

    const headerBtnStylesUpdate = (val:string,type:string) =>{
        const newData = {...h_btnStyles};
        newData[type] = val;
        setH_btnStyles(newData);
    }

    const headerBtnEventUpdate = (val:string,type:string) =>{
        const newData = {...h_btnEvent};
        newData[type] = val;
        setH_btnEvent(newData);
    }
    
    const itemUpdate = (val:string,type:string) =>{
        const newData = {...i_styles};
        newData[type] = val;
        setI_styles(newData);
    }

    const itemListUpdate = (index:any,data:any) =>{
        setI_list((arr:any)=>arr.map((item:any,idx:any)=>(
            idx === index ? {...item,...data} : item
        )))
    }

    const updateEvent = () =>{
        const newData = data;
        newData.container = container;
        newData.header.title = {
            text: h_title_text,
            styles: h_styles,
            custom_styles: h_title_customStyles
        }
        newData.item = {
            custom_styles: i_customStyle,
            img_styles: i_img_styles,
            list: i_list,
            styles: i_styles,
        }
        update(newData,index);
    }

    return (
        <Wrap>
            <ItemWrap>
                <TitleWrap>type</TitleWrap>
                <ContentWrap>
                    {data.type}
                </ContentWrap>
            </ItemWrap>
            <ItemWrap>
                <TitleWrap>container</TitleWrap>
                <ContentWrap>
                    <ContentItem>
                        <ContentItemTitle>custom_styles:</ContentItemTitle>
                        <AppInputItem value={container?.custom_styles ?? ''} valueChange={(val:any)=>containerUpdate(val,'custom_styles')} multi/>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>styles</ContentItemTitle>
                        <ContentItemWrap>
                            <ContentItem>
                                <ContentItemTitle>padding:</ContentItemTitle>
                                <AppInputItem type='number' value={container?.styles?.padding ?? ''} valueChange={(val:any)=>containerUpdate(val,'styles.padding')}/>
                            </ContentItem>
                        </ContentItemWrap>
                    </ContentItem>
                </ContentWrap>
            </ItemWrap>
            <ItemWrap>
                <TitleWrap>header</TitleWrap>
                <ContentWrap>
                    {/* <ContentItem>
                        <ContentItemTitle>right_btn</ContentItemTitle>
                        <ContentItemWrap>
                            <ContentItem>
                                <ContentItemTitle>event</ContentItemTitle>
                                <ContentItemWrap>
                                    <ContentItem>
                                        <ContentItemTitle>name</ContentItemTitle>
                                        <AppInputItem value={h_btnEvent?.name ?? ''} valueChange={(val:any)=>headerBtnEventUpdate(val,'name')} />
                                    </ContentItem>
                                    <ContentItem>
                                        <ContentItemTitle>action</ContentItemTitle>
                                        <AppInputItem value={h_btnEvent?.action ?? ''} valueChange={(val:any)=>headerBtnEventUpdate(val,'action')} />
                                    </ContentItem>
                                    <ContentItem>
                                        <ContentItemTitle>navigator</ContentItemTitle>
                                        <AppInputItem value={h_btnEvent?.navigator ?? ''} valueChange={(val:any)=>headerBtnEventUpdate(val,'navigator')} />
                                    </ContentItem>
                                    <ContentItem>
                                        <ContentItemTitle>screen</ContentItemTitle>
                                        <AppInputItem value={h_btnEvent?.screen ?? ''} valueChange={(val:any)=>headerBtnEventUpdate(val,'screen')} />
                                    </ContentItem>
                                </ContentItemWrap>
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>icon_name</ContentItemTitle>
                                <AppInputItem value={h_btnIcon ?? ''} valueChange={setH_btnIcon} />
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>styles</ContentItemTitle>
                                <ContentItemWrap>
                                    <ContentItem>
                                        <ContentItemTitle>color</ContentItemTitle>
                                        <AppInputItem value={h_btnStyles?.color ?? ''} valueChange={(val:any)=>headerBtnStylesUpdate(val,'color')} />
                                    </ContentItem>
                                    <ContentItem>
                                        <ContentItemTitle>size</ContentItemTitle>
                                        <AppInputItem type='number' value={h_btnStyles?.size ?? ''} valueChange={(val:any)=>headerBtnStylesUpdate(val,'size')} />
                                    </ContentItem>
                                </ContentItemWrap>
                            </ContentItem>
                        </ContentItemWrap>
                    </ContentItem> */}
                    <ContentItem>
                        <ContentItemTitle>title</ContentItemTitle>
                        <ContentItemWrap>
                            <ContentItem>
                                <ContentItemTitle>text:</ContentItemTitle>
                                <AppInputItem value={h_title_text} valueChange={setH_title_text}/>
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>styles</ContentItemTitle>
                                <ContentItemWrap>
                                    <ContentItem>
                                        <ContentItemTitle>bold:</ContentItemTitle>
                                        <AppBooleanItem value={h_styles?.bold ?? 'false'} setValue={(val:any)=>headerStylesUpdate(val,'bold')} />
                                    </ContentItem>
                                    <ContentItem>
                                        <ContentItemTitle>color:</ContentItemTitle>
                                        <AppInputItem value={h_styles?.color ?? 'white'} valueChange={(val:any)=>headerStylesUpdate(val,'color')} />
                                    </ContentItem>
                                    <ContentItem>
                                        <ContentItemTitle>fontSize:</ContentItemTitle>
                                        <AppInputItem type='number' value={h_styles?.fontSize ?? '0'} valueChange={(val:any)=>headerStylesUpdate(val,'fontSize')} />
                                    </ContentItem>
                                </ContentItemWrap>
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>custom_styles:</ContentItemTitle>
                                <AppInputItem value={h_title_customStyles} valueChange={setH_title_customStyles} multi/>
                            </ContentItem>
                        </ContentItemWrap>
                    </ContentItem>
                </ContentWrap>
            </ItemWrap>
            <ItemWrap>
                <TitleWrap>item</TitleWrap>
                <ContentWrap>
                    <ContentItem>
                        <ContentItemTitle>custom_styles:</ContentItemTitle>
                        <AppInputItem value={i_customStyle} valueChange={setI_customStyle} multi/>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>img_styles:</ContentItemTitle>
                        <AppInputItem value={i_img_styles} valueChange={setI_img_styles} multi/>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>styles</ContentItemTitle>
                        <ContentItemWrap>
                            <ContentItem>
                                <ContentItemTitle>activeOpacity:</ContentItemTitle>
                                <AppInputItem value={i_styles?.activeOpacity ?? '0'} valueChange={(val:any)=>itemUpdate(val,'activeOpacity')} />
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>padding:</ContentItemTitle>
                                <AppInputItem value={i_styles?.padding ?? '0'} valueChange={(val:any)=>itemUpdate(val,'padding')} />
                            </ContentItem>
                            <ContentItem>
                                <ContentItemTitle>radius:</ContentItemTitle>
                                <AppInputItem value={i_styles?.radius ?? '0'} valueChange={(val:any)=>itemUpdate(val,'radius')} />
                            </ContentItem>
                        </ContentItemWrap>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>list</ContentItemTitle>
                        <ContentItemWrap>
                            {i_list === undefined || i_list.length === 0 ?
                                <></> : 
                                i_list.map((data:any,index:any)=>{
                                    return(
                                        <VerticalScroll_001_list_item key={`list-item-${index}`} data={data} index={index} itemUpdate={itemListUpdate} />
                                    )
                                })
                            }
                        </ContentItemWrap>
                    </ContentItem>
                </ContentWrap>
            </ItemWrap>
            <UiBtnItem updateEvent={updateEvent} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const ItemWrap = styled(Row)`
    align-items: start;
`;
const TitleWrap = styled.div`
    width: 90px;
    font-weight: 700;
`;
const ContentWrap = styled.div`
    &>div{
        margin-bottom: 4px;
    }
`;

const ContentItem = styled(Row)`
    align-items: start;
    margin-bottom: 4px;
`;
const ContentItemTitle = styled.div`
    margin-right: 4px;
`;

const ContentItemWrap = styled(Row)`
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px;
`;
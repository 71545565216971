import React, { useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Grid } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import RewardSendUserListPart from '../part/RewardSendUserListPart';
import RewardSendPart from '../part/RewardSendPart';
import { AdminWrap } from '../../../style/basic/commonStyle';

export default function RewardSendPage() {
    const pageTitle = '리워드 지급';
    const [userList,setUserList] = useState<any>([])

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'유저 리스트'} />
                        <RewardSendUserListPart userList={userList} setUserList={setUserList} />
                    </AdminWrap>
                </Grid>
                <Grid item md={6} sm={12} sx={{width: '100%'}}>
                    <div style={{position:'sticky',top:'10px',left:0,}}>
                        <AdminWrap>
                            <TableWrapTitleItem title={pageTitle} />
                            <RewardSendPart userList={userList} setUserList={setUserList}/>
                        </AdminWrap>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

import React,{useEffect,useState} from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import {Grid, Box, TableRow, Tooltip, Button} from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { useParams } from 'react-router';
import ListPart from '../../common/part/ListPart';
import { DONATE_LIST_TITLE } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import TableContentItem from '../../common/item/TableContentItem';
import { AdminWrap, TextItem } from '../../../style/basic/commonStyle';
import { numberWithComma } from '../../../utils/format/number';

const DUMMY = [
    {
        id:1,
        user_nick:'테스트',
        user_code:'suejm3j5d',
        title:`NMIXX 'Roller Coaster' M/V`,
        donate_amount:1510,
        sns_time:1691720585003,
    },
    {
        id:2,
        user_nick:'테스트',
        user_code:'suejm3j5d',
        title:`NewJeans (뉴진스) 'Super Shy' Official MV`,
        donate_amount:10010,
        sns_time:1691720585003,
    },
]

export default function StoryDonatePage() {
    const pageTitle = '후원 리스트';
    const {page} = useParams();
    let nowPage = Number(page) ?? 1;
    const [total,setTotal] = useState(1);
    const [list,setList] = useState(DUMMY);

    useEffect(()=>{
        getList();
    },[nowPage]);

    // 리스트
    const getList = async() =>{
        
    }

    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={4} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'총 후원량'}></TableWrapTitleItem>
                        <TextItem textAlign='right' fontSize={29} fw={700}>{numberWithComma(15000)}</TextItem>
                    </AdminWrap>
                </Grid>
                <Grid item md={4} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'총 후원수'}></TableWrapTitleItem>
                        <TextItem textAlign='right' fontSize={29} fw={700}>{numberWithComma(86)}</TextItem>
                    </AdminWrap>
                </Grid>
                <Grid item md={4} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title={'최고 후원액'}></TableWrapTitleItem>
                        <TextItem textAlign='right' fontSize={29} fw={700}>{numberWithComma(4000)}</TextItem>
                    </AdminWrap>
                </Grid>
            </Grid>
            <AdminWrap style={{marginTop: '25px'}}>
                <TableWrapTitleItem title={pageTitle}></TableWrapTitleItem>
                <ListPart titleArr={DONATE_LIST_TITLE} minWidth={950} nowPage={nowPage} total={total} link={'storyDonate'}>
                    {list.length === 0 || list[0].id === 0 ?
                        <TableNoItem colSpan={DONATE_LIST_TITLE.length}></TableNoItem> :
                        list?.map((data,index:number)=>{
                            return(
                                <TableRow
                                    key={`tableContent-${index}`}
                                >
                                    <TableContentItem text={data.id}></TableContentItem>
                                    <TableContentItem text={data.user_nick}></TableContentItem>
                                    <TableContentItem text={data.user_code}></TableContentItem>
                                    <TableContentItem>
                                        <Tooltip title={data.title} arrow={true}>
                                            <TextItem line={2}>{data.title}</TextItem>
                                        </Tooltip>
                                    </TableContentItem>
                                    <TableContentItem text={numberWithComma(data.donate_amount)}></TableContentItem>
                                    <TableContentItem text={data.sns_time} type='time'></TableContentItem>
                                    
                                </TableRow>
                            )
                        })
                    }
                </ListPart>
            </AdminWrap>
        </>
    )
}

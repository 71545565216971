import { axiosHome,axiosConfigInstance } from "./config";

export const APP_BANNER_ITEM = {
    id:0,
    title:'배너 1', 
    img:'/img/dummy/logo.png',
    link:'https://www.naver.com/',
    time:1686645636408
}

export const BOTTOM_BANNER_ITEM = {
    "bb_id": 0,
    "bb_title": "테스트",
    "bb_title_en":"test",
    "bb_link": "https://game3579.com/news",
    "bb_img": "/bb_img/3.jpg",
    "bb_time": 1690338098098,
    "bb_del": 0
}

export const SEASON_ITEM_ITEM ={
    'item_id':0,
    'item_name':'',
    'item_active':false,
}

export const SEASON_ITEM = {
    "season_id": 0,
    "season_title": "테스트",
    "season_time": 1690346366178,
    "season_update_time": 0,
    "season_del": 0,
    "season_item": [SEASON_ITEM_ITEM]
}

export const NOTICE_ITEM = {
    "notice_id": 0,
    "notice_type": 0,
    "notice_title": "123",
    "notice_content": "234",
    "notice_img": [
        "/notice/16902699702371.jpg",
        "/notice/16902699702372.jpg",
        "/notice/16902699702383.jpg"
    ],
    "notice_time": 1690269970240,
    "notice_update_time": 0,
    "notice_delete": 0
}

export const NOTICE_TYPE = ['PRESS','INSIGHT'];

export const FOOTER_INFO = {
    "info_id": 0,
    "info_name": "",
    "info_addr": "",
    "info_num": "",
    "info_cap": "",
    "info_phone": "",
    "info_bNum": "",
    "info_customer": ""
}

export const SNS_ITEM = {
    "sns_id":0,
    "sns_img":'',
    "sns_link":''
}

export const COUNT_ITEM = {
    "count_id": 0,
    "count_title_ko": "",
    "count_title_en": "",
    "count_content": 0,
    "count_type": 0,
    "count_icon":'',
}

export const PARTNER_ITEM = {
    "partner_id":0,
    "partner_img":'',
    "partner_link":''
}

export const PLAYLIST_ITEM = {
    "playList_id":0,
    "playList_img":'',
    "playList_title":'',
    "playList_genre":'',
}

// 비디오 리스트
export const apiGetVideo = async(token:string,admin_id:number) =>{
    try {
        const res = await axiosHome('/admin/homepage/video').post('',{
            method:'list',
        })
        // const res = await axiosHome('/admin/home/video',token).post('',{
        //     method:'adminVideoList',
        //     params:{
        //         admin_id
        //     }
        // })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 비디오 수정
export const apiSetVideo = async(video:any,videoType:string) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','update');
        formData.append('type',videoType);
        formData.append('video',video);
        const res = await axiosHome(`/admin/homepage/video`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }  
}

// 하단배너 리스트
export const apiGetBottomBannerList = async(page:any) =>{
    try {
        const res = await axiosHome('/admin/homepage/banner').post('',{
            method:'bb_list',
            params:{
                count:10,
                page,
            }
        })
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 하단배너 추가 / 수정
export const apiSetBottomBanner = async(title:string,title_en:string,link:string,img:any,updateType:string,id:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method',updateType);
        formData.append('title',title);
        formData.append('title_en',title_en);
        formData.append('link',link);
        formData.append('id',id);
        formData.append('bb_img',img);
        const res = await axiosHome(`/admin/homepage/banner`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }  
}

// 하단배너 삭제
export const apiSetBottomBannerDel = async(id:any) =>{
    try {
        const res = await axiosHome('/admin/homepage/banner').post('',{
            method:'bb_delete',
            params:{
                id,
            }
        })
    
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 시즌 리스트
export const apiGetSeasonList = async() =>{
    try {
        const res = await axiosHome('/admin/homepage/season').post('',{
            method:'list',
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 시즌 생성
export const apiSetSeason= async(title:string,) =>{
    try {
        const res = await axiosHome('/admin/homepage/season').post('',{
            method:'create',
            params:{
                title,
            }
        })
    
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 시즌 수정
export const apiSetSeasonUpdate = async(id:any,title:string,item:any) =>{
    try {
        const res = await axiosHome('/admin/homepage/season').post('',{
            method:'update',
            params:{
                title,
                item,
                id,
            }
        })
    
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 시즌 수정
export const apiSetSeasonDel = async(id:any,) =>{
    try {
        const res = await axiosHome('/admin/homepage/season').post('',{
            method:'delete',
            params:{
                id,
            }
        })
    
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 카운트 리스트
export const apiGetCountList = async() =>{
    try {
        const res = await axiosHome('/admin/homepage/count').post('',{
            method:'list'
        })
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 카운트 수정
export const apiSetCount = async(title:string,title_en:string,content:number,type:number,icon:string) =>{
    try {
        const res = await axiosHome('/admin/homepage/count').post('',{
            method:'update',
            params:{
                count_title_ko:title,
                count_title_en:title_en,
                count_content:content,
                count_type : type,
                count_icon : icon,
            }
        })

        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 공지사항 리스트
export const apiGetNoticeList = async(page:any,notice_id?:any) =>{
    try {
        const res = await axiosHome('/admin/homepage/notice').post('',{
            method:'list',
            params:{
                count:10,
                page,
                notice_id
            }
        })
    
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 공지사항 추가
export const apiSetNotice = async(title:string,content:string,imgList:any,typeState:any,updateType:string,id:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method',updateType);
        formData.append('title',title);
        formData.append('content',content);
        formData.append('type',typeState);
        for (let i = 0; i < imgList.length; i++) {
            formData.append('notice_img',imgList[i]);
        }
        if(updateType==='update'){
            formData.append('id',id);
        }
        const res = await axiosHome(`/admin/homepage/notice`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }  
}

// 공지사항 추가
export const apiSetUpdateNotice = async(title:string,content:string,imgList:any,typeState:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','update');
        formData.append('title',title);
        formData.append('content',content);
        formData.append('type',typeState);
        for (let i = 0; i < imgList.length; i++) {
            formData.append('notice_img',imgList[i]);
        }
        const res = await axiosHome(`/admin/homepage/notice`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }  
}

// 공지사항 삭제
export const apiSetNoticeDel = async(notice_id:any) =>{
    try {
        const res = await axiosHome('/admin/homepage/notice').post('',{
            method:'delete',
            params:{
                notice_id
            }
        })
    
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 파트너 리스트
export const apiGetPartner = async() =>{
    try {
        const res = await axiosHome('/admin/homepage/partner').post('',{
            method:'list',
        })
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 파트너 수정 or 추가
export const apiSetPartner = async(file:any,link:string,id:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','update');
        formData.append('link',link);
        formData.append('id',id);
        formData.append('partner_img',file);
        const res = await axiosHome(`/admin/homepage/partner`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 파트너 수정 or 추가
export const apiSetPartnerAll = async(partner:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','updateAll');
        formData.append('partner',JSON.stringify(partner));
        for (let i = 0; i < partner.length; i++) {
            if(partner[i].file !== undefined){
                formData.append('partner_img',partner[i].file);
            }
        }
        const res = await axiosHome(`/admin/homepage/partner`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 파트너 삭제
export const apiSetPartnerDel = async(partner_id:any) =>{
    try {
        const res = await axiosHome('/admin/homepage/partner').post('',{
            method:'delete',
            params:{
                partner_id
            }
        })
    
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 플레이리스트
export const apiGetPlayList = async() =>{
    try {
        const res = await axiosHome('/admin/homepage/playList').post('',{
            method:'list'
        })
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 플레이리스트 수정 or 추가
export const apiSetPlayList = async(file:any,title:string,genre:string,id:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','update');
        formData.append('title',title);
        formData.append('genre',genre);
        formData.append('id',id);
        formData.append('playList_img',file);
        const res = await axiosHome(`/admin/homepage/playList`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 플레이리스트 수정 or 추가
export const apiSetPlayListAll = async(playList:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','updateAll');
        formData.append('playList',JSON.stringify(playList));
        for (let i = 0; i < playList.length; i++) {
            if(playList[i].file !== undefined){
                formData.append('playList_img',playList[i].file);
            }
        }
        const res = await axiosHome(`/admin/homepage/playList`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 플레이리스트 삭제
export const apiSetPlayListDel = async(playList_id:any) =>{
    try {
        const res = await axiosHome('/admin/homepage/playList').post('',{
            method:'delete',
            params:{
                playList_id
            }
        })
    
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// // 약관 타입 리스트
// export const apiGetTermsType = async() =>{
//     try {
//         const res = await axiosHome('/admin/homepage/terms').post('',{
//             method:'type'
//         })
//         return res.data;
//     } catch (error) {
//         return{
//             result:false,
//             error,
//         }
//     }
// }

// // 약관 리스트
// export const apiGetTerms = async(type:any) =>{
//     try {
//         const res = await axiosHome('/admin/homepage/terms').post('',{
//             method:'list',
//             params:{
//                 type
//             }
//         })
//         return res.data;
//     } catch (error) {
//         return{
//             result:false,
//             error,
//         }
//     }
// }

// // 약관 수정
// export const apiSetTerms = async(type:any,content:string) =>{
//     try {
//         const res = await axiosHome('/admin/homepage/terms').post('',{
//             method:'update',
//             params:{
//                 type,
//                 content
//             }
//         })
//         return res.data;
//     } catch (error) {
//         return{
//             result:false,
//             error,
//         }
//     }
// }

// footer info
export const apiGetInfo = async() =>{
    try {
        const res = await axiosHome('/admin/homepage/footer').post('',{
            method:'info_list',
        })
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// footer info 수정
export const apiSetInfoUpdate = async(type:string,value:any) =>{
    try {
        const res = await axiosHome('/admin/homepage/footer').post('',{
            method:'info_update',
            params:{
                type,
                value,
            }
        })
        return res.data;
       
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// footer sns
export const apiGetSns = async() =>{
    try {
        const res = await axiosHome('/admin/homepage/footer').post('',{
            method:'sns_list',
        })
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// footer sns 수정 or 추가
export const apiSetSns = async(file:any,link:string,id:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','sns_update');
        formData.append('link',link);
        formData.append('id',id);
        formData.append('sns',file);
        const res = await axiosHome(`/admin/homepage/footer`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// sns 전체 수정 or 추가
export const apiSetSnsAll = async(sns:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','sns_updateAll');
        formData.append('snsArr',JSON.stringify(sns));
        for (let i = 0; i < sns.length; i++) {
            if(sns[i].file !== undefined){
                formData.append('sns',sns[i].file);
            }
        }
        const res = await axiosHome(`/admin/homepage/footer`,'',type).post('',formData);
        
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// sns 삭제
export const apiSetSnsDel = async(id:any) =>{
    try {
        const res = await axiosHome('/admin/homepage/footer').post('',{
            method:'sns_delete',
            params:{
                id
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////

// banner_type: 0 전체 / 1 앱 / 3 웹
// 뮤직페이지 배너
export const apiGetMusicBanner = async(token:string,admin_id:any,page:any,count:any,type:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminGetBannerList',
            params:{
                admin_id: admin_id,
                page: page,
                count: count,
                banner_type: type
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 뮤직페이지 배너 활성화 비활성화
export const apiSetMusicBannerOnOff = async(token:string,admin_id:any,banner_id:any,banner_visible:any) =>{
    try {
        const res = await axiosConfigInstance('/admin/utils',token).post('',{
            method:'adminControlVisibleBanner',
            params:{
                admin_id: admin_id,
                banner_id: banner_id,
                banner_visible: banner_visible,
            }
        })
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// banner_type - 1 앱 / 3 웹
// 뮤직페이지 배너 등록
export const apiSetMusicBannerCreate = async(token:string,admin_id:any,title:string,web_img:any,mo_img:any,banner_type:any,banner_content:any,event_name:any,event_action:any,event_navigator:any,event_screen:any,event_tab:any,event_webview_url:any,posType:number) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','adminUploadBanner');
        formData.append('params', JSON.stringify({
            admin_id: admin_id,
            banner_title: title,
            banner_content,
            event_name,
            event_action,
            event_navigator,
            event_screen,
            event_tab,
            event_webview_url,
            banner_type:banner_type,
            banner_use: posType
        }));
        if(web_img){
            formData.append('web_img',web_img);
        }
        if(mo_img){
            formData.append('mo_img',mo_img);
        }
        const res = await axiosConfigInstance(`/admin/utils`,token,type).post('',formData);
        return res.data;
    } catch (error) {
        return{
            result:false,
            error,
        }
    }
}

// 배너 수정
export const apiSetBannerUpdate = async(token:string,admin_id:any,banner_id:any,title:string,web_img:any,mo_img:any,banner_type:any,banner_content:any,event_name:any,event_action:any,event_navigator:any,event_screen:any,event_tab:any,event_webview_url:any,posType:number) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','adminModifyBanner');
        formData.append('params', JSON.stringify({
            admin_id: admin_id,
            banner_id,
            banner_title: title,
            banner_content,
            event_name,
            event_action,
            event_navigator,
            event_screen,
            event_tab,
            event_webview_url,
            banner_type:banner_type,
            banner_use: posType
        }));
        if(web_img){
            formData.append('web_img',web_img);
        }
        if(mo_img){
            formData.append('mo_img',mo_img);
        }
        const res = await axiosConfigInstance(`/admin/utils`,token,type).post('',formData);
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
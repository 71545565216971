import React,{useEffect, useState} from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Button, Grid, TableRow } from '@mui/material';
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem';
import { useNavigate, useParams } from 'react-router';
import { PLAY_LIST_TITLE } from '../../../data/tableTitle';
import TableContentItem from '../../common/item/TableContentItem';
import ListPart from '../../common/part/ListPart';
import { apiGetYouPlayList, apiSetYouListOnOff } from '../../../api/apiMusic';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import TableNoItem from '../../common/item/TableNoItem';
import { AdminWrap } from '../../../style/basic/commonStyle';

export default function MusicYouPlaylistPage() {
    const userInfo = useUserInfo();
    const pageTitle = '유튜브 플레이 리스트';
    const navigate = useNavigate();
    const {page} = useParams();
    let nowPage = Number(page) ?? 1;

    const [list,setList] = useState<any>([]);
    const [total, setTotal] = useState(0);

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,nowPage])

    const getList = async() =>{
        const res = await apiGetYouPlayList(userInfo.jwt_token,userInfo.admin_id,nowPage,10);
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }else{
            setList([]);
            setTotal(0);
        }
    }

    // 생성
    const addBtnAction = () =>{
        navigate(`/youPlaylistUpdate/${0}`)
    }

    // 수정
    const updateBtnAction = (id:any) =>{
        if(window.confirm('수정하시겠습까?')){
            navigate(`/youPlaylistUpdate/${id}`)
        }
    }

    // 삭제
    const deleteBtnAction = async(title:string,id:any,state:any) =>{
        if(window.confirm(`${title} 하시겠습까?`)){
            const res = await apiSetYouListOnOff(userInfo.jwt_token,userInfo.admin_id,id,state);
            if(res.result){
                alert(`${title} 되었습니다.`);
                getList();
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <>
            <AdminWrap >
                <TableWrapTitleItem title={pageTitle}>
                    <Button onClick={addBtnAction} variant='contained'>생성</Button>
                </TableWrapTitleItem>
                <ListPart titleArr={PLAY_LIST_TITLE} minWidth={700} nowPage={nowPage} total={total} link='youPlaylist'>
                    {list === undefined || list.length === 0 ?
                        <TableNoItem colSpan={PLAY_LIST_TITLE.length} /> :
                        list?.map((data:any,index:number)=>{
                            return(
                                <TableRow
                                    key={`tableContent-${index}`}
                                >
                                    <TableContentItem text={data.y_playlist_id}></TableContentItem>
                                    <TableContentItem>
                                        <img alt='img' src={data.y_playlist_thumbnail}></img>
                                    </TableContentItem>
                                    <TableContentItem text={data.y_playlist_name}></TableContentItem>
                                    <TableContentItem text={data.y_video_count}></TableContentItem>
                                    <TableContentItem text={data.user_nickname}></TableContentItem>
                                    <TableContentItem>
                                        <Button variant='contained' onClick={()=>updateBtnAction(data.y_playlist_id)}>수정</Button>
                                    </TableContentItem>
                                    <TableContentItem>
                                        {data.y_playlist_visible === '1' ?
                                            <Button variant='contained' color='error' onClick={()=>deleteBtnAction('비활성',data.y_playlist_id,'0')}>비활성</Button> :
                                            <Button variant='contained' onClick={()=>deleteBtnAction('활성',data.y_playlist_id,'1')}>활성</Button>
                                        }
                                    </TableContentItem>
                                </TableRow>
                            )
                        })
                    }
                </ListPart>
            </AdminWrap>
        </>
    )
}

import React from 'react'
import { MUSIC_LIST_TITLE } from '../../../data/tableTitle'
import styled from 'styled-components';
import TablePart from '../../common/part/TablePart';
import TableNoItem from '../../common/item/TableNoItem';
import { Button, Pagination, TableRow } from '@mui/material';
import TableContentItem from '../../common/item/TableContentItem';
import { useNavigate } from 'react-router';
import { Row } from '../../../style/basic/commonStyle';
import { MUSIC_CATEGORY_ITEM, MUSIC_LIST_ITEM, apiSetMusicState } from '../../../api/apiMusic';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

interface musicListContentPartType {
    index:number;
    list: Array<any>;
    nowPage:number;
    total:number;
    categoryList:Array<typeof MUSIC_CATEGORY_ITEM>;
    getList:any;
    searchRes?: any;
}

const MUSIC_STATE = ['대기','승인','거절','숨김','삭제'];
const MUSIC_CATEGORY = ['music','effect sound'];

export default function MusicListContentPart({index,list,nowPage,total,getList,searchRes}:musicListContentPartType) {
    const navigate = useNavigate();
    const user = useUserInfo();

    // 페이지 이동
    const pageChange = (count:number) =>{
        let search = searchRes;
        if(search === undefined){
            search = '';
        }
        navigate(`/musicList/${index}/${count}/${search}`);
    }

    // 음악 state 변경
    const apiStateAction = async(id:any,state:any,reason?:string) =>{
        const res = await apiSetMusicState(user.jwt_token,user.admin_id,id,state,reason);
        return res.result
    }

    // 승인버튼
    const approvalClick = async(id:any) =>{
        if(window.confirm('승인하시겠습니까?')){
            const res = await apiStateAction(id,1,'');
            if(res){
                alert('승인 되었습니다.');
                getList();
            }else{
                alert('잠시후 다시 시도해주세요.')
            }
            
        }
    }

    // 거절 버튼
    const refusalClick = async(id:any) =>{
        const refusalText = window.prompt('거절하시겠습니까?\n거절사유를 입력해주세요.');
        if(refusalText === null){
            alert('거절이 취소되었습니다.');
            return;
        }

        if(refusalText === ''){
            alert('거절 사유를 입력해주세요.');
            return;
        }

        const res = await apiStateAction(id,2,refusalText);
        
        if(res){
            alert('거절 되었습니다.');
            getList();
        }else{
            alert('잠시후 다시 시도해주세요.')
        }

    }
    
    // 삭제 버튼
    const deleteClick = async(id:any) =>{
        if(window.confirm('삭제하시겠습니까?')){
            const res = await apiStateAction(id,100,'');
            if(res){
                alert('삭제 되었습니다.');
                getList();
            }else{
                alert('잠시후 다시 시도해주세요.');
            }
        }
    }
    
    return (
        <Wrap>
            <TablePart titleArr={MUSIC_LIST_TITLE} minWidth={850} >
                {list.length === 0 || list[0]?.sound_id === 0 ? 
                    <TableNoItem colSpan={MUSIC_LIST_TITLE.length}></TableNoItem>:
                    list?.map((data,index)=>{
                        return(
                            <TableRow
                                key={`tableContent-${index}`}
                            >
                                <TableContentItem text={data.sound_id}></TableContentItem>
                                <TableContentItem>
                                    <Text>{data.artist}/</Text>
                                    <Text>{data.sound_title}</Text>
                                </TableContentItem>
                                <TableContentItem text={data.genre}></TableContentItem>
                                <TableContentItem text={MUSIC_CATEGORY[Number(data.sound_category)-1]}></TableContentItem>
                                <TableContentItem text={data.sound_duration}></TableContentItem>
                                <TableContentItem text={data.sound_timestamp} type='time'></TableContentItem>
                                <TableContentItem text={data.play_count}></TableContentItem>
                                <TableContentItem>
                                    <audio
                                        controls
                                        src={data.url}>
                                    </audio>
                                </TableContentItem>
                                <TableContentItem text={MUSIC_STATE[Number(data.sound_visible) === 100 ? 4 : Number(data.sound_visible)]}></TableContentItem>
                                <TableContentItem>
                                    {/* {data.sound_visible === '1' && <Button variant='contained' color='error' onClick={()=>deleteClick(data.sound_id)}>삭제</Button>} */}
                                    {!(data.sound_visible === '2' || data.sound_visible === '100') &&
                                        <Button variant='contained' color='error' onClick={()=>deleteClick(data.sound_id)}>삭제</Button>
                                    }
                                    {data.sound_visible === '0' && 
                                        <>
                                            <Button sx={{margin:'2px 0'}} variant='contained' onClick={()=>approvalClick(data.sound_id)}>승인</Button>
                                            <Button sx={{margin:'2px 0'}} variant='contained' color='warning' onClick={()=>refusalClick(data.sound_id)}>거절</Button>
                                        </>
                                    }
                                </TableContentItem>
                            </TableRow>
                        )
                    })
                }
            </TablePart>
            <PaginationWrap>
                <Pagination shape="rounded" page={nowPage} count={total} onChange={(e, page) => pageChange(page)}></Pagination>
            </PaginationWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    & audio{
        max-width: 100%;
    }
`;

const Text = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
`;

const PaginationWrap = styled(Row)`
    padding: 20px 0;
    justify-content: end;
`;
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import AppInputItem from '../../item/AppInputItem';
import { Row } from '../../../../style/basic/commonStyle';

export default function HorizontalScroll_001_list_item({data}:any) {
    const [eventObj,setEventObj] = useState<any>();
    const [url,setUrl] = useState('');

    useEffect(()=>{
        if(data === undefined) return;
        setEventObj(data.event);
        setUrl(data.item_url);
    },[data]);

    const eventUpdate = (val:any,type:string) =>{
        const newData = {...eventObj};
        newData[type] = val;
        setEventObj(newData);
    }
    
    return(
        <ListItemWrap>
            <ContentItem>
                <ContentItemTitle>item_id:</ContentItemTitle>
                <ContentItemTitle>{data?.item_id ?? '0'}</ContentItemTitle>
            </ContentItem>
            <ContentItem>
                <ContentItemTitle>item_url:</ContentItemTitle>
                <AppInputItem value={url ?? ''} valueChange={setUrl}/>
            </ContentItem>
            <ContentItem>
                <ContentItemTitle>event:</ContentItemTitle>
                <ContentItemWrap>
                    <ContentItem>
                        <ContentItemTitle>action:</ContentItemTitle>
                        <AppInputItem value={eventObj?.action ?? ''} valueChange={(val:any)=>eventUpdate(val,'action')}/>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>name:</ContentItemTitle>
                        <AppInputItem value={eventObj?.name ?? ''} valueChange={(val:any)=>eventUpdate(val,'name')}/>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>navigator:</ContentItemTitle>
                        <AppInputItem value={eventObj?.navigator ?? ''} valueChange={(val:any)=>eventUpdate(val,'navigator')}/>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>screen:</ContentItemTitle>
                        <AppInputItem value={eventObj?.screen ?? ''} valueChange={(val:any)=>eventUpdate(val,'screen')}/>
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>tab:</ContentItemTitle>
                        <AppInputItem value={eventObj?.tab ?? ''} valueChange={(val:any)=>eventUpdate(val,'tab')}/>
                    </ContentItem>
                </ContentItemWrap>
            </ContentItem>
        </ListItemWrap>
    )
}
const ContentItemWrap = styled(Row)`
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px;
`;

const ListItemWrap = styled(ContentItemWrap)`
    /* border: 1px solid #eee; */
    margin-bottom: 4px;
`;

const ContentItem = styled(Row)`
    align-items: start;
    margin-bottom: 4px;
`;
const ContentItemTitle = styled.div`
    margin-right: 4px;
`;
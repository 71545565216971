import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import { Box, Button, Grid, MenuItem } from '@mui/material';
import InputItem from '../../common/item/InputItem';
import SelectItem from '../../common/item/SelectItem';

const TYPE = ['포인트','박스'];

export default function AdminRewardPart({data,clickEvent}:any) {
    const [amount,setAmount] = useState('0');
    const [sendType,setSendType] = useState(0);
    
    useEffect(()=>{
        setAmount(data.reward_amount);
        const type = Number(data.reward_process) - 1;
        setSendType(type)
    },[data]);

    const changeBtn = () =>{
        if(!clickEvent){
            return;
        }
        const newType = sendType + 1;
        clickEvent(data.reward_code,newType,amount);
    }

    return (
        <Grid item md={4} sm={6}>
            <Box sx={{width:'100%', boxSizing:'border-box', padding:1.5,border:'1px solid #ddd', borderRadius:2}}>
                <InnerWrap>
                    <TitleWrap>이름</TitleWrap>
                    <ContentWrap>{data.reward_name}</ContentWrap>
                </InnerWrap>
                <InnerWrap>
                    <TitleWrap>코드</TitleWrap>
                    <ContentWrap>{data.reward_code}</ContentWrap>
                </InnerWrap>
                <InnerWrap>
                    <TitleWrap>타입</TitleWrap>
                    <ContentWrap>
                        <SelectItem value={sendType} setValue={setSendType} sx={{width:'100px'}}>
                            {TYPE.map((data,index)=>{
                                return(
                                    <MenuItem key={`sendType-${index}`} value={index}>{data}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    </ContentWrap>
                </InnerWrap>
                <InnerWrap>
                    <TitleWrap>양</TitleWrap>
                    <ContentWrap>
                        <InputItem value={amount} valueChange={setAmount} />
                    </ContentWrap>
                </InnerWrap>
                <BtnWrap>
                    <Button variant='contained' onClick={changeBtn}>변경</Button>
                </BtnWrap>
            </Box>
        </Grid>
    )
}

const InnerWrap = styled(Row)`
    padding: 3px 0;
`;
const TitleWrap = styled.div`
    width: 30%;
`;
const ContentWrap = styled.div`
    width: 70%;
`;
const BtnWrap = styled(Center)`
    margin-top: 20px;
`;

import React, { useEffect, useState } from 'react'
import LeftNavBar from '../../common/part/LeftNavBar'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import TableWrapTitleItem from '../../common/item/TableWrapTitleItem'
import { apiGetAppUi, apiSetAppUiUpdate, apiSetAppUiUpdateRes } from '../../../api/apiApp'
import AppMainInputPart from '../part/AppMainInputPart'
import LayoutPart from '../part/LayoutPart'
import { AdminWrap, Center, RowBetween } from '../../../style/basic/commonStyle'
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo'
import SelectItem from '../../common/item/SelectItem'

const PREV = ['적용된 파일','적용 전 세이브 파일']

export default function AppMainPage() {
    const userInfo = useUserInfo();
    const pageTitle = '메인페이지 UI'
    const [list,setList] = useState<any>([]);
    const [is_prev,setIs_prev] = useState('0');
    const part = 'main_001';

    useEffect(()=>{
        if(userInfo.jwt_token === '') return;
        getList();
    },[userInfo.jwt_token,is_prev])

    const getList = async() =>{
        const res = await apiGetAppUi(userInfo.jwt_token,userInfo.admin_id,part,is_prev);
        if(res.result){
            const dataObj = JSON.parse(res.data.main);
            setList(dataObj);
        }else{
            setList([]);
        }
    }

    const updateRes = async() =>{
        const res = await apiSetAppUiUpdateRes(userInfo.jwt_token,userInfo.admin_id,part);
        if(res.data){
            alert('세이브 파일이 적용되었습니다.');
        }
    }

    const update = async(data:any,index:any) =>{
        setList((arr:any)=>arr.map((item:any,ind:any)=>(
            ind === index ? data : item
        )));
        const res = await apiSetAppUiUpdate(userInfo.jwt_token,userInfo.admin_id,part,list);
        if(res.result){
            alert('수정되었습니다')
        }
    }
    
    return (
        <>
            <Grid container={true} spacing={3}>
                <Grid item md={12} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <RowBetween>
                            <SelectItem value={is_prev} setValue={setIs_prev} sx={{width:'220px'}}>
                                {PREV.map((data,index)=>(
                                    <MenuItem key={`prev-${index}`} value={index}>{data}</MenuItem>
                                ))}
                            </SelectItem>
                            {is_prev == '1' &&
                                <Button variant='contained' onClick={updateRes}>세이브 파일 적용</Button>
                            }
                        </RowBetween>
                    </AdminWrap>
                </Grid>
                <Grid item md={7} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title='입력' />
                        <AppMainInputPart contentList={list} update={update} />
                    </AdminWrap>
                </Grid>
                <Grid item md={5} sm={12} sx={{width: '100%'}}>
                    <AdminWrap>
                        <TableWrapTitleItem title='UI' />
                        <Center>
                            <LayoutPart contentList={list} />
                        </Center>
                    </AdminWrap>
                </Grid>
            </Grid>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { MUSIC_CATEGORY_ITEM } from '../../../api/apiMusic';
import InputItem from '../../common/item/InputItem';
import { Center } from '../../../style/basic/commonStyle';
import { Button, MenuItem } from '@mui/material';
import SelectItem from '../../common/item/SelectItem';
import BasicText from '../../basic/BasicText';

interface musicCategoryInputPartType{
    item: typeof MUSIC_CATEGORY_ITEM;
    create: any;
    update: any;
    cancel: any;
    lastCode: any;
}

export default function MusicCategoryInputPart({item,create,update,cancel,lastCode}:musicCategoryInputPartType) {
    const [title,setTitle] = useState('');
    const [code,setCode] = useState('0');
    const [use,setUse] = useState(1);

    useEffect(()=>{
        if(item.category_id === 0){
            setTitle('');
            setCode(lastCode);
            setUse(1);
            return
        };
        setTitle(item.category_title);
        setCode(item.sound_small_category);
        setUse(item.category_use);
    },[item,lastCode])

    const inputChange = (value:string) =>{
        setTitle(value);
    }

    // 생성
    const createAction = async() =>{
        const res = await create(title,code,use);
        if(res){
            setTitle('');
            setCode('0');
            setUse(1);
        }
    }

    // 수정
    const updateAction = async() =>{
        const res = await update(title,item.category_id,code,use);
    }

    return (
        <Wrap>
            <BasicText>사용처</BasicText>
            <SelectItem value={use} setValue={setUse} margin='10px 0 0 0' sx={{width:'205px'}}>
                <MenuItem value='1'>전체</MenuItem>
                <MenuItem value='2'>음악</MenuItem>
                <MenuItem value='3'>유튜브</MenuItem>
            </SelectItem>
            <InputItem label='제목' inputLabel={'none'} value={title} valueChange={inputChange} margin='10px 0 0 0'></InputItem>
            <InputItem type='number' label='코드' inputLabel={'none'} value={code} valueChange={setCode} margin='10px 0 0 0'></InputItem>
            <BtnWrap>
                {item.category_id !== 0 ?
                    <>
                        <Button variant='contained' onClick={updateAction}>수정</Button>
                        <Button variant='contained' color='error' onClick={cancel}>취소</Button>
                    </> : <Button variant='contained' onClick={createAction}>생성</Button>
                }
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const BtnWrap = styled(Center)`
    margin-top: 30px;
    & button{
        margin: 0 15px;
    }
`;
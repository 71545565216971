import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import ListPart from '../../common/part/ListPart';
import { AGENCY_LIST } from '../../../data/tableTitle';
import TableNoItem from '../../common/item/TableNoItem';
import { Button, TableRow } from '@mui/material';
import { Row } from '../../../style/basic/commonStyle';
import TableContentItem from '../../common/item/TableContentItem';

// 타입 1 - 엔터  2 - 제작사
const TYPE = ['엔터','제작사'];
const LIST = [
    {id: 1, code: 'suboxmusic', ch_name: '슈박스', name: '슈박스', type: 2},
    {id: 2, code: 'HYBELABELS', ch_name: 'HYBE LABELS', name: '하이브', type: 1},
    {id: 3, code: 'SMTOWN', ch_name: 'SMTOWN', name: 'sm', type: 1},
    {id: 4, code: 'JYPEntertainment', ch_name: 'JYP Entertainment', name: 'jyp', type: 1},
]
export default function AgencyListPart({setSelectItem}:any) {
    const [list,setList] = useState<any>([]);
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(0);

    useEffect(()=>{
        getList();
    },[page])

    const getList = async() =>{
        setList(LIST);
    }

    // 수정 클릭
    const updateClick = (data:any) =>{
        setSelectItem(data);
    }

    // 삭제 클릭
    const deleteClick = async(data:any) =>{
        if(window.confirm('삭제 하시겠습니까?')){
            alert('삭제되었습니다.');
            getList();
        }
    }

    return (
        <Wrap>
            <ListPart titleArr={AGENCY_LIST} minWidth={800} nowPage={page} setPage={setPage} total={total} >
                {list === undefined || list.length === 0 ? 
                    <TableNoItem colSpan={AGENCY_LIST.length} /> :
                    list.map((data:any,index:any)=>{
                        return (
                            <TableRow key={`table-content-${index}`}>
                                <TableContentItem text={data.id} />
                                <TableContentItem text={data.name} />
                                <TableContentItem text={data.code} />
                                <TableContentItem text={data.ch_name} />
                                <TableContentItem text={TYPE[data.type - 1]} />
                                <TableContentItem>
                                    <Row style={{width:'auto',gap:'4px'}}>
                                        <Button variant='contained' onClick={()=>updateClick(data)}>수정</Button>
                                        <Button variant='contained' onClick={()=>deleteClick(data)} color='error'>삭제</Button>
                                    </Row>
                                </TableContentItem>
                            </TableRow>
                        )
                    })
                }
            </ListPart>
        </Wrap>
    )
}

const Wrap = styled.div``;